import {Lifecycle, scoped} from "tsyringe";
import {AbstractRepository} from "./AbstractRepository";
import {PushNotificationRecipient} from "@jklubcafe/backend-api-dto";
import {PayloadClient} from "./PayloadClient";

@scoped(Lifecycle.ContainerScoped)
export class PushNotificationRecipientsRepository extends AbstractRepository<PushNotificationRecipient> {
    constructor(client: PayloadClient) {
        super(client, 'push-notification-recipients');
    }
}
