import {Share} from "@capacitor/share";
import React, {HTMLAttributes, useCallback, useEffect, useState} from "react";
import {RWebShare} from "react-web-share";

interface ShareLinkProps extends HTMLAttributes<HTMLAnchorElement> {
    title?: string;
    text?: string;
    url?: string;
}

export const ShareLink = ({text, title, url, onClick, children, ...props}: ShareLinkProps) => {
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        Share.canShare().then((canShare) => setCanShare(canShare.value));
    })

    const click = useCallback((e: any) => {
        Share
            .share({
                title,
                text,
                url,
            })
            .then(() => onClick && onClick(e));
    }, [text, title, url, onClick]);

    return canShare ? (
        <a onClick={click} {...props}>{children}</a>
    ) : (
        <RWebShare data={{
            text,
            title,
            url,
        }}>
            {children}
        </RWebShare>
    )
};
