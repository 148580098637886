import {Lifecycle, scoped} from "tsyringe";
import {EventCouponRepository, RedeemCouponResponse} from "../repositories/EventCouponRepository";
import {EventCoupon} from "@jklubcafe/backend-api-dto";
import {dataProvider} from "../hooks/useData";

@scoped(Lifecycle.ContainerScoped)
export class EventCouponService {
    constructor(private readonly eventCouponRepository: EventCouponRepository) {
    }

    public redeemCoupon(couponCode: string, eventId: string): Promise<RedeemCouponResponse> {
        return this.eventCouponRepository.redeem(couponCode, eventId);
    }

    public requestCoupon(userId: string, eventId: string): Promise<EventCoupon> {
        return this.eventCouponRepository.create({
            user: userId,
            event: eventId,
        })
    }

    public removeCoupon(couponId: string): Promise<EventCoupon> {
        return this.eventCouponRepository.deleteById(couponId);
    }

    @dataProvider('userCoupons')
    public getUserCoupons(): Promise<EventCoupon[]> {
        return this.eventCouponRepository.paginate(this.eventCouponRepository.findAll,{
            depth: 0,
            limit: 100,
        });
    }
}

