import log from 'loglevel';
import {isProduction} from "./isProduction";
import express from "express";
import {isServer} from "./isServer";


const rawMethodFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = rawMethodFactory(methodName, logLevel, loggerName);

    return (message, ...args) => {
        if (message instanceof Error) {
            message = message.message;
        }

        const argsString = args.map(arg => String(arg)).join(' ');
        rawMethod(`${new Date().toISOString()} [${String(loggerName)}] [${methodName.toUpperCase()}] ${message} ${argsString}`);
    };
};

// ------------------------------------
// App Logger
// ------------------------------------
export const logger = log.getLogger('jateklub-ui');
// TODO set it to INFO on server side, too, once the app is stable and tested
logger.setLevel(isProduction && !isServer ? log.levels.INFO : log.levels.DEBUG);

// ------------------------------------
// Access Log
// ------------------------------------
const accessLogger = log.getLogger('jateklub-ui-access');
accessLogger.setLevel(log.levels.DEBUG)
export const accessLogMiddleware = (req: express.Request, res: express.Response, next: express.NextFunction) => {
    const {method, url, ip} = req;

    res.on('finish', () => {
        accessLogger.debug(`${ip} - ${method} ${url} ${res.statusCode}`);
    });

    next();
}
