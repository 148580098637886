import {Link} from "react-router-dom";
import {Button} from "../../atoms/button/Button";
import React from "react";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useTranslation} from "react-i18next";
import {GiPadlock} from "react-icons/gi";

export interface IssueDisplayProps {
    icon: React.ReactNode,
    title: string,
    description?: string,
    button?: React.ReactNode,
}

export const Issue = ({icon, title, description, button}: IssueDisplayProps) => {
    const routes = useLocalizedRoutes();
    const {t} = useTranslation();

    return (
        <div className='w-full flex flex-col justify-center items-center gap-10'>
            <h1 className='text-center'>{t(title)}</h1>
            {icon}
            {description && <p className='text-sm text-center'>{t(description)}</p>}
            {button || <Link to={routes.home()}>
                <Button>{t('Go to Homepage')}</Button>
            </Link>}
        </div>
    )
}

export const AccessDeniedIssue = () => {
    const {t} = useTranslation();
    return (<Issue
        icon={<GiPadlock className='text-8xl'/>}
        description={t('You are not authorized to access this page.') || undefined}
        title={t('Access Denied')}
    />)
}
