import React from "react"
import {useTranslation} from "react-i18next";
import {useAuthenticatedUser} from "../hooks/useAuthenticatedUser";
import {AccessDeniedIssue} from "../organisms/issue/Issue";
import {useData} from "../hooks/useData";
import {EventService} from "../services/EventService";
import {useParams} from "react-router-dom";
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import {FaTheaterMasks} from "react-icons/fa";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import {CouponValidationForm} from "../organisms/coupon-validation-form/CouponValidationForm";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";

const CouponValidationPageContent = () => {
    const {eventSlug} = useParams();
    const {t} = useTranslation();
    const {data: event} = useData(EventService, EventService.prototype.getEvent, eventSlug);
    const routes = useLocalizedRoutes();

    return (
        event ? <>
            <MetaTitle subtitle={t('Coupon Validation | {{event}}', {event: event.title})} />
            <h1>{t('Coupon Validation')}</h1>
            <Breadcrumbs className='jk-a-underline' lastClickable={true} items={[
                {
                    href: routes.event(event).details(),
                    label: event.title,
                    icon: <FaTheaterMasks/>,
                }
            ]}/>

            <CouponValidationForm event={event}/>
        </> : null
    )
}

export const CouponValidationPage = () => {
    const {hasRole} = useAuthenticatedUser();

    return (!hasRole('qr-admin') ? (
        <AccessDeniedIssue/>
    ) : (<CouponValidationPageContent/>));
}
