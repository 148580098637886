import {Cafe, Event, Media} from '@jklubcafe/backend-api-dto'
import React, {useMemo} from "react";
import {Helmet} from "react-helmet";
import {oneLineTrim} from "common-tags";

export interface MetaEventProps {
    event: Event
}

export const MetaEvent = ({event}: MetaEventProps) => {
    const image = useMemo(() => typeof event.image === 'string' ? undefined : event.image as Media, [event])
    const data = useMemo(() => ({
        "@context": "https://schema.org",
        "@type": "Event",
        "name": event.title,
        "startDate": event.datetime,
        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",
        "description": oneLineTrim(event.intro || ''),
        "image": [
            image?.url,
            image?.sizes?.tablet?.url,
            image?.sizes?.card?.url,
            image?.sizes?.thumbnail?.url,
            image?.sizes?.highres?.url,
        ].filter(Boolean),
        "location": {
            "@type": "Place",
            "name": (event.cafe as Cafe)?.name,
            ...((event.cafe as Cafe)?.address ? {"address": (event.cafe as Cafe)?.address} : {})
        }
    }), [event])

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(data)}
            </script>
        </Helmet>
    )
}
