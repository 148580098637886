import "reflect-metadata";
import './repositories';
import './i18n';

import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {routes} from "./routes";
import {createRoot, hydrateRoot} from "react-dom/client";
import {isSpa} from "./tools/isSpa";

const container = document.getElementById('root') as HTMLElement;

if (!container) {
  throw new Error('Illegal State: root element not found in DOM');
}

const browserRouter = createBrowserRouter(routes);

if (isSpa) {
  // app is a regular SPA
  createRoot(container).render(<RouterProvider router={browserRouter}/>);
} else {
  // app is a Server Side Rendered app
  hydrateRoot(container, <RouterProvider router={browserRouter}/>);
}

if (module.hot) {
  module.hot.accept();
}
