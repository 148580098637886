import {useEffect, useState} from "react";

export const useImage = (url?: string) => {
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (!url) {
            setError(true);
            return;
        }
        const img = new Image();
        img.onload = () => {
            setImage(img);
            setSuccess(true);
        }
        img.onerror = () => setError(true);
        img.src = url;
    }, [url]);

    return {
        image, error, success
    };
};
