import {PayloadClient} from './PayloadClient';
import {EntityNotFound, FindDocumentsResponse, Pageable, UpdateDocumentResponse, UpsertDocumentResponse} from './index';
import {stringify} from 'qs';
import {Config} from '@jklubcafe/backend-api-dto';

const toQuery = (pageable?: Pageable<never>) => {
    const {page, limit, sort, where, depth} = pageable || {};

    const query = {
        ...(sort ? {sort: `${sort.order === 'desc' ? '-' : ''}${String(sort.field)}`} : {}),
        ...(page ? {page} : {}),
        ...(limit ? {limit} : {}),
        ...(where ? {where} : {}),
        ...(depth !== undefined ? {depth} : {}),
    }

    return stringify(query, {addQueryPrefix: true, encode: false});
}

export class AbstractRepository<T> {
    constructor(protected readonly client: PayloadClient, protected readonly collection: keyof Config['collections']) {
    }

    public async upsert(where: any, payload: Partial<T>): Promise<T> {
        const response = await this.update(where, payload);
        if (response.length === 0) {
            return this.create(payload);
        }
        return response[0];
    }

    public create(payload: Partial<T>): Promise<T> {
        return this.client
            .post<UpsertDocumentResponse<T>>(`/${this.collection}`, payload)
            .then(response => response.data.doc);
    }

    public deleteById(id: string): Promise<T> {
        return this.client
            .delete<T>(`/${this.collection}/${id}`)
            .then(response => response.data);
    }

    public updateById(id: string, payload: Partial<T>): Promise<T> {
        return this.client
            .patch<UpsertDocumentResponse<T>>(`/${this.collection}/${id}`, payload)
            .then(response => response.data.doc);
    }

    public async update(where: any, payload: Partial<T>): Promise<T[]> {
        const query = toQuery({where});
        const response = await this.client.patch<UpdateDocumentResponse<T>>(`/${this.collection}/${query}`, payload);

        if (response.data.errors && response.data.errors.length > 0) {
            throw new Error(response.data.errors.toString());
        }

        return response.data.docs;
    }

    public findById(id: string, depth?: number): Promise<T> {
        return this.client.get<T>(`/${this.collection}/${id}${stringify({depth}, {addQueryPrefix: true})}`).then(response => response.data);
    }

    public async findBySlug(slug: string, depth = 1): Promise<T> {
        const criteria = (k: string, v: string): Pageable<T> => ({
            depth,
            where: {
                [k]: {
                    equals: v,
                }
            }
        });

        // TODO move fallback language to config
        const doc = await this.findOne(criteria('slug', slug)) || await this.findOne(criteria('slug.hu', slug));

        if (!doc) {
            throw new EntityNotFound(this.collection, criteria('slug', slug));
        }

        return doc;
    }

    public findAll(pageable?: Pageable<T>): Promise<FindDocumentsResponse<T>> {
        const queryString = toQuery(pageable);

        return this.client
            .get<FindDocumentsResponse<T>>(`/${this.collection}${queryString}`)
            .then(response => response.data);
    }

    public findOne(pageable?: Omit<Pageable<T>, 'page' | 'limit' | 'sort'>): Promise<T | null> {
        return this
            .findAll({...pageable, limit: 1})
            .then(response => response.totalDocs > 0 ? response.docs[0] : null);
    }

    public async paginate<T>(f: (pageable?: Pageable<T>) => Promise<FindDocumentsResponse<T>>, pageable?: Pageable<T>) {
        const result: T[] = [];

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const {docs, hasNextPage, nextPage} = await f.call(this, pageable);

            result.push(...docs);

            if (!hasNextPage) {
                break;
            }

            pageable = {
                ...pageable,
                ...{
                    page: nextPage!,
                },
            }
        }

        return result;
    }
}


