import {Cafe, Gallery, GalleryImage} from "@jklubcafe/backend-api-dto";
import {Card} from "../card/Card";
import React from "react";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {BsImage} from "react-icons/bs";

interface GalleryCardProps {
    gallery: Gallery;
}

export const GalleryCard = ({gallery}: GalleryCardProps) => {
    const routes = useLocalizedRoutes();

    return (<Card
        url={routes.cafe((gallery.cafe as Cafe).slug).gallery().browse(gallery)}
        datetime={gallery.createdAt}
        image={gallery.images?.[0]?.image as GalleryImage | undefined}
        PlaceholderIco={BsImage}
        title={gallery.title}
        description={gallery.description}
    />)
}
