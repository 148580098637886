import {useLocation, useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const useTreeNavigate = () => {
    const {t} = useTranslation('routes');
    const navigate = useNavigate();
    const location = useLocation();

    const locationUp = useCallback((level = 1) => location.pathname.split('/').slice(0, -1 * level).join('/'), [location.pathname]);
    const locationDown = useCallback((...segment: string[]) => location.pathname + '/' + segment.map(s => t(s)).join('/'), [location.pathname]);

    const goLocationUp = useCallback((level = 1) => {
        const dest = location.pathname.split('/').slice(0, -1 * level).join('/');
        navigate(dest);
    }, [location.pathname]);

    const goLocationDown = useCallback((...segment: string[]) => {
        const dest = location.pathname + '/' + segment.map(s => t(s)).join('/');
        navigate(dest);
    }, [location.pathname]);

    return {goLocationUp, goLocationDown, locationUp, locationDown};
}
