export interface UpsertDocumentResponse<T> {
    message: string;
    doc: T;
}

export interface UpdateDocumentResponse<T> {
    errors: unknown[];
    docs: T[];
}

export interface FindDocumentsResponse<T> {
    docs: Array<T>,
    totalDocs: number,
    offset: number,
    limit: number,
    totalPages: number,
    page: number,
    pagingCounter: number,
    hasPrevPage: boolean
    hasNextPage: boolean,
    prevPage: number | null
    nextPage: number | null
}

export interface Pageable<T> {
    page?: number,
    limit?: number,
    sort?: Sort<T>,
    where?: any,
    depth?: number,
}

export interface Sort<T> {
    field: keyof T;
    order?: 'asc' | 'desc';
}

export class EntityNotFound extends Error {
    constructor(collection: string, criteria?: Partial<Pageable<never>>) {
        super(`Could not found '${collection}' entity with criteria ${JSON.stringify(criteria)}`);
        this.name = 'EntityNotFound';
    }
}
