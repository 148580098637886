import {Trans, useTranslation} from "react-i18next";
import {useInjection} from "../../hooks/useInjection";
import {UserService} from "../../services/UserService";
import {Link, useNavigate} from "react-router-dom";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useGlobalNotification} from "../../hooks/useGlobalNotification";
import React, {useCallback} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Input, MailIcon, PasswordIcon} from "../../atoms/input/Input";
import {MessageBox} from "../../atoms/message-box/MessageBox";
import {Button} from "../../atoms/button/Button";
import {SpinnerBackdrop} from "../../atoms/spinner-backdrop/SpinnerBackdrop";
import {GoogleReCaptchaBranding} from "../../atoms/google-recaptcha-branding/GoogleReCaptchaBranding";
import {useReCaptcha} from "../../hooks/useReCaptcha";

export const SignUpForm = () => {
    const {t} = useTranslation();
    const userService = useInjection(UserService);
    const navigate = useNavigate();
    const routes = useLocalizedRoutes();
    const {showNotification} = useGlobalNotification();
    const {executeReCaptcha} = useReCaptcha('signUp');

    const register = useCallback((username: string, password: string) => {
        return userService
            .signUp(username, password)
            .then(() => {
                showNotification({
                    type: 'success',
                    title: t('Successful Signup'),
                    message: t('You have successfully signed up. Please login to continue.'),
                });
                navigate(routes.login());
            })
            .catch(({response}) => {
                showNotification({
                    type: 'error',
                    title: t('Failed Signup'),
                    message: (response?.data?.errors || [])[0]?.message || t('Failed to signup due to unknown error'),
                });
            });
    }, [userService]);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            passwordAgain: '',
            acceptTerms: [],
        },
        validationSchema: Yup.object({
            username: Yup
                .string()
                .email(t('Please enter a valid email address') || '')
                .required(t('Please enter your email address') || ''),
            password: Yup
                .string()
                .required(t('Please enter your password') || '')
                .min(8, t('Password must be at least 8 characters long') || '')
                .matches(/[a-z]/, t('Password must contain at least one lowercase letter') || '')
                .matches(/[A-Z]/, t('Password must contain at least one uppercase letter') || ''),
            passwordAgain: Yup
                .string()
                .test('passwordAgain', t('Passwords must match') || '', (value, context) => {
                    return context.parent.password === value
                }),
            acceptTerms: Yup
                .array()
                .test('acceptTerms', t('You must accept the terms and conditions') || '', (value) => {
                    return value?.includes('accept')
                }),
        }),
        onSubmit: ({username, password}, {setSubmitting}) => {
            executeReCaptcha()
                .then(() => register(username, password))
                .finally(() => setSubmitting(false));
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} className='grid grid-cols-1 gap-3'>
                <p>{t('Enter your username and password to create account and unlock additional features')}</p>

                <Input
                    id='username' name='username' type='email' autoComplete='username'
                    placeholder={t('E-mail address') || ''} icon={<MailIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username}
                    required/>

                <MessageBox visible={!!(formik.touched.username && formik.errors.username)} type='error'
                            message={formik.errors.username}/>

                <Input
                    id='password' name='password' type='password' autoComplete="current-password"
                    placeholder={t('Password') || ''} icon={<PasswordIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}
                    required/>

                <MessageBox visible={!!(formik.touched.password && formik.errors.password)} type='error'
                            message={formik.errors.password}/>

                <Input
                    id='passwordAgain' name='passwordAgain' type='password' autoComplete="current-password"
                    placeholder={t('Password Confirmation') || ''} icon={<PasswordIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.passwordAgain}
                    required/>

                <MessageBox visible={!!(formik.touched.passwordAgain && formik.errors.passwordAgain)} type='error'
                            message={formik.errors.passwordAgain}/>

                <GoogleReCaptchaBranding/>

                <Input
                    id='acceptTerms' name='acceptTerms' type='checkbox'
                    label={<Trans
                        i18nKey='I agree and accept the <0>terms and conditions</0>'
                        components={[<Link to={routes.article('terms-and-conditions')} key='terms-and-conditions'/>]}
                    />}
                    value={'accept'}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                />

                <MessageBox visible={!!(formik.touched.acceptTerms && formik.errors.acceptTerms)} type='error'
                            message={String(formik.errors.acceptTerms)}/>

                <Button type='submit' className='w-fit' disabled={formik.isSubmitting}>{t('Create Account')}</Button>
            </form>

            {formik.isSubmitting && <SpinnerBackdrop/>}
        </>
    )
}
