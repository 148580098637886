import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

interface GoogleAnalyticsProps {
    measurementId: string
}

export const GoogleAnalytics = ({measurementId}: GoogleAnalyticsProps) => {
    const location = useLocation();

    useEffect(() => {
        if (window?.gtag) {
            window.gtag("event", "page_view", {
                page_location: location.pathname + location.search,
            });
        }
    }, [location]);

    return (
        <Helmet>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}/>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${measurementId}');
                `}
            </script>
        </Helmet>
    )
}
