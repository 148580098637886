import React from "react";
import {Outlet} from 'react-router-dom';
import {Header} from '../organisms/header/Header';
import {Footer} from '../organisms/footer/Footer';
import {getStaticData} from "../hooks/useData";
import {CafeService} from "../services/CafeService";
import {StaticData, StaticDataArgs} from "../tools/context";
import {FooterNavigationService} from "../services/FooterNavigationService";

export const MainTemplate = () => (
    <div>
        <Header/>

        <div className='min-h-screen md:min-h-fit flex-col flex justify-between'>
            <main className='jk-main'>
                <div className='jk-main-content'>
                    <Outlet/>
                </div>
            </main>

            <Footer/>
        </div>
    </div>
);

MainTemplate.staticData = async ({context}: StaticDataArgs): Promise<StaticData> => {
    return {
        fallback: {
            ...(await getStaticData(context, CafeService, CafeService.prototype.getCafes)),
            ...(await getStaticData(context, FooterNavigationService, FooterNavigationService.prototype.getFooterNavigation)),
        }
    }
}
