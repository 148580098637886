import {useTheme} from "../../hooks/useTheme";
import {Link, useLocation} from "react-router-dom";
import React, {HTMLAttributes, useMemo} from "react";

interface NavBarButtonProps extends HTMLAttributes<HTMLAnchorElement>{
    url: string;
    label: string;
    ico: React.ReactNode;
}

export const NavBarButton = ({url, label, ico, className, ...props}: NavBarButtonProps) => {
    const {colors} = useTheme();
    const location = useLocation();
    const active = useMemo(() => location.pathname.startsWith(url), [location, url]);

    return (
        <Link
            to={url}
            style={{
                borderColor: colors.primary,
            }}
            className={`p-2 hover:bg-jk-gray-light ${active ? 'bg-jk-gray-light border-t-4' : ''} text-center text-sm font-semibold ${className || ''}`}
            {...props}
        >
          <span className="relative z-20 whitespace-nowrap flex flex-col gap-2">
              {ico}
              {label}
          </span>
        </Link>
    )
}
