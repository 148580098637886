import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useFederatedLogin} from "../../hooks/useFederatedLogin";
import {isNative} from "../../tools/isNative";
import {useTranslation} from "react-i18next";
import {useAuthenticatedUser} from "../../hooks/useAuthenticatedUser";

/**
 * Component responsible for handling OAuth callback from the identity provider and exchanging the code for an access
 * token using JK backend.
 */
export const FederatedAuthHandler = () => {
    const [tokenExchangeInProgress, setTokenExchangeInProgress] = React.useState(false);
    const {doExchangeToken} = useFederatedLogin();
    const routes = useLocalizedRoutes();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {isAuthenticated} = useAuthenticatedUser();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(routes.home());
        } else if (isNative && !tokenExchangeInProgress) {
            setTokenExchangeInProgress(true);
            doExchangeToken()
                .finally(() => setTokenExchangeInProgress(false));
        }
    }, [routes, isAuthenticated, navigate, doExchangeToken, setTokenExchangeInProgress]);

    return (<span>{t('Authentication is in progress...')}</span>);
}
