import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {FlagEn} from '../../atoms/flag-en/FlagEn';
import {FlagHu} from '../../atoms/flag-hu/FlagHu';
import './LanguageToggle.css';
import {Locale} from "../../i18n";
import {useNavigate} from "react-router-dom";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";

export const LanguageToggle = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const routes = useLocalizedRoutes();

  const toggleLanguage = useCallback(() => {
    const locale = i18n.language as Locale;
    const newLocale = locale === 'en' ? 'hu' : 'en';
    navigate(routes.home(newLocale));
  }, [i18n, i18n.language, navigate]);

  return (
    <div onClick={toggleLanguage}
         className='clickable shadow-md shadow-inner bg-jk-gray-light rounded-full p-2 border-0 language-selector flex flex-row justify-evenly items-center'>
      <FlagEn className={`${i18n.language === 'en' ? 'active' : ''} transition-opacity mx-1 h-5 md:h-7 flex-shrink-0 lang`}/>
      <FlagHu className={`${i18n.language === 'hu' ? 'active' : ''} transition-opacity mx-1 h-5 md:h-7 flex-shrink-0 lang`}/>
    </div>
  )
}
