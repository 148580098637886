import React, {useCallback, useEffect} from "react";
import {CafeSelector} from "../organisms/cafe-selector/CafeSelector";
import {CafeSlug, useCafeSlug} from "../hooks/useCafeSlug";
import {useNavigate} from "react-router-dom";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import {usePageClass} from "../hooks/usePageClass";
import {Partners} from "../organisms/partners/Partners";
import {StaticData, StaticDataArgs} from "../tools/context";
import {getStaticData} from "../hooks/useData";
import {PartnerService} from "../services/PartnerService";
import {isNative} from "../tools/isNative";

export const IndexPage = () => {
    usePageClass('index-page');

    const cafeSlug = useCafeSlug();
    const navigate = useNavigate();
    const routes = useLocalizedRoutes();

    useEffect(() => {
        if (isNative) {
            navigate(routes.cafe(CafeSlug.Jate).main());
        }
    }, []);

    const clickHandler = useCallback((cafeSlug: CafeSlug) => navigate(routes.cafe(cafeSlug).main()), [navigate, routes]);

    return (
        !isNative ? <div className="index-page bg-gradient-to-b from-black/100 via-black/30 to-black/0 ">
            <div className="jk-main !pt-0">
                <div className="jk-main-content justify-center flex">
                    <CafeSelector selected={cafeSlug} onClick={clickHandler} className='w-full px-[10%] md:px-0'/>
                </div>
            </div>
            <Partners/>
        </div> : null
    )
}

IndexPage.staticData = async ({context}: StaticDataArgs): Promise<StaticData> => {
    return {
        fallback: {
            ...await getStaticData(context, PartnerService, PartnerService.prototype.getPartners)
        }
    }
}
