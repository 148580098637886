import {Cafe} from '@jklubcafe/backend-api-dto';
import {CafeRepository} from '../repositories/CafeRepository';
import {Lifecycle, scoped} from 'tsyringe';
import {dataProvider} from "../hooks/useData";

@scoped(Lifecycle.ContainerScoped)
export class CafeService {
    constructor(private readonly cafeRepository: CafeRepository) {
    }

    @dataProvider('cafes')
    public async getCafes(): Promise<Cafe[]> {
        return this.cafeRepository.paginate(this.cafeRepository.findAll, {
            depth: 1,
            sort: {
                field: 'sortOrder',
                order: 'asc',
            }
        });
    }
}

