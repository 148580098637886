import {InjectionToken} from "tsyringe";
import {useInjection} from "./useInjection";
import useSWR from "swr";
import {useTranslation} from "react-i18next";
import {isServer} from "../tools/isServer";
import {useLoaderData} from "react-router-dom";
import {$ServerRequest, RequestContext, ServerRequest, StaticData} from "../tools/context";
import serializeJavascript from "serialize-javascript";
import {logger} from "../tools/logger";

type Fetcher<V> = (...props: any[]) => Promise<V>

const createSwrKey = <T, V, M extends Fetcher<V> = Fetcher<V>>(
    token: InjectionToken<T>,
    method: Fetcher<V>,
    ...props: Parameters<M>
) => {
    const hashCode = (val: string) => {
        let hash = 0, i, chr;
        if (val.length === 0) return hash;
        for (i = 0; i < val.length; i++) {
            chr = val.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    const propsHash = hashCode(props.map((prop) => serializeJavascript(prop)).join('_'));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `${method.__data_provider_id}.${propsHash}`
}

export const useData = <T, V, M extends Fetcher<V> = Fetcher<V>>(
    token: InjectionToken<T>,
    method: Fetcher<V>,
    ...props: Parameters<M>
) => {
    const staticData = useLoaderData() as StaticData | null;
    const config = staticData?.fallback ? {fallback: staticData.fallback} : {};
    const service = useInjection(token);
    const {i18n} = useTranslation();
    const swrKey = createSwrKey(token, method, i18n.language, ...props);
    logger.debug('Getting data with id', swrKey);
    return useSWR(swrKey, method.bind(service, ...props), config);
}


export const getStaticData = async <T, V, M extends Fetcher<V> = Fetcher<V>>(
    context: RequestContext,
    token: InjectionToken<T>,
    method: Fetcher<V>,
    ...props: Parameters<M>
) => {
    if (isServer) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!context) {
            throw 'Illegal State: Request Context is not available during SSR.';
        }

        const language = context.diContainer.resolve<ServerRequest>($ServerRequest).i18n.language;
        const service = context.diContainer.resolve(token);
        const swrKey = createSwrKey(token, method, language, ...props);

        logger.debug('Getting static data with id', swrKey);
        const result = await method.call(service, ...props);
        logger.debug('Static data retrieved and registered with id', swrKey);

        return {
            [swrKey]: result
        }
    } else {
        return {};
    }
}

export function dataProvider(id: string) {
    return function (target: any, property: string, descriptor: TypedPropertyDescriptor<any>) {
        descriptor.value.__data_provider_id = id;
    }
}
