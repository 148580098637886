import {Request} from "express";
import {i18n} from "i18next";
import {DependencyContainer} from "tsyringe";
import {LoaderFunctionArgs} from "react-router-dom";
import React from "react";
import {isServer} from "./isServer";

export const $ServerRequest = Symbol('ServerRequest');

export type ServerRequest = Request & { i18n: i18n };

export type RequestContext = {
    diContainer: DependencyContainer;
} | undefined;

export interface StaticData {
    /**
     * SWR fallback data used in case of SSR.
     */
    fallback?: {
        [key: string]: any;
    };
}

export interface StaticDataArgs extends LoaderFunctionArgs {
    context?: RequestContext
}

export const ContextProvider = React.createContext<RequestContext|null>(null);

export const RequestContextProvider = ({children, context}: { context: RequestContext, children: React.ReactNode }) => {
    return <ContextProvider.Provider value={context}>{children}</ContextProvider.Provider>
}

export const useRequestContext = () => {
    if (isServer) {
        const res = React.useContext(ContextProvider);
        if (!res) {
            throw 'Illegal State: No request context found on server side.'
        }
        return res;
    }
}
