import {useParams} from 'react-router-dom';
import {useMemo} from "react";

export enum CafeSlug {
  Radnoti = 'cafe-radnoti',
  Jate = 'jate-klub',
  Tik = 'cafe-tik',
}

const defaultCafeSlug = CafeSlug.Jate;

export const isValidCafeSlug = (slug: string) => {
    return Object.values(CafeSlug).includes(slug as CafeSlug);
}
export const useCafeSlug = (): CafeSlug => {
  const { cafeSlug: cafeSlugInRoute } = useParams();
  return useMemo(() => (cafeSlugInRoute && isValidCafeSlug(cafeSlugInRoute) ? cafeSlugInRoute : defaultCafeSlug) as CafeSlug, [
    cafeSlugInRoute,
  ]);
}
