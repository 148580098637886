import {Backdrop} from "../backdrop/Backdrop";
import React from "react";
import {Spinner} from "../spinner/Spinner";

export const SpinnerBackdrop = ({visible}: { visible?: boolean }) => {
    return (
        <Backdrop>
            <Spinner/>
        </Backdrop>
    )
}
