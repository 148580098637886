import {useCookie} from "../../hooks/useCookie";
import {useTranslation} from "react-i18next";
import React, {useCallback, useMemo} from "react";
import {isWeb} from "../../tools/isWeb";
import {BtnGetOnGooglePlay} from "../../atoms/btn-get-on-google-play/BtnGetOnGooglePlay";
import {BtnGetOnAppStore} from "../../atoms/btn-get-on-app-store/BtnGetOnAppStore";
import {CgClose} from "react-icons/cg";
import {isMobile} from "../../tools/isMobile";

export const DownloadAppPopup = () => {
    const {t} = useTranslation();
    const {cookie, setCookie, cookieLoaded} = useCookie('downloadAppPopupDisplayed');
    const showDownloadAppPopup = useMemo(() => cookie !== '1' && isWeb && isMobile,[cookie]);

    const onClose = useCallback(() => {
        setCookie('1');
    }, [setCookie]);

    return (
        cookieLoaded && showDownloadAppPopup ?
            <div
                onClick={onClose}
                className='flex flex-col items-center justify-center text-jk-white p-8 fixed w-full h-full top-0 left-0 z-[200] gap-8 backdrop-blur-lg bg-black/40'>
                <CgClose onClick={onClose} className='right-0 top-0 fixed m-10 bg-jk-gray-light rounded-full text-3xl p-2 clickable-restrained'/>
                <p className='text-center'>
                    {t('JATE Klub application is available for iOS and Android devices. Get it now and enjoy the benefits of being a JATE Klub member.')}
                </p>
                <BtnGetOnGooglePlay/>
                <BtnGetOnAppStore/>
            </div> : null
    )
}
