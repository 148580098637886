import {useCallback, useEffect, useState} from "react";
import {cookieName, getCookie, setCookie} from "../tools/cookies";
import {$ServerRequest, ServerRequest, useRequestContext} from "../tools/context";
import {isServer} from "../tools/isServer";

const useRequestCookie = (key: string): string | undefined => {
    const requestContext = useRequestContext();
    return requestContext?.diContainer.resolve<ServerRequest>($ServerRequest).cookies[cookieName(key)]
}

export const useCookie = (key: string) => {
    const cookie = isServer ? useRequestCookie(key) : getCookie(key);
    const [cookieValue, setCookieValue] = useState<string | undefined>(undefined);
    const [loaded, setLoaded] = useState(false);

    const loadCookie = useCallback(() => {
        if (cookie instanceof Promise) {
            cookie.then((v) => {
                setCookieValue(v);
                setLoaded(true);
            });
        } else {
            setCookieValue(cookie);
            setLoaded(true);
        }
    }, [cookie, setCookieValue]);

    const setCookieCallback = useCallback((value: string) => {
        setCookie(key, value).then(() => setCookieValue(value));
    }, [key, setCookieValue]);

    useEffect(() => {
        loadCookie();
    }, [loadCookie]);

    return {
        cookie: cookieValue,
        setCookie: setCookieCallback,
        cookieLoaded: loaded,
    };
}
