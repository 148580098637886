import {Event} from '@jklubcafe/backend-api-dto';
import {EventRepository} from '../repositories/EventRepository';
import {FindDocumentsResponse, Sort} from '../repositories';
import {Lifecycle, scoped} from "tsyringe";
import {dataProvider} from "../hooks/useData";

const DEFAULT_QUERY: unknown[] = [
    {
        published: {
            equals: true,
        }
    },
];

const DEFAULT_SORT: Sort<Event> = {
    field: 'datetime',
    order: 'asc',
};

@scoped(Lifecycle.ContainerScoped)
export class EventService {
    constructor(private readonly eventRepository: EventRepository) {
    }

    @dataProvider('publishedEvents')
    public async getPublishedEvents(): Promise<Event[]> {
        return this.eventRepository.paginate(this.eventRepository.findAll, {
            sort: DEFAULT_SORT,
            depth: 1,
            where: {
                and: DEFAULT_QUERY,
            },
        });
    }

    @dataProvider('publishedEventsPage')
    public async getPublishedEventsPage(cafeSlug: string, when: 'future' | 'past', page: number, limit: number): Promise<FindDocumentsResponse<Event>> {
        const query: unknown[] = [
            ...DEFAULT_QUERY,
            {
                datetime: when === 'future' ? {
                    greater_than_equal: new Date().toISOString(),
                } : {
                    less_than: new Date().toISOString(),
                },
            },
            {
                "cafe.slug": {
                    equals: cafeSlug
                }
            }
        ];

        return this.eventRepository.findAll({
            sort: {
                ...DEFAULT_SORT,
                order: when === 'future' ? 'asc' : 'desc',
            },
            limit: limit,
            page,
            depth: 1,
            where: {
                and: query,
            },
        });
    }

    @dataProvider('event')
    public async getEvent(slug: string): Promise<Event> {
        return this.eventRepository.findBySlug(slug);
    }
}

