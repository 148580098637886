import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {inject, Lifecycle, registry, scoped} from "tsyringe";
import {$ServerRequest} from "../tools/context";
import i18next, {i18n} from "i18next";
import config from "../config";
import {isServer} from "../tools/isServer";
import {logger} from "../tools/logger";
import {isNative} from "../tools/isNative";
import {Preferences} from "@capacitor/preferences";

@scoped(Lifecycle.ContainerScoped)
@registry([{
    token: 'OptionalServerRequest',
    useFactory: (c) => (
        c.isRegistered($ServerRequest) ? c.resolve($ServerRequest) : null
    )
}])
export class PayloadClient {
    private readonly client: AxiosInstance;

    constructor(@inject('OptionalServerRequest') req?: { i18n: i18n }) {
        this.client = axios.create({
            baseURL: isServer ? config.backendApiForSsr : config.backendApi,
            headers: {
                'Content-Type': 'application/json',
            },
            // only send cookies in case of web, otherwise we use bearer token
            withCredentials: !isNative,
        });

        this.client.interceptors.request.use((config) => {
            const currentLocale = req?.i18n.language || i18next.language;

            if (config.url?.indexOf('locale=') === -1) {
                config.url += `${config.url.indexOf('?') === -1 ? '?' : '&'}locale=${currentLocale}`
            }

            if (config.url?.indexOf('fallback-locale=') === -1) {
                config.url += `${config.url.indexOf('?') === -1 ? '?' : '&'}fallback-locale=hu` // TODO: move fallback locale to config
            }

            config.headers['Accept-Language'] = [`${currentLocale};q=0.9`, 'hu;q=0.8', '*;q=0.5'].join(', ')

            logger.debug(`Fetching data from ${config.baseURL}${config.url}`)

            return config;
        }, (error) => {
            return Promise.reject(error);
        });

    }

    public async get<T>(url: string): Promise<{
        data: T
    }> {
        return this.client.get(url, await this.createConfig());
    }

    public async post<T>(url: string, body: unknown): Promise<{
        data: T
    }> {
        return this.client.post(url, body, await this.createConfig());
    }

    public async delete<T>(url: string): Promise<{
        data: T
    }> {
        return this.client.delete(url, await this.createConfig());
    }

    public async patch<T>(url: string, body: unknown): Promise<{
        data: T
    }> {
        return this.client.patch(url, body, await this.createConfig());
    }

    private async createConfig() {
        const config: AxiosRequestConfig = {}
        if (isNative) {
            const token = await Preferences.get({
                key: 'payload-token'
            })

            if (token.value) {
                config.headers = {
                    Authorization: `JWT ${token.value}`
                }
            }
        }
        return config;
    }
}
