import React, {HTMLAttributes} from 'react';
import {useTheme} from '../../hooks/useTheme';
import {useTranslation} from 'react-i18next';

export const Slogan = ({ className, style, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <div
      style={{
        ...(style || {}),
        ...{ color: colors.primary }
      }}
      className={`font-fancy italic whitespace-nowrap z-10 ${className || ''}`}
      {...props}
    >
      {t('Join us!')}
    </div>
  )
}
