import React, {useEffect} from "react";

import {BsApple, BsFacebook, BsGoogle} from 'react-icons/bs'
import {Link, useNavigate} from "react-router-dom";
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import {Trans, useTranslation} from "react-i18next";
import {HiHome} from "react-icons/hi";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import {FiLogIn} from "react-icons/fi";
import {useAuthenticatedUser} from "../hooks/useAuthenticatedUser";
import {LoginForm} from "../organisms/login-form/LoginForm";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";
import {useFederatedLogin} from "../hooks/useFederatedLogin";
import {LoginButton} from "../atoms/login-button/LoginButton";


export default function LoginPage() {
    const {t} = useTranslation();
    const routes = useLocalizedRoutes();
    const {isAuthenticated} = useAuthenticatedUser();
    const navigate = useNavigate();
    const {doAuthorize} = useFederatedLogin();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(routes.settings())
        }
    }, [isAuthenticated, navigate, routes]);

    return (
        <>
            <MetaTitle subtitle={t('Login')}/>
            <div className='mb-4'>
                <h1>{t('Login')}</h1>

                <Breadcrumbs className='jk-a-underline' hideDefault={true} items={[
                    {
                        href: routes.home(),
                        label: t('Home'),
                        icon: <HiHome/>,
                    },
                    {
                        href: routes.login(),
                        label: t('Login'),
                        icon: <FiLogIn/>,
                    }
                ]}/>

                <section className='md:w-2/3 lg:w-1/2'>
                    <LoginForm/>

                    <p className="mt-4 text-sm jk-a-underline">
                        <Trans
                            i18nKey={"Dont have account? <0>Create an account</0> or login with one of your public account."}
                            components={[<Link key='register' to={routes.signUp()}></Link>]}
                        />
                    </p>

                    <div className="flex flex-row justify-around text-4xl mt-4">
                        <Link className='flex hover:brightness-110' to="#" onClick={() => doAuthorize('google')}>
                            <LoginButton aria-label={t('Continue with Google') || ''}><BsGoogle/></LoginButton>
                        </Link>
                        <Link className='flex hover:brightness-110' to="#" onClick={() => doAuthorize('apple')}>
                            <LoginButton aria-label={t('Continue with Apple') || ''}><BsApple className='relative -top-[2px]'/></LoginButton>
                        </Link>
                    </div>
                </section>
            </div>
        </>
    )
}
