import {User} from '@jklubcafe/backend-api-dto';
import {UserRepository} from '../repositories/UserRepository';
import {Lifecycle, scoped} from "tsyringe";
import {dataProvider} from "../hooks/useData";
import {Preferences} from "@capacitor/preferences";
import {isNative} from "../tools/isNative";

@scoped(Lifecycle.ContainerScoped)
export class UserService {

  constructor(private readonly userRepository: UserRepository) {
  }

  @dataProvider('me')
  public async me(): Promise<User> {
    return this.userRepository.me().then(response => response.user);
  }

  public async login(username: string, password: string) {
    const response = await this.userRepository.login(username, password);

    if (isNative) {
      await Preferences.set({key: 'payload-token', value: response.token})
    }

    return response;
  }

  public async loginFederated(provider: string, state: string, code: string, codeVerifier: string) {
    const response = await this.userRepository.loginFederated(provider, state, code, codeVerifier);

    if (isNative) {
      await Preferences.set({key: 'payload-token', value: response.token})
    }

    return response;
  }

  public async logout() {
    await this.userRepository.logout();

    if (isNative) {
      await Preferences.remove({key: 'payload-token'});
    }
  }

  public signUp(email: string, password: string) {
    return this.userRepository.create({
      email,
      password,
    });
  }

  public async delete(id: string) {
    const response = await this.userRepository.deleteById(id);

    if (isNative) {
      await Preferences.remove({key: 'payload-token'});
    }

    return response;
  }

  public forgotPassword(username: string, passwordResetUrl: string) {
    return this.userRepository.forgotPassword(username, passwordResetUrl);
  }

  public async resetPassword(token: string, newPassword: string) {
    const response = await this.userRepository.resetPassword(token, newPassword);

    if (isNative) {
      await Preferences.set({key: 'payload-token', value: response.token})
    }

    return response;
  }
}
