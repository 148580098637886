import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import {Trans, useTranslation} from "react-i18next";
import {HiHome} from "react-icons/hi";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import {FiUserPlus} from "react-icons/fi";
import {useAuthenticatedUser} from "../hooks/useAuthenticatedUser";
import {SignUpForm} from "../organisms/sign-up-form/SignUpForm";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";


export default function SignUpPage() {
    const {t} = useTranslation();
    const routes = useLocalizedRoutes();
    const {isAuthenticated} = useAuthenticatedUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(routes.settings())
        }
    }, [isAuthenticated, navigate, routes]);

    return (
        <>
            <MetaTitle subtitle={t('Sign Up')}/>
            <div>
                <h1>{t('Sign Up')}</h1>

                <Breadcrumbs className='jk-a-underline' hideDefault={true} items={[
                    {
                        href: routes.home(),
                        label: t('Home'),
                        icon: <HiHome/>,
                    },
                    {
                        href: routes.login(),
                        label: t('Sign Up'),
                        icon: <FiUserPlus/>,
                    }
                ]}/>

                <section className='md:w-2/3 lg:w-1/2'>
                    <SignUpForm />

                    <p className="mt-4 text-sm jk-a-underline">
                        <Trans
                            i18nKey='Already have an account? <0>Log in</0> now.'
                            components={[<Link key='login' to={routes.login()}/>]}
                        />
                    </p>
                </section>
            </div>
        </>
    )
}
