import {useAuthenticatedUser} from "../hooks/useAuthenticatedUser";
import {Link, useNavigate} from "react-router-dom";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import {HiHome} from "react-icons/hi";
import {IoMdSettings} from "react-icons/io";
import {Button} from "../atoms/button/Button";
import {useInjection} from "../hooks/useInjection";
import {UserService} from "../services/UserService";
import {NotificationSettingsForm} from "../organisms/notification-settings-form/NotificationSettingsForm";

export const SettingsPage = () => {
    const {user, isAuthenticated, mutate} = useAuthenticatedUser();
    const navigate = useNavigate();
    const routes = useLocalizedRoutes();
    const {t} = useTranslation();
    const userService = useInjection(UserService);

    const onDeleteAccount = useCallback(() => {
        if (user) {
            userService.delete(user.id)
                .then(() => mutate(undefined))
                .then(() => navigate(routes.home()));
        }
    }, [userService, user]);

    return (
        <>
            <MetaTitle subtitle={t('Settings')}/>

            <h1>{t('Settings')}</h1>

            <Breadcrumbs className='jk-a-underline' hideDefault={true} items={[
                {
                    href: routes.home(),
                    label: t('Home'),
                    icon: <HiHome/>,
                },
                {
                    href: routes.login(),
                    label: t('Settings'),
                    icon: <IoMdSettings/>,
                }
            ]}/>

            <section className='gap-2 flex flex-col content-start justify-start mb-6 md:w-2/3 lg:w-1/2'>
                <h2>{t('Notification Settings')}</h2>

                <NotificationSettingsForm/>
            </section>

            {isAuthenticated && <section className='gap-2 flex flex-col md:w-2/3 lg:w-1/2'>
                <h2>{t('Account Settings')}</h2>

                <p>{t('By choosing the below option you can logout from the application on this device.')}</p>

                <p>
                    <Link to={routes.logout()}>
                        <Button type='submit'>{t('Logout')}</Button>
                    </Link>
                </p>

                <p>{t("If you don't want to be a JATE Klub member any more, you can delete your user account. All of your account details will be removed permanently.")}</p>

                <p>
                    <Button onClick={onDeleteAccount} type='submit'>{t('Delete Account')}</Button>
                </p>
            </section>}
        </>
    );
}
