import React from 'react';
import {CafeSlug} from './useCafeSlug';

export type Theme = 'radnoti' | 'tik' | 'jate';

export interface Colors {
    dark: string;
    light: string;
    primary: string;
}

export const ThemeColors: Record<Theme, Colors> = {
    radnoti: {
        dark: '#0d5d81',
        light: '#83cce0',
        primary: '#33c1da',
    },
    tik: {
        dark: '#05674b',
        light: '#5ddcbb',
        primary: '#15b592',
    },
    jate: {
        primary: '#fec21e',
        light: '#e7d38e',
        dark: '#dc7200',
    }
}

const cafeThemeMapping = new Map<CafeSlug, Theme>([
    [CafeSlug.Jate, 'jate'],
    [CafeSlug.Tik, 'tik'],
    [CafeSlug.Radnoti, 'radnoti'],
])

export const ThemeContext = React.createContext<{
    theme: Theme;
    colors: Colors;
}>({
    theme: 'jate',
    colors: ThemeColors.jate
})

export const useTheme = () => {
    return React.useContext(ThemeContext);
}

export const getCafeTheme = (slug: CafeSlug) => {
    if (!cafeThemeMapping.has(slug)) {
        throw new Error(`Cafe theme not found for slug ${slug}`);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const theme = cafeThemeMapping.get(slug)!;

    return {
        theme,
        colors: ThemeColors[theme],
    }
}
