import React, {HTMLAttributes} from "react";
import {BiChevronRight} from "react-icons/bi";
import {HiHome} from "react-icons/hi";
import {IoMdCafe} from "react-icons/io";
import {useCafe} from "../../hooks/useCafe";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {isNative} from "../../tools/isNative";

interface BreadcrumbItemProps {
    label: string;
    href: string;
    icon?: React.ReactNode;
}

interface BreadcrumbsProps extends HTMLAttributes<HTMLDivElement> {
    items?: BreadcrumbItemProps[];
    hideDefault?: boolean;
    lastClickable?: boolean;
}

const Breadcrumb = ({label, href, icon, last, lastClickable}: BreadcrumbItemProps & { last: boolean, lastClickable: boolean }) => {
    const labelWithIcon = (
        <>
            {icon && icon} <span className={`text-sm font-medium`}>{label}</span>
        </>
    )

    return (
        <li className="m-0 p-0 whitespace-nowrap flex-shrink">
            {(!last || lastClickable) ? <Link to={href} className="items-center">
                {labelWithIcon}
            </Link> : labelWithIcon}
            {!last && <BiChevronRight className='ml-1 md:ml-2'/>}
        </li>
    )
}

export const Breadcrumbs = ({items, hideDefault, className, lastClickable, ...props}: BreadcrumbsProps) => {
    const routes = useLocalizedRoutes();
    const cafe = useCafe();
    const {t} = useTranslation();
    const [allItems, setAllItems] = React.useState<BreadcrumbItemProps[]>([]);

    React.useEffect(() => {
        const defaultItems = cafe && !hideDefault ? [
            {
                href: routes.home(),
                label: t('Home'),
                icon: <HiHome/>,
            }, {
                href: routes.cafe(cafe).main(),
                label: cafe.name,
                icon: <IoMdCafe/>,
            },
        ] : [];
        setAllItems([...defaultItems, ...(items || [])])
    }, [cafe, items, t])

    return (
        !isNative && allItems?.length ? <nav
            {...props}
            className={`flex mb-6 jk-a-underline ${className}`}
            aria-label="Breadcrumb">

            <ol className="inline-flex items-center space-x-1 md:space-x-2 max-w-full">
                {allItems.map((item, index) => (
                    <Breadcrumb key={index} {...item} last={index === allItems.length - 1} lastClickable={!!lastClickable}/>
                ))}
            </ol>
        </nav> : null
    )
}
