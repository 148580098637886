import React from "react";
import {EventService} from '../services/EventService';
import {useTranslation} from 'react-i18next';
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import {useCafeSlug} from "../hooks/useCafeSlug";
import {getStaticData, useData} from "../hooks/useData";
import {Outlet} from "react-router-dom";
import {StaticData, StaticDataArgs} from "../tools/context";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";
import {InfinitePagination} from "../organisms/infinite-pagination/InfinitePagination";
import {Event} from "@jklubcafe/backend-api-dto";
import {EventCard} from "../organisms/event-card/EventCard";

const ALL_ITEMS = 1000;
const PAGE_SIZE = 12;

export const EventsPage = () => {
    const {t} = useTranslation();
    const cafeSlug = useCafeSlug();
    const {data: futureEvents} = useData(EventService, EventService.prototype.getPublishedEventsPage, cafeSlug, 'future', 1, ALL_ITEMS)

    return (
        <>
            <MetaTitle subtitle={t('Events')}/>

            <div className="pagetitle jk-a-underline">
                <h1>{t('Events')}</h1>
                <Breadcrumbs/>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {futureEvents?.docs.map((event) => <EventCard event={event} key={`event-card-${event.id}`}/>)}

                <InfinitePagination<Event>
                    size={PAGE_SIZE}
                    fetcherProps={{
                        token: EventService,
                        method: EventService.prototype.getPublishedEventsPage,
                        parameters: [cafeSlug, 'past'],
                    }}
                    Item={({data}) => <EventCard event={data}/>}
                />
            </div>

            <Outlet/>
        </>
    )
}

EventsPage.staticData = async ({params, context}: StaticDataArgs): Promise<StaticData> => {
    return {
        fallback: {
            ...await getStaticData(context, EventService, EventService.prototype.getPublishedEventsPage, params.cafeSlug, 'future', 1, ALL_ITEMS),
            ...await getStaticData(context, EventService, EventService.prototype.getPublishedEventsPage, params.cafeSlug, 'past', 1, PAGE_SIZE),
        }
    }
}
