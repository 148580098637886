import {AiFillExclamationCircle, AiFillWarning} from "react-icons/ai";
import React, {ComponentType} from "react";

interface MessageBoxProps {
    type: 'error' | 'warning';
    message?: string;
    visible?: boolean
}

const icons: Record<
    MessageBoxProps['type'],
    ComponentType<{ className?: string }>
> = {
    'error': AiFillExclamationCircle,
    'warning': AiFillWarning,
}

export const MessageBox = ({type, message, visible}: MessageBoxProps) => {
    const Icon = icons[type];
    return (
        (visible !== false && message) ? <div className={
            `rounded-md border p-2 text-xs whitespace-nowrap` +
            (type === 'error' && ' border-red-500 bg-red-300 text-red-900') +
            (type === 'warning' && ' border-yellow-500 bg-yellow-300 text-yellow-900')
        }>
            <Icon className='mr-2'/> {message}
        </div> : null
    )
};
