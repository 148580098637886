import React, {HTMLAttributes} from 'react';
import {useTheme} from '../../hooks/useTheme';

interface SeparatorProps extends HTMLAttributes<HTMLHeadingElement>{
  title: string;
}

export const Separator = ({ title, className, ...props }: SeparatorProps) => {
  const { colors } = useTheme();

  return (
    <h4
      style={{
        borderColor: colors.primary,
        color: colors.primary,
        lineHeight: 0,
      }}
      className={`text-center h-0 border-b overflow-visible block w-full ${className}`}
      {...props}
    >
      <span className="bg-jk-gray px-4">{title}</span>
    </h4>
  )
}
