import React, {HTMLAttributes} from "react";
import {Link} from "react-router-dom";
import styles from './FooterSimple.module.css';
import {useData} from "../../hooks/useData";
import {FooterNavigationService} from "../../services/FooterNavigationService";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {isNative} from "../../tools/isNative";
import {FooterNative} from "../footer-native/FooterNative";

export const FooterSimple = ({className, ...props}: HTMLAttributes<HTMLUListElement>) => {
    const {data: footerNavigation} = useData(FooterNavigationService, FooterNavigationService.prototype.getFooterNavigation);
    const routes = useLocalizedRoutes();

    return (isNative ? <FooterNative /> :
        <footer className='pb-safe'>
            <ul className={`flex flex-col sm:flex-row gap-1 sm:gap-6 ${styles.footerSimple} ${className}`} {...props}>
                {footerNavigation?.map((item) => (
                    <li key={item.id}><Link to={routes.article(item)}>{item.title}</Link></li>
                ))}
            </ul>
        </footer>
    )
};
