import React, {useEffect} from "react";

interface IntersectionObserverProps {
    target: React.RefObject<Element>;
    onIntersect: () => void;
    options?: IntersectionObserverInit;
}

export const useIntersectionObserver = ({target, onIntersect, options}: IntersectionObserverProps) => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    onIntersect();
                }
            },
            options,
        );

        if (target.current) {
            observer.observe(target.current);
        }

        return () => {
            if (target.current) {
                observer.unobserve(target.current);
            }
        };
    }, [target, onIntersect, options]);
}
