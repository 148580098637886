import {Article} from '@jklubcafe/backend-api-dto';
import {Lifecycle, scoped} from 'tsyringe';
import {PayloadClient} from './PayloadClient';
import {AbstractRepository} from './AbstractRepository';

@scoped(Lifecycle.ContainerScoped)
export class ArticleRepository extends AbstractRepository<Article> {
  constructor(client: PayloadClient) {
    super(client, 'articles');
  }
}
