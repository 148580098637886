import React, {useEffect, useState} from "react";

interface ClientComponentProps {
    serverComponent: React.ReactNode
    children: React.ReactNode
}

/**
 * This component is used to render a component differently on the server and on the client side. This is useful when
 * there is client specific information required to render a component, such as client's time zone.
 *
 * @param children - the component to render on the client side
 * @param serverComponent - the component to render on the server side
 * @constructor
 */
export const ClientComponent = ({children, serverComponent}: ClientComponentProps) => {
    const [mounted, setMounted] = useState<boolean>(false);

    // gets executed only on client side after hydration
    useEffect(() => {
        setMounted(true);
    }, [setMounted]);

    return (
        <>
            {mounted ? children : serverComponent}
        </>
    )
}
