import {Lifecycle, scoped} from "tsyringe";
import {PushNotificationRecipientsRepository} from "../repositories/PushNotificationRecipientsRepository";
import {logger} from "../tools/logger";
import {FirebaseMessaging, GetTokenOptions} from "@capacitor-firebase/messaging";
import config from "../config";
import {isWeb} from "../tools/isWeb";
import {initializeApp} from "firebase/app";
import i18n, {Locale} from "../i18n";
import {Device} from "@capacitor/device";
import {PushNotificationRecipient, Event} from "@jklubcafe/backend-api-dto";
import {dataProvider} from "../hooks/useData";

if (isWeb) {
    initializeApp(config.firebase);
}

export interface OnNotificationActionEvent {
    title: string;
    body: string;
    data: {
        locale: Locale;
        event?: Event;
    };
}

export interface SubscriptionProps {
    onNotificationAction: OnNotificationAction;
}

export type OnNotificationAction = (event: OnNotificationActionEvent) => void;


@scoped(Lifecycle.ContainerScoped)
export class NotificationService {
    private readonly options: GetTokenOptions;

    constructor(private readonly recipientsRepository: PushNotificationRecipientsRepository) {
        this.options = {
            vapidKey: config.firebase.vapidKey,
        }
    }

    public async subscribe(props: SubscriptionProps) {
        const isSupported = await FirebaseMessaging.isSupported();
        if (!isSupported) {
            throw new Error("Push notifications are not supported on this platform");
        }

        await this.registerServiceWorker(props);

        const granted = await this.requestPermission();

        if (granted) {
            const token = await this.getToken();
            const recipient = await this.updateRecipient({
                token,
            });
            await FirebaseMessaging.removeAllListeners();
            FirebaseMessaging.addListener("notificationActionPerformed", (e) => {
                props.onNotificationAction({
                    title: e.notification.title || "", // allways defined in case of JATE Klub
                    body: e.notification.body || "", // allways defined in case of JATE Klub
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data: e.notification.data?.data ? JSON.parse(e.notification.data.data) : undefined,
                });
            })
            logger.debug(`Subscribed to push notifications with token ${token}`);
            return recipient;
        } else {
            throw new Error("Permission denied for push notifications");
        }
    }

    public async updateRecipient(recipient?: Partial<PushNotificationRecipient>) {
        const deviceId = (await Device.getId()).identifier;
        return await this.recipientsRepository.upsert({
            deviceId: {
                equals: deviceId,
            }
        }, {
            ...(recipient || {}),
            deviceId,
            locale: i18n.language as Locale,
        });
    }

    @dataProvider('getRecipient')
    public async getRecipient() {
        const deviceId = (await Device.getId()).identifier;
        const existing = await this.recipientsRepository.findOne({
            where: {
                deviceId: {
                    equals: deviceId,
                }
            }
        })
        return existing || await this.updateRecipient();
    }

    private async requestPermission() {
        const permissionStatus = await FirebaseMessaging.requestPermissions();
        logger.debug(`Requested permission for push notifications`);
        return permissionStatus.receive === 'granted';
    }

    private async getToken() {
        const token = await FirebaseMessaging.getToken(this.options);
        logger.debug(`Retrieved FCM token ${token.token}`);
        return token.token;
    }

    private async registerServiceWorker({onNotificationAction}: SubscriptionProps) {
        if (isWeb && !this.options.serviceWorkerRegistration) {
            this.options.serviceWorkerRegistration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");

            navigator.serviceWorker.addEventListener("message", (event: any) => {
                logger.debug("serviceWorker message: ", {event});

                const notification = new Notification(event.data.notification.title, {
                    body: event.data.notification.body,
                    image: event.data.notification.image,
                });

                notification.onclick = (event: any) => {
                    logger.debug("notification clicked: ", {event});
                    onNotificationAction({
                        title: event.data.notification.title,
                        body: event.data.notification.body,
                        data: event.data.data?.data ? JSON.parse(event.data.data.data) : undefined,
                    });
                };
            });

            logger.debug("Registered service worker for push notifications");
        }
    }

    public async isEnabled() {
        try {
            return await this.requestPermission();
        } catch (e) {
            return false;
        }
    }
}
