import {Lifecycle, scoped} from "tsyringe";
import {dataProvider} from "../hooks/useData";
import {Partner} from "@jklubcafe/backend-api-dto";
import {PartnerRepository} from "../repositories/PartnerRepository";

@scoped(Lifecycle.ContainerScoped)
export class PartnerService {
    constructor(private readonly partnerRepository: PartnerRepository) {
    }

    @dataProvider('partners')
    public async getPartners(): Promise<Partner[]> {
        return this.partnerRepository.paginate(this.partnerRepository.findAll, {
            sort: {
                field: 'sortOrder',
                order: 'asc',
            },
        });
    }
}
