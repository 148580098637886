import {LogoCafeRadnoti} from '../../atoms/logo-cafe-radnoti/LogoCafeRadnoti';
import React from 'react';
import {LogoCafeTik} from '../../atoms/logo-cafe-tik/LogoCafeTik';
import {LogoJateKlub} from '../../atoms/logo-jate-klub/LogoJateKlub';
import {IconCircle} from '../../molecules/icon-circle/IconCircle';
import {CafeSlug} from '../../hooks/useCafeSlug';
import {getCafeTheme, useTheme} from "../../hooks/useTheme";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

interface CafeSelectorProps {
    className?: string;
    selected: CafeSlug;
    onClick?: (cafeSlug: CafeSlug) => void;
}

const slides = [
    {slug: CafeSlug.Radnoti, iconComponent: LogoCafeRadnoti},
    {slug: CafeSlug.Jate, iconComponent: LogoJateKlub},
    {slug: CafeSlug.Tik, iconComponent: LogoCafeTik},
];

const CafeButton = ({slug, onClick, className}: {
    slug: CafeSlug,
    onClick?: (cafeSlug: CafeSlug) => void,
    className?: string
}) => {
    const Icon = slides.find(s => s.slug === slug)!.iconComponent;
    const theme = getCafeTheme(slug).theme;

    return (
        <IconCircle
            className={`border-jk-${theme} ${className || ''}`}
            onClick={onClick?.bind(this, slug)}
        >
            <Icon className={`fill-jk-${theme} max-h-full max-w-full`}/>
        </IconCircle>
    )
}

const SzteButton = ({className}: { className?: string }) => (
    <Link to='https://u-szeged.hu/' target='_blank' rel='noreferrer' className={className}>
        <IconCircle className={`border-[#11aae2]`}>
            <img src="/images/logo_szte.png" className='max-h-full max-w-full aspect-auto' alt='SZTE'/>
        </IconCircle>
    </Link>
)

const OurPartners = ({className}: { className?: string }) => {
    const {colors} = useTheme();
    const {t} = useTranslation();

    return (
        <div
            className={`text-center text-sm ${className}`}
            style={{color: colors.primary, animationDuration: '5s'}}
        >{t('Our Partners')}</div>
    )
}

export const CafeSelector = ({className, onClick}: CafeSelectorProps) => {
    return (
        <div className={`${className || ''} grid grid-cols-1 md:grid-cols-5 w-full max-w-[70rem]`}>
            <CafeButton slug={CafeSlug.Jate} onClick={onClick} className='md:row-span-4 md:col-span-3 m-10 md:mx-24'/>
            <OurPartners className='md:col-span-2 md:my-6'/>
            <SzteButton className='md:col-span-2 my-6 mx-[20%] md:mx-28 md:my-10'/>
            <CafeButton slug={CafeSlug.Radnoti} onClick={onClick} className='mx-[30%] my-6 md:mx-14'/>
            <CafeButton slug={CafeSlug.Tik} onClick={onClick} className='mx-[30%] my-6 md:mx-14'/>
        </div>
    )
}
