import {AbstractRepository} from "./AbstractRepository";
import {Gallery} from "@jklubcafe/backend-api-dto";
import {PayloadClient} from "./PayloadClient";
import {Lifecycle, scoped} from "tsyringe";

@scoped(Lifecycle.ContainerScoped)
export class GalleryRepository extends AbstractRepository<Gallery> {
    constructor(client: PayloadClient) {
        super(client, 'galleries');
    }
}
