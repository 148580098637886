import {useTranslation} from "react-i18next";
import React from "react";

const Hu = () => (
    <a href='https://play.google.com/store/apps/details?id=com.jateklub.app&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
       className={"inline-block overflow-hidden h-[83px]"}>
        <img
            alt='Szerezd meg: Google Play'
            className="h-full object-contain"
            src='https://play.google.com/intl/en_us/badges/static/images/badges/hu_badge_web_generic.png'/>
    </a>
)

const En = () => (
    <a href='https://play.google.com/store/apps/details?id=com.jateklub.app&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
       className={"inline-block overflow-hidden h-[83px]"}>
        <img
            alt='Get it on Google Play'
            className="h-full object-contain"
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
    </a>
)

export const BtnGetOnGooglePlay = () => {
    const {i18n} = useTranslation();

    return (
        i18n.language === 'hu' ? <Hu/> : <En/>
    )
}
