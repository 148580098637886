import {useEffect, useState} from "react";
import {App} from "@capacitor/app";
import {isNative} from "../tools/isNative";

export const useAppVersion = () => {
    const [appVersion, setAppVersion] = useState<{
        version: string,
        build: number,
    }>()

    useEffect(() => {
        if (isNative) {
            App.getInfo().then((info) => {
                setAppVersion({
                    version: info.version,
                    build: parseInt(info.build)
                })
            });
        }
    }, [setAppVersion])

    return {
        ...appVersion
    };
}
