import {Cafe, Event, Media} from '@jklubcafe/backend-api-dto';
import React, {HTMLAttributes, useCallback, useMemo} from 'react';
import {FaClock, FaLink, FaMapMarkerAlt, FaShareAlt} from 'react-icons/fa';
import {Trans, useTranslation} from 'react-i18next';
import {Button} from '../../atoms/button/Button';
import {ShareLink} from "../../atoms/share-link/ShareLink";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {absoluteUrl} from "../../tools/url";
import {Link} from "react-router-dom";
import {BsQrCodeScan} from "react-icons/bs";
import {useAuthenticatedUser} from "../../hooks/useAuthenticatedUser";
import {useData} from "../../hooks/useData";
import {EventCouponService} from "../../services/EventCouponService";
import {useTheme} from "../../hooks/useTheme";
import {QrCode} from "../../atoms/qr-code/QrCode";
import {useCoupon} from "../../hooks/useCoupon";
import {RiCoupon3Fill} from "react-icons/ri";
import {useImage} from "../../hooks/useImage";
import {GalleryService} from "../../services/GalleryService";
import {IoMdPhotos} from "react-icons/io";
import {useTreeNavigate} from "../../hooks/useTreeNavigate";
import parse from "html-react-parser";
import {oneLineTrim} from "common-tags";
import {ClientComponent} from "../../atoms/client-component/ClientComponent";
import {IsoTime} from "../../atoms/iso-time/IsoTime";

export interface EventDetailsProps {
    event: Event;
}

const Card = ({children, className, ...props}: { children: React.ReactNode } & HTMLAttributes<HTMLDivElement>) => (
    <div
        className={`border-jk-white-dark border bg-jk-white-light rounded-xl overflow-hidden p-4 ${className}`}
        {...props}
    >
        {children}
    </div>
)

const Toolbar = ({event}: EventDetailsProps) => {
    const {t} = useTranslation();
    const routes = useLocalizedRoutes();
    const {hasRole} = useAuthenticatedUser();
    const {coupon, loading, toggleCoupon} = useCoupon(event);
    const {data: gallery} = useData(GalleryService, GalleryService.prototype.getGalleryByEvent, event.slug);
    const {locationDown} = useTreeNavigate();

    return (
        <div className="flex flex-col gap-2">
            {gallery &&
                <Link to={locationDown('gallery', gallery.slug)} className='basis-full'>
                    <Button className='w-full'><IoMdPhotos className="mr-2"/> {t('Gallery')}</Button>
                </Link>
            }
            {event.link &&
                <Link to={event.link} target='_blank' rel='noreferrer' className='basis-full'>
                    <Button className='w-full'><FaLink className="mr-2"/> {t('More Details')}</Button>
                </Link>
            }
            <ShareLink text={oneLineTrim(event.intro || '')} title={event.title} url={absoluteUrl(routes.event(event).details())}>
                <Button className='w-full'><FaShareAlt className="mr-2"/> {t('Share')}</Button>
            </ShareLink>
            <Button className='basis-full' disabled={loading} onClick={toggleCoupon}>
                <RiCoupon3Fill className="mr-2"/> {t(coupon ? 'Unsubscribe' : 'Subscribe')}
            </Button>
            {hasRole('qr-admin') &&
                <Link to={routes.couponValidation(event)} className='basis-full'>
                    <Button className='w-full' outline>
                        <BsQrCodeScan className="mr-2"/> {t('Coupon Validation')}
                    </Button>
                </Link>
            }
        </div>
    )
}

const EventDescription = ({event}: EventDetailsProps) => {
    const {i18n} = useTranslation();
    const {image} = useImage((event.image as Media)?.url);
    const {data: gallery} = useData(GalleryService, GalleryService.prototype.getGalleryByEvent, event.slug);
    const {goLocationDown} = useTreeNavigate();

    const eventDate = new Date(event.datetime).toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })

    const openGallery = useCallback(() => {
        if (gallery) {
            goLocationDown('gallery', gallery.slug);
        }
    }, [gallery]);

    return (
        <Card className='!p-0'>
            {image && <img onClick={openGallery} className={`w-full h-60 object-cover ${gallery ? 'cursor-pointer': ''}`} src={image.src} alt={event.title}/>}
            <div className='p-4'>
                <h3>{event.title}</h3>
                <h4 className="text-sm text-jk-gray-light leading-10">
                    <span className="mr-5 whitespace-nowrap"><FaMapMarkerAlt
                        className="mr-1"/> {(event.cafe as Cafe).name}</span>
                    <span className="mr-5 whitespace-nowrap">
                        <FaClock className="mr-1"/>
                        <ClientComponent serverComponent={<IsoTime datetime={event.datetime}/>}>
                            {eventDate}
                        </ClientComponent>
                    </span>
                </h4>
                <section className="text-sm md:text-base">
                    {event.intro && <p className="font-bold mb-6">
                        {parse(event.intro.replace(/\r\n|\r|\n/g, '<br />'), {
                            trim: true,
                        })}
                    </p>}
                    {event.description && <div className='rich-text'>
                        {parse(event.description, {
                            trim: true,
                        })}
                    </div>}
                </section>
            </div>
        </Card>
    )
}

interface CouponCodeProps extends HTMLAttributes<HTMLDivElement> {
    event: Event,
}

const CouponCode = ({event, className, ...props}: CouponCodeProps) => {
    const {colors} = useTheme();
    const {data: coupons} = useData(EventCouponService, EventCouponService.prototype.getUserCoupons);
    const coupon = useMemo(() => coupons?.find(coupon => coupon.event === event.id), [coupons]);
    const redeemed = useMemo(() => !!coupon?.attended_at, [coupon]);

    return (coupon && coupon.code ?
            <Card className={`flex flex-col gap-2 justify-center ${className || ''}`} {...props}>
                <p className='mb-2 text-sm md:text-base'>
                    <Trans
                        i18nKey={redeemed ? 'You have already used your coupon <0>{{coupon}}</0>.' : 'Use your coupon <0>{{coupon}}</0> when participating on the event. You can either show the QR code on your phone or use the 8 letters code.'}
                        components={[<strong key={coupon.code}/>]}
                        values={{coupon: coupon.code}}
                    />
                </p>
                <>
                    <div
                        className={`${redeemed ? `bg-jk-gray-dark opacity-10` : ''} w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto`}>
                        <QrCode value={coupon.code} className={`w-full`}/>
                    </div>
                    <span className={`text-2xl text-center ${redeemed && 'text-jk-white-dark'}`}
                          style={redeemed ? {} : {color: colors.dark}}>{coupon?.code}</span>
                </>
            </Card> : null
    )
}

export const EventDetails = ({event}: EventDetailsProps) => {

    return (
        <div className="w-full flex flex-col lg:flex-row gap-2 lg:gap-4">
            <div className='basis-full lg:basis-2/3 flex flex-col gap-2 lg:gap-4'>
                <EventDescription event={event}/>
                <CouponCode event={event}/>
            </div>

            <div className='
                lg:basis-1/3
                flex flex-col
                gap-2 lg:gap-4
            '>
                <Toolbar event={event}/>
            </div>
        </div>
    );
};
