import React, {useCallback, useMemo} from 'react';
import {SimpleCalendarEvent, SimpleCalendarProps} from './SimpleCalendarProps';
import {SimpleCalendarMonth} from './SimpleCalendarMonth';
import {Splide as ReactSplide, SplideSlide as ReactSplideSlide} from '@splidejs/react-splide';
import {SlideComponent, Splide} from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

const createMonthRange = (firstMonth: Date, lastMonth: Date) => {
    const shiftDateByMonths = (date: Date, months: number) => {
        const d = new Date(date.valueOf());
        d.setMonth(d.getMonth() + months);
        return d;
    }

    const firstDayOfMonth = (date: Date) => {
        const d = new Date(date.valueOf());
        d.setDate(1);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    const last = firstDayOfMonth(lastMonth);
    const monthRange = [];
    for (let current = firstDayOfMonth(firstMonth); current <= last; current = shiftDateByMonths(current, 1)) {
        monthRange.push(current);
    }
    return monthRange;
}

const createEventMap = (events: SimpleCalendarEvent[]) => events.reduce((map, event) => {
    const k = event.date.toDateString();
    if (!map.has(k)) {
        map.set(k, []);
    }
    map.get(k)!.push(event);
    return map;
}, new Map<string, Array<SimpleCalendarEvent>>());

export default function SimpleCalendar({events, initialMonth, onMonthSelected, ...props}: SimpleCalendarProps) {
    const today = useMemo(() => new Date(), []);
    const eventsOrdered = useMemo(() => events.sort((a, b) => a.date.getTime() - b.date.getTime()), [events]);
    const firstDate = useMemo(() => new Date(Math.min((eventsOrdered.length ? eventsOrdered[0].date : today).getTime(), today.getTime())), [eventsOrdered, today]);
    const lastDate = useMemo(() => new Date(Math.max((eventsOrdered.length ? eventsOrdered[eventsOrdered.length - 1].date : today).getTime(), today.getTime())), [eventsOrdered, today]);
    const months = useMemo(() => createMonthRange(firstDate, lastDate), [firstDate, lastDate]);

    const slideIndexOf = useCallback((date: Date) => {
        return months.findIndex((d) => d.getFullYear() === date.getFullYear() && d.getMonth() === date.getMonth());
    }, [months]);

    const eventMap = useMemo(() => createEventMap(events), [events]);

    const slides = months.map((month, i) => {
        return (
            <ReactSplideSlide
                key={`month-${month.toISOString()}`}
                className='flex flex-grow !w-screen'
            >
                <SimpleCalendarMonth
                    {...props}
                    onMonthSelected={onMonthSelected}
                    month={month}
                    events={eventMap}
                />
            </ReactSplideSlide>
        );
    });

    const onReady = useCallback((splide: Splide) => {
        splide.go(slideIndexOf(initialMonth || today))
    }, [initialMonth]);

    const onActive = useCallback((splide: Splide, slide: SlideComponent) => {
        const date = months[slide.index];
        if (onMonthSelected) {
            onMonthSelected(date);
        }
    }, [onMonthSelected]);

    return (
        <ReactSplide
            onActive={onActive}
            onReady={onReady}
            options={{
                pagination: false,
                arrows: false,
            }}
            className='w-full h-full simple-calendar flex flex-grow'
        >
            {slides}
        </ReactSplide>
    );
}
