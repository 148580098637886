import {useFormik} from "formik";
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import {Input, MailIcon} from "../../atoms/input/Input";
import {Button} from "../../atoms/button/Button";
import React, {useCallback} from "react";
import {MessageBox} from "../../atoms/message-box/MessageBox";
import {useInjection} from "../../hooks/useInjection";
import {UserService} from "../../services/UserService";
import {SpinnerBackdrop} from "../../atoms/spinner-backdrop/SpinnerBackdrop";
import {useGlobalNotification} from "../../hooks/useGlobalNotification";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useReCaptcha} from "../../hooks/useReCaptcha";
import {GoogleReCaptchaBranding} from "../../atoms/google-recaptcha-branding/GoogleReCaptchaBranding";

export const PasswordResetRequestForm = () => {
    const {t} = useTranslation();
    const userService = useInjection(UserService);
    const {showNotification} = useGlobalNotification();
    const routes = useLocalizedRoutes();
    const {executeReCaptcha} = useReCaptcha('passwordResetRequest');

    const forgotPassword = useCallback((username: string) => {
        return userService
            .forgotPassword(username, routes.forgotPassword())
            .then(() => {
                showNotification({
                    type: 'success',
                    title: t('Password reset link sent'),
                    message: t('A password reset link has been sent to your email address. Please check your inbox.')
                })
            })
            .catch(({response}) => {
                const errorMessage = (response?.data?.errors || [])[0]?.message || t('Failed to send password reset link');
                showNotification({
                    type: 'error',
                    title: t('Password reset failed'),
                    message: errorMessage
                });
            });
    }, [userService]);

    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validationSchema: Yup.object({
            username: Yup
                .string()
                .email(t('Please enter a valid email address') || '')
                .required(t('Please enter your email address') || ''),
        }),
        onSubmit: ({username}, {setSubmitting}) => {
            executeReCaptcha()
                .then(() => forgotPassword(username))
                .finally(() => setSubmitting(false));
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} className='grid grid-cols-1 gap-3'>
                <p>{t('Have you forgotten your password? Ask for a password reset link which will be sent to your email address.')}</p>

                <Input
                    id='username' name='username' type='email' autoComplete='username'
                    placeholder={t('E-mail address') || ''} icon={<MailIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username}
                    required/>

                <MessageBox visible={!!(formik.touched.username && formik.errors.username)} type='error'
                            message={formik.errors.username}/>

                <GoogleReCaptchaBranding/>

                <Button type='submit' className='w-fit' disabled={formik.isSubmitting}>{t('Send Password Reset Link')}</Button>
            </form>

            {formik.isSubmitting && <SpinnerBackdrop/>}
        </>
    )
};
