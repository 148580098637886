import {useTranslation} from "react-i18next";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import {useAuthenticatedUser} from "../hooks/useAuthenticatedUser";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import {HiHome} from "react-icons/hi";
import {FaUserLock} from "react-icons/fa";
import {PasswordResetRequestForm} from "../organisms/password-reset-request-form/PasswordResetRequestForm";
import {PasswordResetForm} from "../organisms/password-reset-form/PasswordResetForm";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";

export const PasswordResetPage = () => {
    const {t} = useTranslation();
    const routes = useLocalizedRoutes();
    const {isAuthenticated} = useAuthenticatedUser();
    const navigate = useNavigate();
    const {token} = useParams();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(routes.settings())
        }
    }, [isAuthenticated, navigate, routes]);

    return (
        <>
            <MetaTitle subtitle={t('Reset Password')}/>

            <div>
                <h1>{t('Reset Password')}</h1>

                <Breadcrumbs className='jk-a-underline' hideDefault={true} items={[
                    {
                        href: routes.home(),
                        label: t('Home'),
                        icon: <HiHome/>,
                    },
                    {
                        href: routes.login(),
                        label: t('Reset Password'),
                        icon: <FaUserLock/>,
                    }
                ]}/>

                <section className='md:w-2/3 lg:w-1/2'>
                    {token ? <PasswordResetForm token={token}/> : <PasswordResetRequestForm/>}
                </section>
            </div>
        </>
    )
}
