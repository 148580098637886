import React from "react";
import './Toast.css';
import styles from './Toast.module.css';
import {CSSTransition} from 'react-transition-group';
import {AiFillCloseCircle} from "react-icons/ai";

export interface ToastProps {
    type: 'error' | 'success' | 'warning' | 'info';
    message: React.ReactNode;
    title?: string | null;
    visible?: boolean;
    onClose?: () => void;
    onClosed?: () => void;
}

const config = {
    error: {
        header: 'bg-red-700',
        body: 'bg-red-400',
        title: 'Error',
    },
    success: {
        header: 'bg-green-700',
        body: 'bg-green-600',
        title: 'Success',
    },
    warning: {
        header: 'bg-yellow-600',
        body: 'bg-yellow-500',
        title: 'Warning',
    },
    info: {
        header: 'bg-sky-800',
        body: 'bg-sky-600',
        title: 'Info',
    }
}

export const Toast = ({type, message, title, visible, onClose, onClosed}: ToastProps) => {
    const toastRef = React.useRef<HTMLDivElement>(null);
    return (
        <CSSTransition
            in={visible}
            timeout={600}
            classNames="toast"
            unmountOnExit
            nodeRef={toastRef}
            onExited={onClosed}
        >
            <div className={`w-full drop-shadow-md rounded-md text-white overflow-hidden transition-all duration-[600ms] ${visible ? 'hover:opacity-40' : ''}`}
                 ref={toastRef}>
                <div className={`p-3 text-sm rounded-t-md flex items-center justify-between ${config[type]['header']}`}>
                    <span className='flex-grow'>{title || config[type]['title']}</span>
                    <AiFillCloseCircle className='text-lg clickable-restrained' onClick={onClose} />
                </div>
                <div className={`${config[type]['body']} ${styles.message}`}>
                    {message}
                </div>
            </div>
        </CSSTransition>
    )
};
