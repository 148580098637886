import {useInjection} from "../hooks/useInjection";
import React, {useEffect} from "react";
import {UserService} from "../services/UserService";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import {useNavigate} from "react-router-dom";
import {SpinnerBackdrop} from "../atoms/spinner-backdrop/SpinnerBackdrop";
import {useAuthenticatedUser} from "../hooks/useAuthenticatedUser";

export const LogoutPage = () => {
    const service = useInjection(UserService);
    const navigate = useNavigate();
    const routes = useLocalizedRoutes();
    const {mutate} = useAuthenticatedUser();

    useEffect(() => {
        service
            .logout()
            .then(() => mutate())
            .finally(() => navigate(routes.home()));
    }, [service]);

    return (<SpinnerBackdrop/>)
}
