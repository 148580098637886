import {Lifecycle, scoped} from "tsyringe";
import {GalleryRepository} from "../repositories/GalleryRepository";
import {Cafe, Event, Gallery} from "@jklubcafe/backend-api-dto";
import {dataProvider} from "../hooks/useData";
import {FindDocumentsResponse, Sort} from "../repositories";

const DEFAULT_SORT: Sort<Gallery> = {
    field: 'createdAt',
    order: 'desc',
};

@scoped(Lifecycle.ContainerScoped)
export class GalleryService {
    constructor(private readonly galleryRepository: GalleryRepository) {
    }

    @dataProvider('galleryByEvent')
    public async getGalleryByEvent(event: string | Event): Promise<Gallery | null> {
        const eventSlug = typeof event === 'string' ? event : event.slug;
        return this.galleryRepository
            .findOne({
                where: {
                    'event.slug': {
                        equals: eventSlug,
                    }
                }
            })
    }

    @dataProvider('galleriesByCafe')
    public getGalleriesByCafe(cafe: string | Cafe, page: number, limit: number): Promise<FindDocumentsResponse<Gallery>> {
        const cafeSlug = typeof cafe === 'string' ? cafe : cafe.slug;
        return this.galleryRepository
            .findAll({
                sort: DEFAULT_SORT,
                limit: limit,
                page,
                depth: 1,
                where: {
                    'cafe.slug': {
                        equals: cafeSlug,
                    }
                }
            })
    }

    @dataProvider('gallery')
    public async getGallery(slug: string): Promise<Gallery> {
        return this.galleryRepository.findBySlug(slug);
    }
}
