import {AbstractRepository} from './AbstractRepository';
import {PayloadClient} from './PayloadClient';
import {Lifecycle, scoped} from 'tsyringe';
import {Event} from '@jklubcafe/backend-api-dto';

@scoped(Lifecycle.ContainerScoped)
export class EventRepository extends AbstractRepository<Event> {
  constructor(client: PayloadClient) {
    super(client, 'events');
  }
}
