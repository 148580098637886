import {Event, Media} from '@jklubcafe/backend-api-dto';
import React, {useMemo} from 'react';
import {BtnBookmark} from "../../atoms/btn-bookmark/BtnBookmark";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useCoupon} from "../../hooks/useCoupon";
import {Card} from "../card/Card";

interface EventCardProps {
    event: Event;
}

export const EventCard = ({event}: EventCardProps) => {
    const {coupon, loading, toggleCoupon} = useCoupon(event);
    const routes = useLocalizedRoutes();
    const isPast = useMemo(() => new Date(event.datetime) < new Date(), [event]);

    return (
        <div className={`relative ${isPast ? 'opacity-50' : ''}`}>
            <BtnBookmark
                className='clickable-restrained'
                type={coupon ? 'remove' : 'add'}
                loading={loading}
                onClick={toggleCoupon}
            />

            <Card
                url={routes.event(event).details()}
                datetime={event.datetime}
                image={event.image as Media | undefined}
                title={event.title}
                description={event.intro}
            />
        </div>
    )
}
