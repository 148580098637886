import {Theme, ThemeColors, useTheme} from '../../hooks/useTheme';
import React, {ButtonHTMLAttributes} from 'react';

export const Button = ({ children, className, theme:themeOverride, outline, ...props }: ButtonHTMLAttributes<HTMLButtonElement> & {
    theme?: Theme;
    outline?: boolean;
}) => {
  const {theme, colors} = themeOverride ? {theme: themeOverride, colors: ThemeColors[themeOverride]} : useTheme();
  const finalTheme = themeOverride || theme;
  return (
    <button
        style={outline ? {
            color: colors.dark,
            outlineColor: colors.dark,
            backgroundColor: 'white',
        } : {
            backgroundColor: colors.dark,
            color: 'white',
    }}
      className={`\
        p-2 px-4 rounded-md shadow-gray-300\
        shadow-[0_1px_4px_1px]\
        text-center\
        whitespace-nowrap\
        ring-offset-0\
        ring-jk-${finalTheme}-dark\
        focus:shadow-none\
        focus:scale-[98%]\
        hover:brightness-110\
        outline-offset-0\
        ${outline ? 'outline-2 outline ring-offset-2' : 'outline-0'}\
        focus:ring-4\
        ring-opacity-40\
        cursor-pointer\
        transition-all\
        ${className || ''}\
      `}
      {...props}
    >
      {children}
    </button>
  );
}
