import React, {useCallback} from "react";
import {getStaticData, useData} from "../hooks/useData";
import {StaticDataArgs} from "../tools/context";
import {GalleryService} from "../services/GalleryService";
import {useParams} from "react-router-dom";
import {Gallery} from "../organisms/gallery/Gallery";
import {HeaderSimple} from "../organisms/header-simple/HeaderSimple";
import {useTreeNavigate} from "../hooks/useTreeNavigate";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";

export const GalleryPage = () => {
    const {gallerySlug, eventSlug} = useParams();
    const {data: gallery} = useData(GalleryService, GalleryService.prototype.getGallery, gallerySlug);
    const {goLocationUp} = useTreeNavigate();
    const [toolbarVisible, setToolbarVisible] = React.useState(true);

    const onGalleryToolbarToggle = useCallback((visibility: boolean) => {
        setToolbarVisible(visibility);
    }, [setToolbarVisible]);

    return (gallery ?
        <>
            <MetaTitle subtitle={gallery.title}/>

            <div className='jk-backdrop-full !z-[100]'>
                <HeaderSimple className={`fixed transition-opacity ${toolbarVisible ? 'opacity-70' : 'opacity-0'}`}/>
                <Gallery gallery={gallery} onClose={() => goLocationUp(eventSlug ? 2 : 1)}
                         onToolbarToggle={onGalleryToolbarToggle}/>
            </div>
        </> : null
    );
}

GalleryPage.staticData = async ({params, context}: StaticDataArgs) => {
    return {
        fallback: {
            ...await getStaticData(context, GalleryService, GalleryService.prototype.getGallery, params.gallerySlug)
        }
    }
}
