import React, {useRef} from "react";
import {useSwipeable} from "react-swipeable";

export interface BackdropProps {
    children?: React.ReactNode,
    onInteract?: () => void,
    closeable?: boolean,
}

export const Backdrop = ({onInteract, children, closeable}: BackdropProps) => {
    const backdropRef = useRef(null);
    const closeSwipeHandler = useSwipeable({
        onSwiping: onInteract,
        preventScrollOnSwipe: true,
        trackMouse: true,
    })

    return (
        <div
            className="jk-backdrop"
            onClick={onInteract}
            ref={backdropRef}
        >
            <div
                className='w-full h-full flex justify-center items-center' {...(closeable === false ? {} : closeSwipeHandler)}>
                {children}
            </div>
        </div>
    )
}
