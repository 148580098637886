import {Lifecycle, scoped} from 'tsyringe';
import {PayloadClient} from './PayloadClient';
import {User} from '@jklubcafe/backend-api-dto';
import {stringify} from "qs";
import {AbstractRepository} from "./AbstractRepository";

@scoped(Lifecycle.ContainerScoped)
export class UserRepository extends AbstractRepository<User> {
    constructor(client: PayloadClient) {
        super(client, 'users');
    }

    public async me(): Promise<AuthenticationResponse<User>> {
        const response = await this.client.get<AuthenticationResponse<User>>(`/${this.collection}/me`);
        return response.data;
    }

    public async login(email: string, password: string): Promise<AuthenticationResponse<User>> {
        const response = await this.client.post<AuthenticationResponse<User>>(`/${this.collection}/login`, {
            email,
            password,
        });
        return response.data;
    }

    public async logout(): Promise<void> {
        await this.client.post(`/${this.collection}/logout`, {});
    }

    forgotPassword(email: string, passwordResetUrl: string) {
        return this.client.post(`/${this.collection}/forgot-password?password-reset-url=${passwordResetUrl}`, {
            email: email,
        });
    }

    async resetPassword(token: string, newPassword: string) {
        const response = await this.client.post<AuthenticationResponse<User>>(`/${this.collection}/reset-password`, {
            token: token,
            password: newPassword,
        });
        return response.data;
    }

    async loginFederated(provider: string, state: string, code: string, codeVerifier: string): Promise<AuthenticationResponse<User>> {
        const result = await this.client.get<AuthenticationResponse<User>>(`/${this.collection}/login/federated/${provider}/oauth/token${stringify({
            state,
            code,
            code_verifier: codeVerifier,
        }, {addQueryPrefix: true})}`);
        return result.data
    }
}

export interface AuthenticationResponse<T> {
    user: T,
    token: string,
    exp: number,
}
