import React, {useCallback} from "react";

import SimpleCalendar from '../organisms/calendar/SimpleCalendar';
import {Trans, useTranslation} from 'react-i18next';
import {EventService} from '../services/EventService';
import {getStaticData, useData} from "../hooks/useData";
import {HeaderSimple} from "../organisms/header-simple/HeaderSimple";
import {BtnBack} from "../atoms/btn-back/BtnBack";
import {Outlet, useNavigate} from "react-router-dom";
import {Cafe, Event} from "@jklubcafe/backend-api-dto";
import {getCafeTheme} from "../hooks/useTheme";
import {CafeSlug} from "../hooks/useCafeSlug";
import {Popup} from "../molecules/popup/Popup";
import {SimpleCalendarEvent} from "../organisms/calendar/SimpleCalendarProps";
import {Button} from "../atoms/button/Button";
import {useLocalizedRoutes} from "../hooks/useLocalizedRoutes";
import {StaticData, StaticDataArgs} from "../tools/context";
import {useTreeNavigate} from "../hooks/useTreeNavigate";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";
import {ClientComponent} from "../atoms/client-component/ClientComponent";
import {IsoTime} from "../atoms/iso-time/IsoTime";
import {oneLineTrim} from "common-tags";

const EventList = ({events, onEventClicked}: {
    events: Event[],
    onEventClicked: (e: Event) => void
}) => {
    return (
        <div>
            <p className='mb-4'>
                <Trans
                    i18nKey='multipleEventsOnSameDay'
                    defaults="There are multiple events on {{eventDateTime, datetime}}. Please select the event you are interested in."
                    values={{
                        eventDateTime: new Date(events[0].datetime)
                    }}
                />
            </p>
            <ul className='gap-2.5 grid grid-cols-1 md:grid-cols-2'>
                {events.map(e => {
                    const cafe = e.cafe as Cafe
                    const {theme} = getCafeTheme(cafe.slug as CafeSlug);
                    return (
                        <Button
                            onClick={() => onEventClicked(e)}
                            key={e.id}
                            theme={theme}
                            className='w-full'
                        >
                            {(e.cafe as Cafe).name} - {e.title}
                        </Button>
                    );
                })}
            </ul>
        </div>
    )
}

const EventPopup = ({events, onClose, open}: { events: Event[], onClose: () => void, open: boolean }) => {
    const {t} = useTranslation();
    const routes = useLocalizedRoutes();
    const navigate = useNavigate();

    const onEventClicked = useCallback((event: Event) => {
        navigate(routes.cafe(event.cafe).calendar().event(event))
    }, [navigate]);

    return (
        <Popup open={open} title={t('Events at the selected day')} onClose={onClose}>
            <EventList events={events} onEventClicked={onEventClicked}/>
        </Popup>
    )
}

export const CalendarPage = () => {
    const {i18n, t} = useTranslation();
    const {data: events} = useData(EventService, EventService.prototype.getPublishedEvents);
    const [month, setMonth] = React.useState<Date | undefined>(undefined);
    const {goLocationUp} = useTreeNavigate();
    const [eventList, setEventList] = React.useState<Event[] | undefined>();
    const routes = useLocalizedRoutes();
    const navigate = useNavigate();

    const onDayClicked = useCallback((calendarEvents: SimpleCalendarEvent[]) => {
        const events = calendarEvents.map(e => e.event as Event);

        setEventList(events.length > 1 ? events : undefined);

        if (events.length === 1) {
            navigate(routes.cafe(events[0].cafe).calendar().event(events[0]));
        }
    }, [setEventList, navigate, routes]);

    const onEventPopupClose = useCallback(() => {
        setEventList([]);
    }, [setEventList]);

    return (
        <>
            <MetaTitle subtitle={t('Event Calendar')}/>
            <div className='calendar-page w-full h-screen flex flex-col'>
                <HeaderSimple className='text-xl'>
                <span className='self-center font-heading whitespace-nowrap flex-shrink text-ellipsis'>
                    {month?.toLocaleDateString(i18n.language, {month: 'long', year: 'numeric'})}
                </span>
                <span className='self-center'>
                    <BtnBack onClick={() => goLocationUp()}/>
                </span>
                </HeaderSimple>
                <main className='flex-grow'>
                    {events ?
                        <ClientComponent serverComponent={
                            <ul>
                                {events.map((e) => (
                                    <li key={e.id}>
                                        <IsoTime datetime={e.datetime}/>
                                        <h4>{e.title}</h4>
                                        <p>{oneLineTrim(e.intro || '')}</p>
                                    </li>
                                ))}
                            </ul>
                        }>
                            <SimpleCalendar
                                events={events?.map(event => ({
                                    id: event.id,
                                    title: event.title,
                                    date: new Date(event.datetime),
                                    type: getCafeTheme((event.cafe as Cafe).slug as CafeSlug).theme,
                                    url: routes.cafe(event.cafe).calendar().event(event),
                                    event,
                                }))}
                                onDayClicked={onDayClicked}
                                days={t('CalendarDays', {returnObjects: true}) as string[]}
                                onMonthSelected={setMonth}
                            />
                        </ClientComponent> : null}
                </main>
                {eventList?.length &&
                    <EventPopup events={eventList} onClose={onEventPopupClose} open={eventList.length > 0}/>}
                <Outlet/>
            </div>
        </>
    )
};

CalendarPage.staticData = async ({context}: StaticDataArgs): Promise<StaticData> => {
    return {
        fallback: {
            ...await getStaticData(context, EventService, EventService.prototype.getPublishedEvents),
        }
    }
}
