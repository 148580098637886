import React from "react";
import {RouteObject} from "react-router/dist/lib/context";
import Main from "./Main";
import {MainTemplate} from "./templates/MainTemplate";
import {IndexPage} from "./pages/IndexPage";
import {ArticlePage} from "./pages/ArticlePage";
import {EventsPage} from "./pages/EventsPage";
import {SimpleTemplate} from "./templates/SimpleTemplate";
import SignUpPage from "./pages/SignUpPage";
import LoginPage from "./pages/LoginPage";
import {CalendarPage} from "./pages/CalendarPage";
import {useLocalizedRoutes} from "./hooks/useLocalizedRoutes";
import {useCafeSlug} from "./hooks/useCafeSlug";
import {Navigate} from "react-router-dom";
import {SettingsPage} from "./pages/SettingsPage";
import {LogoutPage} from "./pages/LogoutPage";
import {PageNotFound, UnknownError} from "./pages/IssuePage";
import {PasswordResetPage} from "./pages/PasswordResetPage";
import {CouponValidationPage} from "./pages/CouponValidationPage";
import {EventDetailsPage} from "./pages/EventDetailsPage";
import {GalleryPage} from "./pages/GalleryPage";
import {NativeRouter} from "./atoms/native-router/NativeRouter";
import {FederatedAuthHandler} from "./atoms/federated-auth-handler/FederatedAuthHandler";
import {isNative} from "./tools/isNative";
import {GalleriesPage} from "./pages/GalleriesPage";

const RedirectToDefaultPage = () => {
    const routes = useLocalizedRoutes();
    const cafeSlug = useCafeSlug();

    return (cafeSlug ? <Navigate to={routes.cafe(cafeSlug).main()}/> : null);
}
const createMultiRoute = (
    {
        paths,
        ...props
    }: RouteObject & { paths: string[] },
) => paths.map((route) => ({
    path: route,
    ...props
}))
export const routes: RouteObject[] = [
    {
        path: '',
        element: <NativeRouter/>,
        errorElement: <UnknownError/>,
        loader: isNative ? NativeRouter.dataLoader : undefined,
        children: [
            {
                path: '/:locale?',
                element: <Main/>,
                children: [
                    {
                        path: '*',
                        element: <PageNotFound/>
                    },
                    {
                        path: '',
                        element: <MainTemplate/>,
                        loader: MainTemplate.staticData,
                        children: [
                            {
                                path: 'login/federated/:provider/redirect',
                                element: <FederatedAuthHandler/>,
                            },
                            {
                                path: '',
                                element: <IndexPage/>,
                                loader: IndexPage.staticData,
                            },
                            ...createMultiRoute({
                                paths: ['settings', 'beallitasok'],
                                element: <SettingsPage/>,
                            }),
                            ...createMultiRoute({
                                paths: ['articles/:articleSlug', 'cikkek/:articleSlug'],
                                element: <ArticlePage/>,
                                loader: ArticlePage.staticData,
                            }),
                            ...createMultiRoute({
                                paths: ['cafes/:cafeSlug', 'kavezok/:cafeSlug'],
                                children: [
                                    {
                                        path: '',
                                        element: <RedirectToDefaultPage/>,
                                    },
                                    ...createMultiRoute({
                                        paths: ['articles/:articleSlug', 'cikkek/:articleSlug'],
                                        element: <ArticlePage/>,
                                        loader: ArticlePage.staticData,
                                    }),
                                    ...createMultiRoute({
                                        paths: ['gallery', 'galeria'],
                                        element: <GalleriesPage/>,
                                        loader: GalleriesPage.staticData,
                                        children: [{
                                            path: ':gallerySlug',
                                            element: <GalleryPage/>,
                                            loader: GalleryPage.staticData,
                                        }]
                                    }),
                                    ...createMultiRoute({
                                        paths: ['events', 'esemenyek'],
                                        element: <EventsPage/>,
                                        loader: EventsPage.staticData,
                                        children: [{
                                            path: ':eventSlug',
                                            element: <EventDetailsPage/>,
                                            loader: EventDetailsPage.staticData,
                                            children: createMultiRoute({
                                                paths: ['gallery/:gallerySlug', 'galeria/:gallerySlug'],
                                                element: <GalleryPage/>,
                                                loader: GalleryPage.staticData,
                                            })
                                        }]
                                    }),
                                    ...createMultiRoute({
                                        paths: ['events/:eventSlug?/coupon-validation', 'esemenyek/:eventSlug?/kupon-ervenyesites'],
                                        element: <CouponValidationPage/>,
                                    })
                                ]
                            }),
                        ]
                    },
                    ...createMultiRoute({
                        paths: ['authentication', 'authentikacio'],
                        element: <SimpleTemplate/>,
                        loader: SimpleTemplate.staticData,
                        children: [
                            ...createMultiRoute({
                                paths: ['forgot-password/:token?', 'jelszo-emlekezteto/:token?'],
                                element: <PasswordResetPage/>,
                            }),
                            ...createMultiRoute({
                                paths: ['login', 'bejelentkezes'],
                                element: <LoginPage/>,
                            }),
                            ...createMultiRoute({
                                paths: ['sign-up', 'regisztracio'],
                                element: <SignUpPage/>,
                            }),
                            ...createMultiRoute({
                                paths: ['logout', 'kijelentkezes'],
                                element: <LogoutPage/>,
                            }),
                        ]
                    }),
                    ...createMultiRoute({
                        paths: [
                            'cafes/:cafeSlug/calendar',
                            'kavezok/:cafeSlug/esemeny-naptar',
                        ],
                        element: <CalendarPage/>,
                        loader: CalendarPage.staticData,
                        children: [{
                            path: ':eventSlug',
                            element: <EventDetailsPage/>,
                            loader: EventDetailsPage.staticData,
                            children: createMultiRoute({
                                paths: ['gallery/:gallerySlug', 'galeria/:gallerySlug'],
                                element: <GalleryPage/>,
                                loader: GalleryPage.staticData,
                            })
                        }]
                    }),
                ],
            }
        ]
    },
];
