import './main.css';
import React from 'react';
import {Outlet} from 'react-router-dom';
import {getCafeTheme, ThemeColors, ThemeContext} from './hooks/useTheme';
import {useCafeSlug} from './hooks/useCafeSlug';
import {SWRConfig} from "swr";
import {Helmet} from "react-helmet";

import {CookieConsentBar} from "./molecules/cookie-consent-bar/CookieConsentBar";
import {useLocalizedRoutes} from "./hooks/useLocalizedRoutes";
import {absoluteUrl} from "./tools/url";
import {GlobalNotificationProvider,} from "./hooks/useGlobalNotification";
import {MetaTitle} from "./atoms/meta-title/MetaTitle";
import {MetaLogo} from "./atoms/meta-logo/MetaLogo";
import {GoogleAnalytics} from "./atoms/google-analytics/GoogleAnalytics";
import config from "./config";
import {useUrlLanguage} from "./hooks/useUrlLanguage";
import {isNative} from "./tools/isNative";
import {MetaDescription} from "./atoms/meta-description/MetaDescription";
import {useTranslation} from "react-i18next";
import {useSafeArea} from "./hooks/useSafeArea";
import {DownloadAppPopup} from "./organisms/download-app-popup/DownloadAppPopup";

function Main() {
    useSafeArea();
    useUrlLanguage();

    const cafeSlug = useCafeSlug();
    const {theme} = getCafeTheme(cafeSlug);
    const routes = useLocalizedRoutes();
    const {t} = useTranslation();

    return (
        <>
            {!isNative && <GoogleAnalytics measurementId={config.googleAnalyticsMeasurementId} />}
            <MetaTitle />
            <MetaLogo />
            <MetaDescription />
            <Helmet>
                <meta name="keywords" content={t('meta keywords') || ''} />
                <meta charSet='utf-8'/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />

                <link rel="alternate" hrefLang="en" href={absoluteUrl(routes.home('en'))}/>
                <link rel="alternate" hrefLang="hu" href={absoluteUrl(routes.home('hu'))}/>
                <link rel="alternate" hrefLang="x-default" href={absoluteUrl(routes.home('hu'))}/>

                <link rel="icon" href="/favicon.svg"/>
                <link rel="apple-touch-icon" href="/favicon.svg"/>
                <link rel="manifest" href="/manifest.json"/>
            </Helmet>
            <ThemeContext.Provider value={{
                colors: ThemeColors[theme],
                theme: theme
            }}>
                <SWRConfig
                    value={{
                        refreshInterval: 10000,
                        loadingTimeout: 30000,
                    }}
                >
                    <GlobalNotificationProvider>
                        <div className={`jk-container jk-theme-${theme} ${isNative ? 'select-none native' : ''}`}>
                            <Outlet/>
                            {!isNative && <CookieConsentBar/>}
                            {!isNative && <DownloadAppPopup/>}
                        </div>
                    </GlobalNotificationProvider>
                </SWRConfig>
            </ThemeContext.Provider>
        </>
    );
}

export default React.memo(Main);
