import React from "react";
import {NavBarButton} from "../../molecules/nav-bar-button/NavBarButton";
import {CafeSlug} from "../../hooks/useCafeSlug";
import {useData} from "../../hooks/useData";
import {CafeService} from "../../services/CafeService";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {LogoJateKlubSmall} from "../../atoms/logo-jate-klub-small/LogoJateKlubSmall";
import {LogoCafeTikSmalll} from "../../atoms/logo-cafe-tik-small/LogoCafeTikSmall";
import {LogoCafeRadnotiSmall} from "../../atoms/logo-cafe-radnoti-small/LogoCafeRadnotiSmall";
import {getCafeTheme} from "../../hooks/useTheme";

const icons = {
    [CafeSlug.Radnoti]: <LogoCafeRadnotiSmall className='h-10'/>,
    [CafeSlug.Jate]: <LogoJateKlubSmall className='h-10'/>,
    [CafeSlug.Tik]: <LogoCafeTikSmalll className='h-10'/>,
};

export const FooterNative = () => {
    const {data: cafes, isLoading: isCafeLoading} = useData(CafeService, CafeService.prototype.getCafes);
    const routes = useLocalizedRoutes();

    return (
        <div className='fixed bottom-0 w-full z-[9] bg-jk-gray flex flex-row justify-evenly h-[calc(var(--safe-area-bottom)+var(--native-footer-size))]'>
            {!cafes && isCafeLoading ? null : cafes?.map(cafe => {
                const {colors} = getCafeTheme(cafe.slug as CafeSlug);
                return (
                    <NavBarButton
                        style={{
                            color: colors.primary,
                            fill: colors.primary,
                            borderColor: colors.primary,
                        }}
                        className={`flex-grow w-1/3`}
                        key={cafe.id}
                        label={cafe.name}
                        url={routes.cafe(cafe).index()}
                        ico={icons[cafe.slug as CafeSlug]}
                    />
                )
            })}
        </div>
    )
}
