import {Lifecycle, scoped} from "tsyringe";
import {AbstractRepository} from "./AbstractRepository";
import {Partner} from "@jklubcafe/backend-api-dto";
import {PayloadClient} from "./PayloadClient";

@scoped(Lifecycle.ContainerScoped)
export class PartnerRepository extends AbstractRepository<Partner> {
    constructor(client: PayloadClient) {
        super(client, 'partners');
    }
}
