import {CafeService} from '../services/CafeService';
import {useCafeSlug} from './useCafeSlug';
import {useData} from "./useData";
import {useMemo} from "react";

export const useCafe = () => {
    const cafeSlug = useCafeSlug();
    const {data: cafes, isLoading} = useData(CafeService, CafeService.prototype.getCafes);
    return useMemo(() => cafes?.find((cafe) => cafe.slug === cafeSlug), [
        cafes, cafeSlug, isLoading
    ]);
}
