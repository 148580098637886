import {Outlet, useLoaderData, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {isNative} from "../../tools/isNative";
import {NativeConfigService} from "../../services/NativeConfigService";
import {container} from "tsyringe";
import {IssuePage} from "../../pages/IssuePage";
import {MdSecurityUpdateWarning} from "react-icons/md";
import {AppUpdate} from "@capawesome/capacitor-app-update";
import {Button} from "../button/Button";
import {Trans, useTranslation} from "react-i18next";
import {NativeConfig} from "@jklubcafe/backend-api-dto";
import {Capacitor} from "@capacitor/core";
import {useAppVersion} from "../../hooks/useAppVersion";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useInjection} from "../../hooks/useInjection";
import {NotificationService, OnNotificationActionEvent} from "../../services/NotificationService";
import {logger} from "../../tools/logger";

const useDeepLinkRouting = () => {
    const navigate = useNavigate();

    const appUrlOpenCallback = useCallback((event: URLOpenListenerEvent) => {
        const slug = event.url.slice(event.url.indexOf('.com') + 4);
        if (slug) {
            navigate(slug);
        }
    }, [navigate]);

    useEffect(() => {
        if (isNative) {
            App.addListener('appUrlOpen', appUrlOpenCallback);
        }
    }, [appUrlOpenCallback])
}

const usePushNotificationRouting = () => {
    const notificationService = useInjection(NotificationService);
    const navigate = useNavigate();
    const routes = useLocalizedRoutes();
    const {i18n} = useTranslation();

    const handler = useCallback((e: OnNotificationActionEvent) => {
        const event = e.data.event;

        if (event) {
            i18n
                .changeLanguage(e.data.locale)
                .then(() => navigate(routes.event(event).details()));
        }
    }, [navigate]);

    useEffect(() => {
        notificationService
            .subscribe({
                onNotificationAction: handler
            })
            .catch((e) => logger.error(e))
    }, [handler])
}

const useNativeVersionCheck = () => {
    const nativeConfig = useLoaderData() as NativeConfig;
    const [upgradeRequired, setUpgradeRequired] = useState<boolean | undefined>(undefined);
    const [upgradeAvailable, setUpgradeAvailable] = useState<boolean | undefined>(undefined);
    const {build: currentVersionNumber} = useAppVersion();

    useEffect(() => {
        if (isNative && nativeConfig && currentVersionNumber) {
            const platform = Capacitor.getPlatform() as 'ios' | 'android';
            const targetVersion = nativeConfig.versions[platform].targetVersion;
            const minimumSupportedVersion = nativeConfig.versions[platform].minimumSupportedVersion;
            setUpgradeAvailable(currentVersionNumber < targetVersion);
            setUpgradeRequired(currentVersionNumber < minimumSupportedVersion);
        }
    }, [nativeConfig, currentVersionNumber])

    return {upgradeRequired, upgradeAvailable};
}

const UpdateRequiredPage = () => {
    const {t} = useTranslation();

    return (<IssuePage
        title="Update Required"
        description="There is a new version of the application available in the App Store. Please update the application to continue."
        icon={<MdSecurityUpdateWarning className='text-8xl'/>}
        button={
            <Button onClick={() => AppUpdate.openAppStore()}>{t('Go to App Store')}</Button>
        }
    />)
}

const UpdateAvailableNotification = () => {
    const onUpdateAvailableClick = useCallback(() => {
        AppUpdate.openAppStore();
    }, []);

    return (
        <div className='bg-yellow-200 p-2 text-xs w-full sticky top-0 z-10' onClick={onUpdateAvailableClick}>
            <Trans
                i18nKey="<0>New version available.</0> Tap here to update the application in the App Store."
                components={[<b key='new-version-bold'></b>]}
            />
        </div>
    )
}

export const NativeRouter = () => {
    useDeepLinkRouting();
    usePushNotificationRouting();
    const {upgradeRequired, upgradeAvailable} = useNativeVersionCheck();

    return (upgradeRequired === true ? <UpdateRequiredPage/> :
        <>
            {upgradeAvailable === true && <UpdateAvailableNotification/>}
            <Outlet/>
        </>)
}

NativeRouter.dataLoader = async () => {
    return container.resolve(NativeConfigService).getNativeConfig();
}
