import React from 'react';

export interface LogoCafeRadnotiProps {
    className?: string;
}

export const LogoCafeRadnotiSmall = ({className}: LogoCafeRadnotiProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.7 53.3">
            <path d="M25.1,29l-0.4,2.2c-0.1,0.1-0.3,0.2-0.4,0.1L24.1,31H24v0l-0.1-0.1l-0.1-0.4h-0.1l-0.1-0.2l-0.3-0.1v0.1
	c-0.4,0.3-0.8,0.7-1.1,1.1v0.2h-0.1c-0.1,0.4,0.1,0.5-0.4,0.6v-0.1l-0.6,0V32h-0.2l0,0h-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.9h-0.1v-0.2
	l-0.1-0.2v-0.1l-0.3-0.2v-0.5h0.1V29H20l0.1-0.5h0v-0.1l0,0v-0.2l0.1-0.1l0.1-0.3l0.1-0.1v-0.1l0.2-0.1v-0.1l0.1-0.1l0.2-0.3H21
	l0.1-0.1h0.1v-0.1h0.1v-0.1h0.3v-0.1c0.4-0.1,0.9,0,1.3,0.1v0.1H23v0.1h0.2l0,0h0.1V27c0.3,0.1,0.5,0.2,0.8,0.3v0.5
	c-0.1,0.2-0.1,0.4,0,0.6c0.2,0.1,0.5,0.2,0.6,0.3v-0.1h0.4l0.1-0.5h0.1v-0.3h0.1v-0.1h0.3v0.1H26l0.1-0.2l0,0l0.1-1h0.1v-0.3h0.1
	v-0.4h0.1v-0.3h0.1v-0.2h0.1V25h0.1v-0.2h0.1v-0.2H27v-0.3l0,0V24h0.1v-0.2h0.1v-0.2h-0.1v-0.2l0,0v-0.5h0.1c0.1-0.5,0-1.1-0.1-1.6
	v-0.7L27,19.3l-0.9-3.7c-0.4,0-0.7-0.2-0.9-0.5c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-1.1,0.8h-0.5v0.1h-0.1v-0.1l-1-0.1
	c-0.2-0.1-0.3-0.2-0.5-0.2V16h-0.1v-0.8h-0.1v-0.1h-0.3c0-0.2,0-0.4-0.1-0.5c-0.1,0.1-0.3,0.1-0.4,0.1v-0.9c-0.1,0-0.2-0.1-0.3-0.1
	v0.1c0,0-0.6,0-0.6,0.1c0,0.3,0,0.7-0.1,1V15h-0.5v-0.1L18.2,15l-0.1-0.2H18v-0.3l-0.9-0.3l-0.4-0.5l-0.6-0.5V13l-0.5-0.4l-0.1-0.1
	h-0.1l-0.1-0.2l-0.4-0.1v-0.1l-1.4-0.3l-1.6-0.1h-0.5c-1,0.3-1.9,1-2.5,1.9L8.5,14v0.1l-0.1,0.1v0.1l-0.3,0.1v0.2H8v0.1H7.9
	l-0.1,0.5H7.7l-0.1,0.5H7.5l-0.1,0.5H7.3v0.1l-0.2,0.1v0.1l0,0.2v0.5l0,0v0.4h0.1l0.1,1.6H7.2l-0.1,0.2l0,0v0.1L7,19.6v0.1l-0.1,0.1
	V20l-0.1,0.1l-0.1,0.5l-0.1,0.1v0.1H6.5l-0.1,0.4H6.1v0.1l-0.2,0.1v0.2L5.5,22v0.1l-0.2,0.1v0.1l-0.1,0.1v0.2H5.1
	c0,0.2,0,0.5-0.1,0.7v0.1H4.5l-0.6-1l-0.1-0.6H3.7l-0.1-0.5l-0.3-0.2L3.2,21H3.1l0,0c-0.4-0.1-0.9,0.5-1.2,0.3H1.8
	c-0.2-1.2-1.1-1.6-1.5-2.5L0.2,18C0,17.4-0.1,16.7,0,16.1h0.1v-0.4h0v-0.3h0.1v-0.3h0.1v-0.4h0.1v-0.5h0.1c-0.1-0.4-0.1-0.9-0.1-1.4
	h0.1l0.1-0.4L1,12.1L1.3,11H1.1l0.1-0.3l0.1-0.1v-0.1l0.1-0.1v-0.1h0.1v-0.2c0.1-0.5,0.2-0.9,0.4-1.4l0.3-0.2V8.4l0.4-0.3V8L3,7.7
	V7.5l0.1-0.1V7.2l0.4-0.3V6.7l0.1-0.1l0.1-0.5h0.1V6l0.1-0.1V5.8L4,5.7V5.6l0.2-0.1V5.3L4.6,5l0.1-0.1h0.1l0.1-0.2h0.2l0.1-0.2h0.1
	l0.1-0.1h0.1L5.7,4L6,3.9l0.1-0.2l0.3-0.1l0.1-0.2h0.1l0.2-0.3h0.3l0.1-0.2l0.5-0.4L8,2h0.1l0,0l0.4-0.1V1.7h0.3l0,0h0.1V1.6h0.2
	V1.5h0.1l0,0h0.2V1.4h0.1V1.3h0.2V1.2h0.1l0,0H10V1.1h0.2V1h0.2l0,0l1.2-0.1V0.8L12,0.7V0.6h0.1V0.5h0.1V0.4h0.1V0.3h0.2V0.2h0.1
	l0.5-0.1h0.1V0c0.6,0,1.1,0.2,1.6,0.4l2.6-0.1v0.1h0.2v0.1l0.5,0.2v0.1l1,0.1V1h0.2v0.1h0.1v0.1h0.1l0.1,0.1l0.5,0.1v0.2l0.4,0.1
	l0.1,0.1h0.2v0.1h0.1V2h0.2v0.1l0.3,0.1v0.1h0.2v0.1l0.3,0.1v0.1h0.2l0.1,0.1l0.3,0.1l0.1,0.1h0.1L22.8,3h0.3v0.2h0.1l0.1,0.2h0.1
	l0.1,0.1h0.1l0.1,0.2h0.1L24.2,4h0.1l0.1,0.2h0.1l0.2,0.3h0.1l0.1,0.1l0.3,0.2V5l0.2,0.1v0.1l0.2,0.1v0.2l0.2,0.1V6l0.3,0.1v0.1
	l0.4,0.3v0.1L26.9,7v0.1l0.6,0.6V8l0.6,0.4v0.1l0.2,0.1L28.4,9h0.1l0.1,0.3h0.1v0.2h0.1v0.2H29V10l0,0v0.3h0.1v0.3h0.1V11l0.7,2.6
	v7.1h-0.1V21h-0.1v0.3h-0.1v0.4h-0.1v0.1h0.1v0.3h0.1v0.1h0.1l0.2,0.7l-0.1,1.6c0.1,0,0.1,0.1,0.1,0.1h0.4v0.1l0.2,0.1l0.1,0.6
	c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0-0.1,0.1-0.1,0.1h-0.2v0.1h-0.2v0.1h-0.2l-0.1,0.1h-0.1L29.2,27h-0.1L29,27.2h-0.1l-0.1,0.2h-0.1
	l-0.1,0.1h-0.1l-0.1,0.1l-1.3,0.5v0.1L27,28.3v0.1l-0.1,0.1v0.1l-0.3,0.2v0.1L26.4,29v0.1h-0.3l-0.3,0.3h-0.2c0-0.1-0.1-0.1-0.1-0.1
	C25.3,29.3,25.5,29.1,25.1,29"/>
            <path d="M2.2,23.9c0.1,0.6,0.4,1.6,0.1,2.2l-0.6,0.5l-0.1,0.3H1.5l-0.1,1.4c-0.1,0-0.2,0.1-0.4,0.1l-0.1-0.2H0.8
	l-0.1-0.4H0.6v-0.2H0.5L0.6,27H0.5v-0.3H0.4c-0.1-0.4,0.1-0.8-0.4-0.8c-0.1-0.4-0.2-0.8-0.3-1.1v-0.5h-0.1v-0.5h-0.1l-0.1-1.1h-0.1
	v-1.3l0.4-1.1h0.1c0.1-0.1,0.2-0.2,0.2-0.4c0.2-0.1,0.3-0.2,0.5-0.2l0.4,0.6L1,21.2c0.1,0.2,0.1,0.5,0.1,0.8l0-0.1V22H1l-0.2,0.7
	H0.7v0.1H0.6V23c-0.2,0.4-0.3,0.8-0.3,1.3h0.1v0.1c0.2-0.1,0.3-0.2,0.5-0.3h0.2V24h0.2C1.6,24,1.9,23.9,2.2,23.9"/>
            <path d="M13.2,21.8c0.2,0,0.4,0.1,0.6,0.2l0.6,0.1v0.1l0.4,0.1v0.1h0.3v0.1h0.1v0.1h0.1l0,0h0.1l0.1,0.1l1.5,0.7
	l0.7,0.8l0.2,0.1v0.1H18l0.3,0.4l0.1,0.1c0.4,0.7,0.4,1.6,0.2,2.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3-0.1-0.7-0.2-1.1-0.1
	c-0.1,0.1-0.2,0.2-0.3,0.2l-0.1,0.2h-0.1l-0.1,0.1h-0.1L16,28.3h-0.1l0.2,0.3H16l-0.1,0.1l-0.2,0.1v0.1L15.3,29l0,0l-0.2,0.1v0.1
	h-0.3v0.1h-0.4v0.1c-0.4,0-0.7,0-1.1-0.1v-0.1h-0.2l0,0L12.8,29l-0.1-0.1h-0.1l-0.2-0.3l-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3v-1.6
	c-0.2-0.1-0.5-0.2-0.7-0.1v0.1l-0.9-0.1l-0.1-0.2c-0.2-0.6-0.2-1.3-0.1-1.9l0.3-0.2V24l0.3-0.1l0.1-0.1h0.2v-0.1h0.1v-0.1h0.2
	c0.5-0.2,1-0.5,1.4-0.8h0.1v-0.6h0.1C13.2,22.1,13.2,21.9,13.2,21.8"/>
            <path d="M2.4,31.1c0.3,0.1,0.3,0.1,0.3,0.5c0.1,0.3,0.2,0.7,0.1,1c-0.3,0.3-0.7,0.5-1.1,0.6v-0.1L1.3,33l-0.2-0.2l0,0
	v-0.1c-0.1-0.3-0.2-0.7-0.2-1H1v-0.1c0.5,0.1,1.1-0.1,1.5-0.4"/>
            <path d="M15.5,37.8c0.5,0,0.9,0,1.4,0.2v0.1H17v0.1l0.8,0.1c0,0.5,0,1,0.1,1.5c0.2-0.1,0.3-0.3,0.4-0.5
	c0-0.3,0-0.5,0.1-0.8v-0.1h0.2v0.1l0.4,0.1l0.1,0.1c0.2,0.1,0.4,0.2,0.4,0.5c0.2,0.5,0.2,1,0.1,1.5l-0.2,0.1V41
	c-0.4,0.2-0.8,0-1.1,0.5h-0.1l0,1.5h-0.4l-0.2-0.3h-0.1v-0.8h-0.1v-0.1c-0.3-0.2-0.2,0.2-0.4-0.2c0-0.5,0-1,0.1-1.6
	c-0.2-0.1-0.3-0.2-0.5-0.3h-0.1l-0.1-0.1h-0.1v-0.1h-0.2v-0.1H16v-0.1h-0.1l-0.4-0.5C15.5,38.5,15.5,38.2,15.5,37.8"/>
            <path d="M17.2,44.1c0.5,0.1,1.1,0.1,1.6,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0.4,0,0.7-0.1,1.1l-0.6,0.2
	c-0.5,0.1-1.3-0.1-1.8,0.1c0,0,0,0.1,0.1,0.1c0.3,0.3,0.9,0.2,1.1,0.5c0.1,0.2,0.1,0.4,0.1,0.7c-0.1,0.2-0.1,0.4-0.1,0.6h-0.1
	L17.5,48l-0.1,0.1v0.1L17,48.5l-0.1,0.1h-0.6c-0.3-0.1-0.7-0.2-1-0.1c-0.9-0.3-2.2-0.1-3-0.5c0-0.2-0.1-0.4-0.1-0.6v-0.6
	c0-0.3,0-0.7,0.1-1h0.1v-0.1l0.1-0.1v-0.1l0.1-0.1v-0.1h0.2l0.1-0.1h0.2l0.1-0.1h0.1V45l0.6-0.1l0,0l0.4,0.1v-0.1
	c0.1-0.1,0,0,0.1-0.1l-0.2-0.1v-0.1h-0.1L14,44.5h-0.1L13.8,44h-0.1l-0.1-0.1l-0.3-0.1v-0.1h-0.2l0,0h-0.2v-0.1l-0.5-0.1v-0.1H12
	c-0.4-0.1-0.9-0.1-1.4-0.1c-0.2,0.1-0.4,0.1-0.6,0.1c-0.1-0.4-0.1-0.8-0.1-1.3L10,42v0l0.2-0.1v-0.1h0.1l0.1-0.1
	c0.3-0.2,0.7-0.3,1.1-0.3v0.1h0.2l0.1,0.1h0.3l0.2,0.3h0.1l0.1,0.2c0.2,0.1,0.4,0.1,0.6,0.1v0.1l1,0.1v0.1h0.6v0.1h0.6
	C16.2,42.7,16.9,43.3,17.2,44.1"/>
            <path d="M7.2,48.2l0.6,0.2l0.1,0.3H8l0.1,0.2h0.1l0.3,0.4h0.1L9,49.8h0.1l0.2,0.3h0.1l0.1,0.1l0.6,0.2
	c0.3,0,0.5-0.1,0.8-0.1v-0.1h0.2v-0.1h0.2V50h0.3l0,0H12v-0.1h0.3v-0.1c0.4,0,0.9,0,1.3,0.2l0.1,0.1h0.1v0.1l0.6,0.1l0.6,0.2
	c0.1,0.4,0.3,0.8,0.5,1.1l0.8,0.8v0.7l-0.2,0.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.8,0-1.6-0.1-2.4-0.3h-0.4v-0.1h-0.3v-0.1h-0.4v-0.1
	h-0.3v-0.1h-0.3v-0.1h-0.2v-0.1h-0.2v-0.1l-0.5-0.1v-0.1h-0.1V52H9.9v-0.1L9.6,52v-0.1l-0.3-0.1l-0.1-0.1H9.1l-0.2-0.3l-0.8-0.7
	v-0.1l-0.3-0.2v-0.1l-0.6-0.5v-0.2l-0.1-0.2v-0.7H7v-0.3C7,48.3,7.1,48.3,7.2,48.2"/>
        </svg>
    )
}
