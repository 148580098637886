import config from "../config";
import {useCallback} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useInjection} from "./useInjection";
import {UserService} from "../services/UserService";
import {useLocalizedRoutes} from "./useLocalizedRoutes";
import {useGlobalNotification} from "./useGlobalNotification";
import {useTranslation} from "react-i18next";
import {useAuthenticatedUser} from "./useAuthenticatedUser";
import getPkce from 'oauth-pkce';
import qs from "qs";
import {isNative} from "../tools/isNative";
import {Browser} from "@capacitor/browser";

const getPkceCodes = () => new Promise<{
    verifier: string;
    challenge: string;
}>((resolve, reject) => {
    getPkce(43, (error, result) => {
        if (error) {
            reject(error);
        } else {
            resolve(result);
        }
    });
});

const buff_to_base64 = (buff: Uint8Array) => btoa(
    new Uint8Array(buff).reduce((data, byte) => data + String.fromCharCode(byte), '')
);

const randomKey = () => buff_to_base64(crypto.getRandomValues(new Uint8Array(32)))

const createAuthorizationEndpoint = async (provider: string) => {
    const state = randomKey();
    const {verifier, challenge} = await getPkceCodes();

    localStorage.setItem('state', state);
    localStorage.setItem('code_verifier', verifier);

    const queryString = qs.stringify({
        state,
        code_challenge: challenge,
    }, {
        addQueryPrefix: true,
    })

    return `${config.backendApi}/users/login/federated/${provider}/authorize${queryString}`;
}

export const useFederatedLogin = () => {
    const {provider} = useParams();
    const [searchParams] = useSearchParams();
    const userService = useInjection(UserService);
    const navigate = useNavigate();
    const routes = useLocalizedRoutes();
    const {showNotification} = useGlobalNotification();
    const {t} = useTranslation();
    const {mutate} = useAuthenticatedUser();

    const doAuthorize = useCallback((provider: string) => {
        createAuthorizationEndpoint(provider)
            .then((url) => {
                if (isNative) {
                    return Browser.open({url})
                } else {
                    window.location.replace(url)
                }
            });
    }, [])

    const doExchangeToken = useCallback(() => {
        const params = [
            provider,
            searchParams.get('state'),
            searchParams.get('code'),
            localStorage.getItem('code_verifier')
        ];

        if (params.some((param) => !param)) {
            throw new Error('Missing parameters for federated login.');
        }

        const originalState = localStorage.getItem('state');
        const currentState = searchParams.get('state');

        if (currentState !== originalState) {
            throw new Error(`Invalid state '${currentState}'`);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return userService.loginFederated(...params)
            .then((response) => mutate(response.user))
            .then((user) => {
                showNotification({
                    type: 'success',
                    title: t('Login Successful'),
                    message: t('You have logged in as {{username}}', {username: user?.email})
                });
            })
            .catch(({response}) => {
                showNotification({
                    type: 'error',
                    title: t('Login Failed'),
                    message: (response?.data?.errors || [])[0]?.message || t('Login failed due to unknown error')
                });
            })
            .finally(() => {
                if (isNative) {
                    Browser.close();
                }
                navigate(routes.home());
            })
    }, []);

    return {
        doAuthorize: doAuthorize,
        doExchangeToken: doExchangeToken,
    };
}
