import React from "react";
import config from "../../config";
import {Helmet} from "react-helmet";

export const MetaLogo = () => {
    const data = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": config.publicBaseUrl,
        "logo": config.publicBaseUrl + "/favicon.svg"
    }

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(data)}
            </script>
        </Helmet>
    )
}
