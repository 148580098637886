import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

interface IsoTimeProps {
    datetime: string | Date | number;
}

/**
 * This component is used to render a date and time in ISO format. Mainly used by server side rendered components.
 *
 * @param datetime - the date and time to render
 * @constructor
 */
export const IsoTime = ({datetime}: IsoTimeProps) => {
    const {i18n} = useTranslation();
    const date = useMemo(() => new Date(datetime), [datetime]);

    return (
        <time dateTime={date.toISOString()}>
            {date.toLocaleString(i18n.language, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
            })} UTC
        </time>
    )
}
