import {ImBookmark} from "react-icons/im";
import React, {HTMLAttributes} from "react";
import {useTheme} from "../../hooks/useTheme";
import {CgSpinner} from "react-icons/cg";

export interface BtnBookmarkProps extends HTMLAttributes<HTMLSpanElement> {
    type: 'add' | 'remove';
    loading?: boolean;
}

export const BtnBookmark = ({className, type, loading, ...props}: BtnBookmarkProps) => {
    const { colors } = useTheme();

    return (
        <span className={`absolute left-1 top-0 z-[1] opacity-50 hover:opacity-70 ${className || ''}`} {...props}>
            <ImBookmark className={`absolute left-0 top-0 text-6xl drop-shadow-md`} style={{
                color: type === 'remove' ? colors.dark : 'grey',
            }}/>
            <span
                className='absolute block left-0 top-0 w-[60px] h-[45px] text-center text-jk-white font-bold text-3xl'>
                {!loading && (type === 'remove' ? '-' : '+')}
                {loading && (<CgSpinner className='animate-spin'/>)}
            </span>
        </span>
    )
}
