import React, {useCallback, useRef} from 'react';
import './Popup.css';
import {useSwipeable} from 'react-swipeable';
import {CSSTransition} from 'react-transition-group';
import {useTheme} from '../../hooks/useTheme';
import {CgCloseO} from 'react-icons/cg';
import {Backdrop} from "../../atoms/backdrop/Backdrop";
import styles from './Popup.module.css';

export interface PopupProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
}

export const Popup = ({ open, title, children, onClose }: PopupProps) => {
  const [currentlyOpened, setCurrentlyOpened] = React.useState(false);
  const popupWindowRef = useRef(null)
  const { colors } = useTheme();

  React.useEffect(() => {
    setCurrentlyOpened(open);
  }, [open]);

  const downSwipeHandler = useSwipeable({
    onSwipedDown: () => {
      setCurrentlyOpened(false)
    },
    trackMouse: true,
    preventScrollOnSwipe: true,
  })

  const closeCallback = useCallback(() => {
    setCurrentlyOpened(false);
  }, []);

  return (
    <>
      {currentlyOpened && <Backdrop onInteract={closeCallback}/>}

      <CSSTransition
        in={currentlyOpened}
        timeout={300}
        classNames="popup-window"
        // unmountOnExit
        nodeRef={popupWindowRef}
        onExited={onClose}
      >
        <div
          {...downSwipeHandler}
          ref={popupWindowRef}
          className="
            transition-all duration-150 ease-in-out
            fixed top-full left-0 rounded-t-2xl shadow w-full h-[90%] bg-jk-gray-dark z-50 border border-jk-gray-light
            flex flex-col
            md:w-4/5 md:left-[10%]
          "
        >
          <div className="text-white border-b border-jk-gray-light p-4 flex row items-center">
            <h3
              style={{
                color: colors.primary,
              }}
              className="flex flex-grow flex-shrink m-0 whitespace-nowrap text-ellipsis"
            >
              {title}
            </h3>
            <CgCloseO
              style={{ color: colors.primary }}
              onClick={closeCallback}
              className="text-3xl clickable flex-grow-0 flex-shrink-0"
            />
          </div>
          <div className={`bg-jk-white p-4 pb-[calc(1rem+var(--safe-area-bottom))] flex-grow ${styles.scrollable}`}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
