import {Lifecycle, scoped} from "tsyringe";
import {FooterNavigationRepository} from "../repositories/FooterNavigationRepository";
import {Article} from "@jklubcafe/backend-api-dto";
import {dataProvider} from "../hooks/useData";

@scoped(Lifecycle.ContainerScoped)
export class FooterNavigationService {
    constructor(private readonly footerNavigationRepository: FooterNavigationRepository) {
    }

    @dataProvider('footerNavigation')
    public async getFooterNavigation() {
        const result = await this.footerNavigationRepository.find();
        return result
            .items
            .filter((item => ((item.article as Article).id)))
            .map((item) => item.article as Article)
    }
}
