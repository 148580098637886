import React, {useCallback, useMemo, useState} from 'react';
import {LanguageToggle} from '../../molecules/language-toggle/LanguageToggle';
import {BtnMenu} from '../../atoms/btn-menu/BtnMenu';
import {Slogan} from '../../atoms/slogan/Slogan';
import {useSwipeable} from 'react-swipeable';
import {useTheme} from '../../hooks/useTheme';
import {useCafe} from '../../hooks/useCafe';
import {Link, useLocation} from 'react-router-dom';
import {FaUserCircle} from 'react-icons/fa';
import {useCafeSlug} from '../../hooks/useCafeSlug';
import {Separator} from '../../atoms/separator/Separator';
import {CafeService} from '../../services/CafeService';
import {useTranslation} from 'react-i18next';
import {useData} from "../../hooks/useData";
import {Backdrop} from "../../atoms/backdrop/Backdrop";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useAuthenticatedUser} from "../../hooks/useAuthenticatedUser";
import {IoMdSettings} from "react-icons/io";
import {AppVersion} from "../../atoms/app-version/AppVersion";
import {BsFacebook, BsInstagram} from "react-icons/bs";
import {isNative} from "../../tools/isNative";
import {ClickableLogo} from "../../molecules/clickable-logo/ClickableLogo";

const AuthMenu = () => {
    const {colors} = useTheme();
    const {isAuthenticated} = useAuthenticatedUser();
    const routes = useLocalizedRoutes();
    const [displayProfile, setDisplayProfile] = useState(false);

    const profileOnClick = useCallback((e: React.UIEvent<HTMLAnchorElement>) => {
        if (isAuthenticated) {
            e.preventDefault();
            setDisplayProfile(!displayProfile);
        }
    }, [setDisplayProfile, displayProfile])

    return (
        <>
            {isAuthenticated === false && (
                <Link to={routes.login()} onClick={profileOnClick} className='text-2xl md:text-3xl'
                      style={{color: colors.primary}}>
                    <FaUserCircle className="clickable"/>
                </Link>
            )}
            <Link to={routes.settings()} className='text-3xl md:text-4xl flex text-center'
                  style={{color: colors.primary}}>
                <IoMdSettings className="clickable"/>
            </Link>
        </>
    )
}

const HeaderMenu = ({onClose, onOpen, closed}: {
    onClose?: () => void;
    onOpen?: () => void;
    closed?: boolean;
}) => {
    return (
        <div className="flex flex-row justify-between items-center gap-4 md:gap-6">
            <LanguageToggle/>
            <AuthMenu/>
            <BtnMenu onClose={onClose} onOpen={onOpen} closed={closed} className="md-web:!hidden"/>
        </div>
    )
}

const Button = ({children, url, onClick, active, newWindow, className}: {
    url: string;
    children: React.ReactNode;
    newWindow?: boolean;
    onClick?: () => void;
    active?: boolean;
    className?: string;
}) => {
    const {colors} = useTheme();
    const location = useLocation();
    active = useMemo(() => active === undefined ? location.pathname.startsWith(url) : active, [active, location, url]);

    return (
        <Link
            to={url}
            onClick={onClick}
            style={{
                borderColor: colors.primary,
                color: colors.primary,
            }}
            target={newWindow ? '_blank' : undefined}
            rel={newWindow ? 'noreferrer' : undefined}
            className={`\
        py-3 px-3 lg-web:px-5 hover:bg-jk-gray-light ${active ? 'bg-jk-gray-light md-web:border-b-4' : ''}\
        rounded-full mx-8 mb-2 text-center md-web:mx-0 md-web:mb-0 md-web:rounded-none\
        ${className || ''}\
      `}
        >
      <span className="relative z-20 whitespace-nowrap">
        {children}
      </span>
        </Link>
    )
}

const CafeButtons = ({onClick}: {
    onClick: () => void;
}) => {
    const routes = useLocalizedRoutes();
    const cafeSlug = useCafeSlug();
    const {data: cafes, isLoading} = useData(CafeService, CafeService.prototype.getCafes)

    return (!cafes && isLoading ? null :
            <>
                {cafes?.map(cafe => (
                        <Button
                            key={cafe.id}
                            url={routes.cafe(cafe).main()}
                            onClick={onClick}
                            active={cafeSlug === cafe.slug}
                        >
                            {cafe.name}
                        </Button>
                    ),
                )}
            </>
    )
}

const SocialMediaLinks = () => (
    <div className='flex flex-row items-center justify-center'>
        <Button url="https://www.facebook.com/szegedjateklub" newWindow={true} className={'h-full'}>
            <BsFacebook/>
        </Button>
        <Button url="https://www.instagram.com/jateklub" newWindow={true} className={'h-full'}>
            <BsInstagram/>
        </Button>
    </div>
)

const MainMenu = ({closed, onClose}: {
    closed?: boolean
    onClose: () => void;
}) => {
    const routes = useLocalizedRoutes();
    const cafe = useCafe();
    const {t} = useTranslation();

    const leftSwipeHandler = useSwipeable({
        onSwipedLeft: onClose,
        trackMouse: true,
    })

    return (
        <>
            <aside
                className={`fixed top-0 left-0 z-40 w-2/3 min-h-screen transition-transform ${closed ? '-translate-x-full' : 'transform-none'}\
        md-web:transform-none md-web:static md-web:w-auto md-web:h-auto md-web:min-h-fit\
      `}
            >
                <div className="flex flex-row z-30">
                    <div
                        className={`\
            h-screen w-full flex flex-col bg-jk-gray\
            ${closed ? '' : 'shadow-lg shadow-black'}\
            md-web:flex-grow md-web:w-auto md-web:h-auto md-web:flex-row md-web:shadow-none md-web:justify-between\
            scroll-auto\
            overflow-y-auto overflow-x-hidden\
            lg-web:px-28\
          `}
                        {...leftSwipeHandler}
                    >
                        <Slogan className="text-3xl md-web:hidden text-center mt-14 mb-10"/>
                        {cafe && cafe.pages?.map((page) => (
                            <Button
                                onClick={onClose}
                                key={page.id}
                                url={routes.cafe(cafe).page(page)}
                            >
                                {page.title}
                            </Button>
                        ))}
                        <SocialMediaLinks/>
                        <div className="md-web:hidden mt-8 flex flex-col justify-between mb-8">
                            {!isNative && <Separator title={t('Places')} className='mb-8'/>}
                            {!isNative && <CafeButtons onClick={onClose}/>}
                            <AppVersion className='w-full text-center text-xs text-jk-gray-light'/>
                        </div>
                    </div>
                </div>
            </aside>
            {!closed && <Backdrop onInteract={onClose}/>}
        </>
    )
}

export const Header = () => {
    const [closed, setClosed] = React.useState(true);

    const close = useCallback(() => {
        setClosed(true);
    }, []);

    const open = useCallback(() => {
        setClosed(false);
    }, []);

    return (
        <header className="fixed md-web:static w-full z-10 bg-jk-gray-dark">
            <div
                className="bg-jk-gray-dark pt-[calc(max(1rem,var(--safe-area-top)))] p-4 md-web:py-5 lg-web:px-28 flex flex-row justify-between border-b border-jk-gray-light">
                <ClickableLogo />
                <Slogan className="relative top-12 text-5xl hidden md-web:block"/>
                <HeaderMenu onOpen={open} onClose={close} closed={closed}/>
            </div>
            <MainMenu closed={closed} onClose={close}/>
        </header>
    )
};
