import {Lifecycle, scoped} from "tsyringe";
import {NativeConfig} from "@jklubcafe/backend-api-dto";
import {PayloadClient} from "./PayloadClient";
import {AbstractGlobalRepository} from "./AbstractGlobalRepository";

@scoped(Lifecycle.ContainerScoped)
export class NativeConfigRepository extends AbstractGlobalRepository<NativeConfig> {
    constructor(client: PayloadClient) {
        super(client, 'native-config');
    }
}
