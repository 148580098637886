import React, {HTMLAttributes} from 'react';

export const IconCircle = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={`aspect-square border-2 rounded-full bg-gradient-to-b from-jk-gray drop-shadow-lg to-black ${className || ''} clickable`} {...props}>
        <div className="h-full w-full flex items-center justify-center">
            <div className="w-[80%] text-center m-auto">
                {children}
            </div>
        </div>
    </div>
  )
}
