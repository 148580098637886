import React, {createRef, ImgHTMLAttributes, useCallback, useMemo, useState} from "react";
import {GalleryImage} from "@jklubcafe/backend-api-dto";
import {useIntersectionObserver} from "../../hooks/useIntersectionObserver";

export interface OptimizedImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    image: {
        url?: string,
        width?: number,
        sizes?: GalleryImage['sizes']
    },
    loadHighres: boolean,
}

export const OptimizedImage = React.forwardRef<HTMLImageElement, OptimizedImageProps>(({
                                                                                           image,
                                                                                           loadHighres,
                                                                                           ...props
                                                                                       }: OptimizedImageProps, ref) => {
    const [loaded, setLoaded] = useState(false);

    const lowresUrl = useMemo(() => image.sizes?.lowres?.url || image.url, [image]);

    const srcSet = useMemo(() => (
        [
            ...Object.entries(image.sizes || {})
                .filter(([name]) => name !== 'lowres' && name !== 'thumbnail')
                .filter(([, size]) => size.url && size.width)
                .map(([, size]) => `${size.url} ${size.width}w`),
            `${image.url} ${image.width}w`
        ].join(', ')
    ), [image]);

    const onHighresLoaded = useCallback(() => {
        setLoaded(true);
    }, []);

    return (
        <>
            {!loaded && <img ref={ref}
                             aria-label='preview'
                             src={lowresUrl}
                             {...props}/>}
            {(loadHighres || loaded) && (
                <img src={lowresUrl}
                     srcSet={srcSet}
                     onLoad={onHighresLoaded}
                     style={{display: loaded ? 'block' : 'none'}}
                     sizes='100vw'
                     {...props}
                />
            )}
        </>
    )
});

OptimizedImage.displayName = 'OptimizedImage';

export const LazyOptimizedImage = ({...props}: Omit<OptimizedImageProps, 'loadHighres'>) => {
    const [loading, setLoading] = useState(false);
    const ref = createRef<HTMLImageElement>();

    useIntersectionObserver({
        target: ref,
        onIntersect: () => {setLoading(true)},
        options: {
            rootMargin: '-100px'
        }
    });

    return (<OptimizedImage ref={ref} {...props} loadHighres={loading}/>)
}
