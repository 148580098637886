import React, {useCallback} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useInjection} from "../../hooks/useInjection";
import {UserService} from "../../services/UserService";
import {useGlobalNotification} from "../../hooks/useGlobalNotification";
import {useTranslation} from "react-i18next";
import {Input, PasswordIcon} from "../../atoms/input/Input";
import {MessageBox} from "../../atoms/message-box/MessageBox";
import {Button} from "../../atoms/button/Button";
import {SpinnerBackdrop} from "../../atoms/spinner-backdrop/SpinnerBackdrop";
import {useAuthenticatedUser} from "../../hooks/useAuthenticatedUser";
import {useReCaptcha} from "../../hooks/useReCaptcha";
import {GoogleReCaptchaBranding} from "../../atoms/google-recaptcha-branding/GoogleReCaptchaBranding";

interface PasswordResetFormProps {
    token: string
}

export const PasswordResetForm = ({token}: PasswordResetFormProps) => {
    const {t} = useTranslation();
    const userService = useInjection(UserService);
    const {showNotification} = useGlobalNotification();
    const { mutate } = useAuthenticatedUser();
    const {executeReCaptcha} = useReCaptcha('passwordReset');

    const resetPassword = useCallback((password: string) => {
        return userService
            .resetPassword(token, password)
            .then((response) => mutate(response.user))
            .then((user) => {
                showNotification({
                    type: 'success',
                    title: t('Password reset successful'),
                    message: t('Your password has been reset successfully. You have been automatically logged in as {{username}}.', {username: user?.email})
                });
            })
            .catch(({response}) => {
                const errorMessage = (response?.data?.errors || [])[0]?.message || t('Failed to reset password. Please try again.');
                showNotification({
                    type: 'error',
                    title: t('Password reset failed'),
                    message: errorMessage
                });
            });
    }, [userService]);

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordAgain: '',
        },
        validationSchema: Yup.object({
            password: Yup
                .string()
                .required(t('Please enter your password') || '')
                .min(8, t('Password must be at least 8 characters long') || '')
                .matches(/[a-z]/, t('Password must contain at least one lowercase letter') || '')
                .matches(/[A-Z]/, t('Password must contain at least one uppercase letter') || ''),
            passwordAgain: Yup
                .string()
                .test('passwordAgain', t('Passwords must match') || '', (value, context) => {
                    return context.parent.password === value
                }),
        }),
        onSubmit: ({password}, {setSubmitting}) => {
            executeReCaptcha()
                .then(() =>resetPassword(password))
                .finally(() => setSubmitting(false));
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} className='grid grid-cols-1 gap-3'>
                <p>{t('As a final step of the password reset process, please enter your new password.')}</p>

                <Input
                    id='password' name='password' type='password' autoComplete="new-password"
                    placeholder={t('Password') || ''} icon={<PasswordIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}
                    required/>

                <MessageBox visible={!!(formik.touched.password && formik.errors.password)} type='error'
                            message={formik.errors.password}/>

                <Input
                    id='passwordAgain' name='passwordAgain' type='password' autoComplete="new-password"
                    placeholder={t('Password Confirmation') || ''} icon={<PasswordIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.passwordAgain}
                    required/>

                <MessageBox visible={!!(formik.touched.passwordAgain && formik.errors.passwordAgain)} type='error'
                            message={formik.errors.passwordAgain}/>

                <GoogleReCaptchaBranding/>

                <Button type='submit' className='w-fit' disabled={formik.isSubmitting}>{t('Reset Password')}</Button>
            </form>

            {formik.isSubmitting && <SpinnerBackdrop/>}
        </>
    )
}
