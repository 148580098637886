import React from "react";
import {HeaderSimple} from "../organisms/header-simple/HeaderSimple";
import {isServer} from "../tools/isServer";
import {Issue, IssueDisplayProps} from "../organisms/issue/Issue";
import {GrDocumentMissing} from "react-icons/gr";
import {FaSadCry} from "react-icons/fa";
import {useRouteError} from "react-router-dom";
import {logger} from "../tools/logger";

export const IssuePage = (props: IssueDisplayProps) => {
    const error: any = useRouteError();

    if (isServer && error) {
        logger.error(error);
        if (error.stack) {
            logger.error(error.stack);
        }
    }

    return (
        <div className='flex flex-col justify-center items-center h-screen '>
            <HeaderSimple/>
            <main className='simple-template p-4 md:p-8 lg-web:px-28 flex-grow'>
                <Issue {...props} />
            </main>
        </div>
    );
}

export const PageNotFound = () => (
    <IssuePage
        title='Page Not Found!'
        description='The page you are looking for might have been removed, had its name changed or is temporarily unavailable'
        icon={<GrDocumentMissing className='text-8xl'/>}
    />
)

export const UnknownError = () => {

    // FIXME: find a way to get the error from the router and display 404 if EntityNotFound error.
    //  Unfortunately, the useError hook does not return the error on client side causing hydration
    //  mismatch on client side. So, for now, we just display the generic error page.

    return (
        <IssuePage
            title='Ooops! Something went wrong!'
            description='We are sorry, but something went wrong. Please try again later.'
            icon={<FaSadCry className='text-8xl'/>}
        />
    )
}
