import React from 'react';
import {useParams} from 'react-router-dom';
import {ArticleService} from '../services/ArticleService';
import parse from 'html-react-parser'
import styles from './ArticlePage.module.css';
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import {getStaticData, useData} from "../hooks/useData";
import {StaticData, StaticDataArgs} from "../tools/context";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";

export const ArticlePage = () => {
    const {articleSlug} = useParams();
    const {data: article} = useData(ArticleService, ArticleService.prototype.getArticle, articleSlug)

    return (
        <>
            <MetaTitle subtitle={article?.title}/>
            <div className={`jk-a-underline ${styles.articlePage}`}>
                <div className="pagetitle">
                    <h1>{article?.title}</h1>
                    <Breadcrumbs lastClickable={true} />
                </div>
                <div className="rich-text">
                    {article && parse(article.content, {
                        trim: true,
                    })}
                </div>
            </div>
        </>
    )
}

ArticlePage.staticData = async ({params, context}: StaticDataArgs): Promise<StaticData> => {
    return {
        fallback: {
            ...await getStaticData(context, ArticleService, ArticleService.prototype.getArticle, params.articleSlug)
        }
    }
}
