import {useTranslation} from "react-i18next";
import React from "react";

const En = () => (
    <a href="https://apps.apple.com/hu/app/jate-klub/id6463098424?itsct=apps_box_badge&amp;itscg=30200"
       className={"inline-block overflow-hidden h-[83px] rounded-[13px] py-[0.8rem]"}>
        <img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1696896000"
        className="h-full object-contain"
        alt="Download on the App Store"
        />
    </a>
)

const Hu = () => (
    <a href="https://apps.apple.com/hu/app/jate-klub/id6463098424?itsct=apps_box_badge&amp;itscg=30200"
       className={"inline-block overflow-hidden h-[83px] rounded-[13px] py-[0.8rem]"}>
        <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/hu-hu?size=250x83&amp;releaseDate=1696896000"
            alt="Letöltés az App Store-ból"
            className="h-full object-contain"
        />
    </a>
)

export const BtnGetOnAppStore = () => {
    const { i18n } = useTranslation();
    return (
        i18n.language === 'hu' ? <Hu /> : <En />
    )
}
