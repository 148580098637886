import {PayloadClient} from "./PayloadClient";
import {Pageable} from "./index";
import {stringify} from "qs";
import {Config} from "@jklubcafe/backend-api-dto";

export class AbstractGlobalRepository<T> {
    constructor(private readonly client: PayloadClient, private readonly collection: keyof Config['globals']) {
    }

    public find(pageable?: Pick<Pageable<never>, 'depth'>) {
        const queryString = stringify(pageable, {addQueryPrefix: true});

        return this.client
            .get<T>(`/globals/${this.collection}${queryString}`)
            .then(response => response.data);
    }
}
