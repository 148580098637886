import React from "react";
import styles from './Toggle.module.css';

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
}

export const Toggle = ({label, className, ...props}: ToggleProps) => {
    return (
        <label className={`relative inline-flex items-center cursor-pointer ${className || ''} ${props.disabled ? styles.toggleDisabled : ''}`}>
            <input type="checkbox" className="sr-only peer" {...props}/>
            <div
                className="w-11 flex-shrink-0 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-organge-300 dark:peer-focus:ring-orange-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-600" ></div>
            <span className="ml-3 text-sm whitespace-nowrap overflow-hidden">{label}</span>
        </label>
    )
}
