import {Cafe} from '@jklubcafe/backend-api-dto';
import {Lifecycle, scoped} from 'tsyringe';
import {PayloadClient} from './PayloadClient';
import {AbstractRepository} from './AbstractRepository';

@scoped(Lifecycle.ContainerScoped)
export class CafeRepository extends AbstractRepository<Cafe> {
  constructor(client: PayloadClient) {
    super(client, 'cafes');
  }
}
