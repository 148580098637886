import QRCode, {QRCodeProps} from "react-qr-code";
import React from "react";

export type QrCodeProps = QRCodeProps;

export const QrCode = ({ref, ...props}: QrCodeProps) => (<QRCode
    {...props}
    viewBox={`0 0 256 256`}
    size={256}
    style={{ height: "auto" }}
/>)
