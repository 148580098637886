import {Gallery as GalleryDto, GalleryImage} from "@jklubcafe/backend-api-dto";
import React, {createRef, useCallback, useEffect, useMemo, useState} from "react";
import {Splide as ReactSplide, SplideSlide as ReactSplideSlide, SplideTrack} from '@splidejs/react-splide';
import {CgClose} from "react-icons/cg";
import {ArrayElement} from "../../tools/ArrayElement";
import {useTheme} from "../../hooks/useTheme";
import {SlideComponent} from "@splidejs/splide";
import {OptimizedImage} from "../../atoms/optimized-image/OptimizedImage";
import { BiMoviePlay } from "react-icons/bi";

export interface GalleryProps {
    gallery: GalleryDto,
    onClose?: () => void,
    onToolbarToggle?: (visible: boolean) => void,
}

export const Gallery = ({gallery, onClose, onToolbarToggle}: GalleryProps) => {
    const thumnailSplide = createRef<ReactSplide>();
    const mainSplide = createRef<ReactSplide>();
    const [toolbarVisible, setToolbarVisible] = useState(true);
    const [selected, setSelected] = useState<ArrayElement<Exclude<GalleryDto['images'], undefined>>>();
    const isVideoSelected = useMemo(() => selected?.type === 'youtube' || !!(selected?.image as GalleryImage | undefined)?.mimeType?.startsWith('video/'), [selected]);
    const {colors} = useTheme();

    useEffect(() => {
        if (thumnailSplide.current?.splide && mainSplide.current) {
            mainSplide.current.sync(thumnailSplide.current.splide);
        }
    }, [thumnailSplide.current, mainSplide.current]);

    const toggleToolbar = useCallback(() => {
        setToolbarVisible(v => {
            const newState = !v;
            onToolbarToggle?.(newState);
            return newState
        });
    }, [setToolbarVisible]);

    const onSlideSelect = useCallback((_: unknown, slide: SlideComponent) => {
        const idx = slide.index;
        if (gallery.images && gallery.images.length > idx) {
            const sel = gallery.images[idx];
            setSelected(sel);

            const isVideo = sel.type === 'youtube'
                || !!(sel.image as GalleryImage | undefined)?.mimeType?.startsWith('video/');

            if (isVideo) {
                setToolbarVisible(false);
                onToolbarToggle?.(false);
            }
        }
    }, [gallery.images, setSelected, setToolbarVisible, onToolbarToggle]);

    return (
        <>
            <ReactSplide
                ref={mainSplide}
                title={gallery.title}
                hasTrack={false}
                onClick={toggleToolbar}
                onActive={onSlideSelect}
                options={{
                    pagination: false,
                    arrows: true,
                    keyboard: true,
                    keyboardNavigation: true,
                }}>

                <button onClick={onClose} className='relative splide__arrow !top-[2em] !right-[1em] !z-20 mt-safe'>
                    <CgClose/>
                </button>

                <SplideTrack className='flex-grow'>
                    {gallery.images?.map(({id, image, caption, type, youtube}) => {
                        const img = image as GalleryImage | undefined;
                        return (
                            <ReactSplideSlide key={`gallery-image-${id}`}>
                                {img?.mimeType?.startsWith('image/') && <OptimizedImage loadHighres={img.id === (selected?.image as GalleryImage)?.id} image={img} alt={caption} className='w-screen h-screen object-contain'/>}
                                {img?.mimeType?.startsWith('video/') && <video autoPlay controls className='w-screen h-screen object-contain' src={id === selected?.id ? img.url : ''}/>}
                                {type === 'youtube' && id === selected?.id && <iframe
                                    className='w-screen h-screen'
                                    src={`https://www.youtube.com/embed/${youtube}?autoplay=1&fs=0&modestbranding=1&rel=0&showinfo=0&controls=1&loop=1`}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; loop"
                                    title={caption}
                                    frameBorder="0"
                                />}
                            </ReactSplideSlide>
                        )
                    })}
                </SplideTrack>
            </ReactSplide>

            <div className={`${isVideoSelected ? '-z-10' : 'z-10'} fixed bottom-0 w-full h-fit`}>
                {selected?.caption && !isVideoSelected && <div>
                    <h3 className={`m-4 text-lg md:text-xl !font-normal ${!toolbarVisible ? 'fixed bottom-0' : ''}`}
                        style={{
                            color: colors.primary,
                        }}>{selected.caption}</h3>
                </div>}

                <div
                    className={`p-2 pb-[calc(0.5rem+var(--safe-area-bottom))] bg-jk-gray-dark border-t border-jk-gray-light transition-opacity ${toolbarVisible && !isVideoSelected ? 'opacity-70' : 'opacity-0'}`}>
                    <ReactSplide
                        ref={thumnailSplide}
                        options={{
                            pagination: false,
                            arrows: false,
                            rewind: true,
                            fixedWidth: 100,
                            fixedHeight: 100,
                            gap: 10,
                            focus: 'center',
                            isNavigation: true,
                            keyboard: true,
                            keyboardNavigation: true,
                            breakpoints: {
                                600: {
                                    fixedWidth: 65,
                                    fixedHeight: 65,
                                },
                            },
                        }}>
                        {
                            gallery.images?.map(({id, image, caption, type}) => {
                                const img = image as GalleryImage | undefined;
                                return (
                                    <ReactSplideSlide key={`gallery-image-thumb-${id}`}>
                                        {img?.mimeType?.startsWith('image/') && <img className='object-cover' src={img.sizes?.thumbnail?.url || img.url}
                                             alt={caption}/>}
                                        {(type === 'youtube' || img?.mimeType?.startsWith('video/')) && <div className='w-[100px] h-[100px] flex justify-center items-center'><BiMoviePlay className='text-3xl' fill='white'/></div>}
                                    </ReactSplideSlide>
                                );
                            })
                        }
                    </ReactSplide>
                </div>
            </div>
        </>
    )
}
