import {useEffect} from "react";
import {SafeArea as SafeAreaPlugin, SafeAreaInsets} from "capacitor-plugin-safe-area";
import {logger} from "../tools/logger";
import {Capacitor} from "@capacitor/core";

export const useSafeArea = () => {
    useEffect(() => {
        // on android, webview is displayed in the safe area automatically so we don't need to do anything
        if (Capacitor.getPlatform() === 'ios') {
            const exportSafeAreaCssVariables = async ({insets}: SafeAreaInsets) => {
                logger.debug('Safe area detected', insets);
                for (const [key, value] of Object.entries(insets)) {
                    document.documentElement.style.setProperty(
                        `--safe-area-${key}`,
                        `${value}px`,
                    );
                }
            }

            SafeAreaPlugin.getSafeAreaInsets().then(exportSafeAreaCssVariables);

            const eventListener = SafeAreaPlugin.addListener('safeAreaChanged', exportSafeAreaCssVariables);

            return () => {
                eventListener.remove();
            }
        }
    }, []);
}
