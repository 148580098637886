import React, {MouseEvent, useCallback} from 'react';
import styles from './SimpleCalendarMonth.module.css';
import {SimpleCalendarEvent, SimpleCalendarProps} from './SimpleCalendarProps';

const today = new Date()

type SimpleCalendarMonthProps = Omit<SimpleCalendarProps, 'events' | 'initialMonth'> & {
    events: Map<string, SimpleCalendarEvent[]>,
    month: Date,
}

const isSameMonth = (a: Date, b: Date) => {
    return a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
}

const isSameDay = (a: Date, b: Date) => {
    return a.getDate() === b.getDate() && isSameMonth(a, b);
}

const Event = ({event, onEventClicked}: {
    event: SimpleCalendarEvent
} & Pick<SimpleCalendarProps, 'onEventClicked'>) => {
    const clickHandler = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault(); // prevent navigation, only required for SEO
        onEventClicked?.(event)
    }, [onEventClicked, event])
    return (
        <a
            className={`sc-event ${event.type} ${styles.event}`}
            href={event.url} // for SEO
            onClick={clickHandler}
        >
            {event.title}
        </a>
    )
}

const Day = ({day, month, events, onDayClicked}: { day: Date } & SimpleCalendarMonthProps) => {
    const dayEvents = events.get(day.toDateString()) || [];
    const clickHandler = useCallback(() => onDayClicked && onDayClicked(dayEvents), [onDayClicked, events])

    return (
        <div
            onClick={clickHandler}
            className={[
                'sc-day',
                styles.day,
                dayEvents.length > 0 ? styles.hasEvent : '',
                isSameDay(day, today) ? styles.today : '',
                isSameMonth(day, month) ? styles.currentMonth : '',
            ].join(' ')}
        >
            <span className={styles.dayNumber}>{day.getDate()}</span>
            {
                dayEvents.map((event) => <Event key={event.id} event={event}/>)
            }
        </div>
    );
}

const Week = ({week, ...props}: { week: Date[] } & SimpleCalendarMonthProps) => (
    <div className={styles.week}>
        {
            week.map((day) => <Day key={day.getTime()} {...props} day={day}/>)
        }
    </div>
)
const HeaderDay = (props: { day: string }) => {
    const {day} = props;

    return (
        <div className={styles.headerDay}>{day}</div>
    );
}

const Header = ({days}: { days?: string[] }) => {
    const labels = days || ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    return (
        <div className={styles.header}>
            {
                labels.map((day, idx) => <HeaderDay key={`${idx}-day`} day={day}/>)
            }
        </div>
    );
}

const weeksOfMonth = (month: Date, nrOfWeeks = 6): Date[][] => {
    const MONDAY = 1;
    const padWeek = (week: Date[], dir: -1 | 1) => {
        while (week.length < 7) {
            const idx = dir === -1 ? 0 : week.length - 1
            const day = new Date(week[idx].valueOf());
            day.setDate(day.getDate() + dir);
            dir === -1 ? week.unshift(day) : week.push(day);
        }
    }

    const weeks: Date[][] = [];

    // generate weeks
    let week: Date[] = [];
    let day = new Date(month.getFullYear(), month.getMonth(), 1);
    while (day.getMonth() === month.getMonth()) {
        week.push(day);
        day = new Date(day.valueOf());
        day.setDate(day.getDate() + 1);
        if (day.getDay() === MONDAY) {
            weeks.push(week);
            week = [];
        }
    }

    // push last week if not empty
    if (week.length > 0) {
        weeks.push(week);
    }

    // pad first and last week
    padWeek(weeks[0], -1);
    padWeek(weeks[weeks.length - 1], 1);

    // fill up weeks
    while (weeks.length < nrOfWeeks) {
        const week = weeks[weeks.length - 1].map((day) => {
            const d = new Date(day.valueOf());
            d.setDate(d.getDate() + 7);
            return d;
        });
        weeks.push(week);
    }

    return weeks;
}

export const SimpleCalendarMonth = ({month, days, ...props}: SimpleCalendarMonthProps) => {
    const weeks = weeksOfMonth(month);

    return (
        <div className={styles.month}>
            <Header days={days}/>
            {
                weeks.map((week) => <Week month={month} {...props} week={week} key={week[0].getTime()}/>)
            }
        </div>
    );
}
