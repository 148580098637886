import {UserService} from "../services/UserService";
import {useData} from "./useData";
import {User} from "@jklubcafe/backend-api-dto";
import {ArrayElement} from "../tools/ArrayElement";

type UserRole = ArrayElement<Exclude<User['roles'], undefined>>

export const useAuthenticatedUser = () => {
    // TODO further enhancement needed for mobile app
    const {data:me, mutate, isLoading} = useData(UserService, UserService.prototype.me);

    return {
        isAuthenticated: isLoading ? undefined : !!me,
        hasRole: (role: UserRole) => me?.roles?.includes(role),
        user: isLoading ? undefined : me,
        mutate,
    }
}
