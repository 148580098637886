import {MetaTitle} from "../atoms/meta-title/MetaTitle";
import {Breadcrumbs} from "../molecules/breadcrumbs/Breadcrumbs";
import React from "react";
import {useTranslation} from "react-i18next";
import {StaticData, StaticDataArgs} from "../tools/context";
import {getStaticData} from "../hooks/useData";
import {GalleryService} from "../services/GalleryService";
import {useCafeSlug} from "../hooks/useCafeSlug";
import {GalleryCard} from "../organisms/gallery-card/GalleryCard";
import {Outlet} from "react-router-dom";
import {Gallery} from "@jklubcafe/backend-api-dto";
import {InfinitePagination} from "../organisms/infinite-pagination/InfinitePagination";

const FIRST_PAGE_NR = 1;
const PAGE_SIZE = 12;

export const GalleriesPage = () => {
    const {t} = useTranslation();
    const cafeSlug = useCafeSlug();

    return (
        <>
            <MetaTitle subtitle={t('Gallery')}/>

            <div className="pagetitle jk-a-underline">
                <h1>{t('Gallery')}</h1>
                <Breadcrumbs/>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                <InfinitePagination<Gallery>
                    size={PAGE_SIZE}
                    fetcherProps={{
                        token: GalleryService,
                        method: GalleryService.prototype.getGalleriesByCafe,
                        parameters: [cafeSlug],
                    }}
                    Item={({data}) => <GalleryCard gallery={data}/>}
                    noItems={t("There is no gallery created yet.")}
                />
            </div>

            <Outlet/>
        </>
    )
}

GalleriesPage.staticData = async ({params, context}: StaticDataArgs): Promise<StaticData> => {
    return {
        fallback: {
            ...await getStaticData(context, GalleryService, GalleryService.prototype.getGalleriesByCafe, params.cafeSlug, FIRST_PAGE_NR, PAGE_SIZE),
        }
    }
}
