import React from "react";
import {useTheme} from "../../hooks/useTheme";
import {ClickableLogo} from "../../molecules/clickable-logo/ClickableLogo";

export interface HeaderSimpleProps {
    className?: string;
    children?: React.ReactNode;
}

export const HeaderSimple = ({className, children}: HeaderSimpleProps) => {
    const {colors} = useTheme()

    return (
        <header className={`bg-jk-gray-dark w-full z-10 text-2xl md:text-3xl lg:text-4xl ${className || ''}`}
                style={{
                    color: colors.primary,
                }}>
            <div
                className="bg-jk-gray-dark p-4 pt-[calc(max(1rem,var(--safe-area-top)))] md:py-5 lg-web:px-28 flex flex-row justify-between border-b border-jk-gray-light"
            >
                <ClickableLogo/>
                {children}
            </div>
        </header>
    )
}
