import {Helmet} from "react-helmet";
import React, {useMemo} from "react";
import {oneLineTrim} from "common-tags";
import {useTranslation} from "react-i18next";

interface MetaDescriptionProps {
    description?: string | null | undefined;
}

export const MetaDescription = ({description}: MetaDescriptionProps) => {
    const {t} = useTranslation();
    const descr = useMemo(() => {
        return description ? oneLineTrim(description).slice(0, 200) : t('meta description');
    }, [description, t]);

    return descr ? (
        <Helmet>
            <meta name="description" content={descr}/>
            <meta property="og:description" content={descr}/>
            <meta property="twitter:description" content={descr}/>
        </Helmet>
    ) : null
}
