import React from 'react';
import {BsFacebook, BsInstagram} from 'react-icons/bs';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {useData} from "../../hooks/useData";
import {FooterNavigationService} from "../../services/FooterNavigationService";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import styles from './Footer.module.css';
import {CafeService} from "../../services/CafeService";
import {isNative} from "../../tools/isNative";
import {FooterNative} from "../footer-native/FooterNative";
import {BtnGetOnGooglePlay} from "../../atoms/btn-get-on-google-play/BtnGetOnGooglePlay";
import {BtnGetOnAppStore} from "../../atoms/btn-get-on-app-store/BtnGetOnAppStore";

const FooterMenu = () => {
    const {t} = useTranslation()
    const {
        data: footerNavigation,
        isLoading: isFooterNavigationLoading
    } = useData(FooterNavigationService, FooterNavigationService.prototype.getFooterNavigation);
    const {data: cafes, isLoading: isCafeLoading} = useData(CafeService, CafeService.prototype.getCafes);
    const routes = useLocalizedRoutes();

    return (!(cafes && footerNavigation) && (isFooterNavigationLoading || isCafeLoading) ? null :
            <section className='flex flex-row justify-center md:justify-between'>
                {cafes?.map((cafe) => (
                    <ul key={cafe.id} className={`${styles.footerMenu} !hidden md:!flex`}>
                        <li className={styles.footerMenuTitle}>
                            <h4>{cafe.name}</h4>
                        </li>
                        {cafe?.pages?.map((page) => (
                            <li key={page.id}>
                                <Link to={routes.cafe(cafe).page(page)}>{page.title}</Link>
                            </li>
                        ))}
                    </ul>
                ))}
                <ul className={styles.footerMenu}>
                    <li className={styles.footerMenuTitle}>
                        <h3>{t('Other Pages')}</h3>
                    </li>
                    {footerNavigation?.map((item) => (
                        <li key={item.id}><Link to={routes.article(item)}>{item.title}</Link></li>
                    ))}
                </ul>
            </section>
    )
};

const FooterGetTheApp = ({className}: {
    className?: string
}) => (
    <section className={`${className || ''} p-3 md:p-6 flex flex-col sm:flex-row justify-center gap-3 items-center`}>
        <BtnGetOnGooglePlay />
        <BtnGetOnAppStore />
    </section>
)
const FooterCopyright = ({className}: {
    className?: string
}) => {
    const {t} = useTranslation();

    return (
        <section className={`${className || ''} p-3 md:p-6 flex flex-col justify-center items-center`}>
            <span className="text-xs">
                {t('Copyright')} © {new Date().getFullYear()}
            </span>
            <div>
                <a
                    className="m-3"
                    href="https://www.facebook.com/szegedjateklub"
                    target="_blank"
                    rel="noreferrer"
                >
                    <BsFacebook/>
                </a>
                <a
                    className="m-3"
                    href="https://www.instagram.com/jateklub"
                    target="_blank"
                    rel="noreferrer"
                >
                    <BsInstagram/>
                </a>
            </div>
        </section>
    )
};

export const Footer = () => {
    return (isNative ? <FooterNative /> :
        <footer
            className="bg-jk-gray-dark border-t border-jk-gray-light lg-web:px-28 flex-grow-0 flex-shrink-0 jk-a-underline pb-safe">
            <FooterMenu/>
            <FooterGetTheApp className="border-t border-jk-gray-light"/>
            <FooterCopyright className="border-t border-jk-gray-light"/>
        </footer>
    )
}
