import {Link} from "react-router-dom";
import React from "react";
import styles from './Partners.module.css';
import {useData} from "../../hooks/useData";
import {PartnerService} from "../../services/PartnerService";

export const Partners = () => {
    const {data: partners} = useData(PartnerService, PartnerService.prototype.getPartners);

    return (<div className={styles.ourPartners}>
        {partners?.map((partner) => (
            partner.link && (<Link key={partner.id} to={partner.link} target='_blank' rel='noreferrer' className='clickable'>
                <img src={partner.sizes?.md?.url} alt={partner.name}/>
            </Link>)
        ))}
    </div>)
}
