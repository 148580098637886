import {useData} from "./useData";
import {EventCouponService} from "../services/EventCouponService";
import {useAuthenticatedUser} from "./useAuthenticatedUser";
import {useGlobalNotification} from "./useGlobalNotification";
import {useInjection} from "./useInjection";
import React, {useCallback, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useLocalizedRoutes} from "./useLocalizedRoutes";
import {Event} from "@jklubcafe/backend-api-dto";

export const useCoupon = (event: Event) => {
    const {t} = useTranslation();
    const {
        data: coupons,
        mutate: mutateEventCoupons
    } = useData(EventCouponService, EventCouponService.prototype.getUserCoupons);
    const {user} = useAuthenticatedUser();
    const {showNotification, showGenericError} = useGlobalNotification();
    const eventCouponService = useInjection(EventCouponService);
    const [loading, setLoading] = useState(false);
    const routes = useLocalizedRoutes();

    const coupon = useMemo(() => coupons?.find((coupon) => coupon.event === event.id), [coupons, event]);

    const toggleCoupon = useCallback(() => {
        if (!user) {
            showNotification({
                title: t('Login required'),
                type: 'info',
                message: <Trans
                    i18nKey='<0>Sign Up</0> or <1>Login</1> to subscribe for events and request free coupons'
                    components={[
                        <Link key='register' to={routes.signUp()}/>,
                        <Link key='login' to={routes.login()}/>,
                    ]}
                />,
            })
        } else {
            setLoading(true);

            if (coupon) {
                eventCouponService
                    .removeCoupon(coupon.id)
                    .then(() => mutateEventCoupons((coupons || []).filter((c) => c.event !== coupon.id)))
                    .then(() => showNotification({
                        "title": t("Unsubscribed successfully"),
                        "type": "success",
                        "message": t("Your coupon has been removed from your profile"),
                    }))
                    .catch(showGenericError)
                    .finally(() => setLoading(false));
            } else {
                eventCouponService
                    .requestCoupon(user.id, event.id)
                    .then((coupon) => mutateEventCoupons([...(coupons || []), coupon]))
                    .then(() => showNotification({
                        "title": t("Subscribed successfully"),
                        "type": "success",
                        "message": t("Your new coupon has been added to your profile"),
                    }))
                    .catch(showGenericError)
                    .finally(() => setLoading(false));
            }
        }
    }, [user, eventCouponService, event, mutateEventCoupons, showNotification, routes, coupon]);

    return {
        coupon,
        loading,
        toggleCoupon,
    }
}
