import {isNative} from "./isNative";
import {Preferences} from "@capacitor/preferences";

const DEFAULT_COOKIE_PREFIX = 'jk:';
const ONE_YEAR = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);

export const cookieName = (key: string, prefix = DEFAULT_COOKIE_PREFIX) => prefix + key;

export const setCookie = async (key: string, value: string, expires: Date = ONE_YEAR) => {
    const keyWithPrefix = cookieName(key);

    if (isNative) {
        // this is to avoid iOS cookie tracking prevention, i.e.: don't use cookies in case of native app
        await Preferences.set({
            key: keyWithPrefix,
            value,
        });
    } else {
        document.cookie = `${keyWithPrefix}=${value};expires=${expires.toUTCString()}`;
    }
}

const getBrowserCookie = (keyWithPrefix: string) => {
    const cookie = document.cookie
        .split(';')
        .map(row => row.trim())
        .find(row => row.startsWith(keyWithPrefix));
    return cookie ? cookie.split('=')[1] : undefined;
}

export const getCookie = (key: string) => {
    const keyWithPrefix = cookieName(key);

    if (isNative) {
        // this is to avoid iOS cookie tracking prevention, i.e.: don't use cookies in case of native app
        return Preferences.get({key: keyWithPrefix}).then((c) => c.value || undefined);
    } else {
        return getBrowserCookie(keyWithPrefix);
    }
}
