import serializeJavascript from 'serialize-javascript';
import {isServer} from "./tools/isServer";
import {isSpa} from "./tools/isSpa";

class Config {
    backendApiForSsr: string = isServer ? process.env.RAZZLE_JK_BACKEND_API_FOR_SSR : (isSpa ? process.env.RAZZLE_JK_BACKEND_API : window.env.backendApiForSsr);

    backendApi: string = isServer || isSpa ? process.env.RAZZLE_JK_BACKEND_API : window.env.backendApi;

    publicBaseUrl: string = isServer || isSpa ? process.env.RAZZLE_JK_PUBLIC_BASE_URL : window.env.publicBaseUrl;

    reCaptchaSiteKey: string = isServer || isSpa ? process.env.RAZZLE_JK_RECAPTCHA_SITE_KEY : window.env.reCaptchaSiteKey;

    googleAnalyticsMeasurementId: string = isServer || isSpa ? process.env.RAZZLE_JK_GOOGLE_ANALYTICS_MEASUREMENT_ID : window.env.googleAnalyticsMeasurementId;

    firebase = {
        apiKey: "AIzaSyB-G5_n-Q6dfnSjrIsl_N5XRn6zQjJBKfQ",
        authDomain: "jate-klub-393317.firebaseapp.com",
        projectId: "jate-klub-393317",
        storageBucket: "jate-klub-393317.appspot.com",
        messagingSenderId: "183450914079",
        appId: "1:183450914079:web:84e4c66ffbf079684e342a",
        measurementId: "G-QX7M2GEQF3",
        vapidKey: 'BNnfUBOId56TCwibx8J40GF6R82QtMbdItj1OymDgjke9NXkEK1SmSJgvDo5CUM9XrrJrS9sIcwo95yDCQjSYMg'
    };

    serialize(): string {
        if (!isServer) {
            throw new Error('Config should not be serialized on client side');
        }

        return serializeJavascript(this, {
            ignoreFunction: true,
        });
    }
}

export default new Config();
