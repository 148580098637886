import React, {useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {Button} from "../../atoms/button/Button";
import {useCookie} from "../../hooks/useCookie";

export const CookieConsentBar = () => {
    const routes = useLocalizedRoutes();
    const {t} = useTranslation();
    const {cookie, setCookie, cookieLoaded} = useCookie('cookieConsentAgreed');
    const isCookieConsentAgreed = useMemo(() => cookie === '1', [cookie]);

    return cookieLoaded && !isCookieConsentAgreed ? (<div
        className='fixed bottom-0 px-5 py-3 bg-jk-white text-jk-gray flex flex-row justify-around items-center text-sm w-full'>
        <div className='jk-a-underline'>
            <Trans
                i18nKey={"We are using cookies to ensure you get the best experience on our website. By using our website you agree to our <0>Cookie Policy</0>."}
                components={[<Link key='cookie-policy' to={routes.article('cookie-policy')}></Link>]}
            />
        </div>
        <Button className='flex' onClick={() => setCookie('1')}>{t('Agree')}</Button>
    </div>) : null;
}
