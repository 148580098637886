import {useTranslation} from "react-i18next";
import {CafeSlug} from "./useCafeSlug";
import {Article, Cafe, Event, Gallery} from "@jklubcafe/backend-api-dto";
import {Locale} from "../i18n";
import {ArrayElement} from "../tools/ArrayElement";
import {useMemo} from "react";
import {isNative} from "../tools/isNative";

type CafePage = ArrayElement<Exclude<Cafe['pages'], undefined>>;

const slugOf = <T extends Event | Article | Cafe | Gallery | string>(dto: T) => {
    if (typeof dto === 'string') {
        return dto;
    }
    return dto.slug;
}

export const useLocalizedRoutes = () => {
    const {t, i18n} = useTranslation('routes');
    const locale = i18n.language as Locale;
    const routes = useMemo(() => ({
        home: (newLocale?: Locale) => isNative ? `/${newLocale || locale}/${t('cafes')}/${CafeSlug.Jate}/${t('events')}` : `/${newLocale || locale}`,
        article: (article: Article | string) => `/${locale}/${t('article')}/${typeof article === 'string' ? t(article) : article.slug}`,
        cafe: (cafe: CafeSlug | string | Cafe) => {
            const cafeSlug = slugOf(cafe) as CafeSlug;
            return {
                index: () => `/${locale}/${t('cafes')}/${cafeSlug}`,
                main: () => (cafeSlug === CafeSlug.Tik
                        ? `/${locale}/${t('cafes')}/${cafeSlug}/${t('article')}/${t('cafe-tik-special-offers')}`
                        : `/${locale}/${t('cafes')}/${cafeSlug}/${t('events')}`
                ),
                page: (page?: CafePage) => `/${locale}/${t('cafes')}/${cafeSlug}/${t(page?.type || 'events')}${page && page.type === 'article' ? `/${(page.ref!.value as Article).slug}` : ''}`,
                event: (event: string) => `/${locale}/${t('cafes')}/${cafeSlug}/${t('events')}/${event}`,
                calendar: () => ({
                    main: () => `/${locale}/${t('cafes')}/${cafeSlug}/${t('calendar')}`,
                    event: (event: string | Event) => `${routes.cafe(cafeSlug).calendar().main()}/${slugOf(event)}`,
                }),
                gallery : () => ({
                    main: () => `/${locale}/${t('cafes')}/${cafeSlug}/${t('gallery')}`,
                    browse: (gallery: Gallery) => `${routes.cafe(cafeSlug).gallery().main()}/${slugOf(gallery)}`,
                }),
            }
        },
        event: (event: Event) => {
            const eventRoutes = {
                details: () => routes.cafe(event.cafe as Cafe).event(slugOf(event)),
                gallery: () => `${eventRoutes.details()}/${t('gallery')}`,
            };
            return eventRoutes
        },
        couponValidation: (event: Event) => `/${locale}/${t('cafes')}/${(event.cafe as Cafe).slug}/${t('events')}/${event.slug}/${t('coupon-validation')}`,
        login: () => `/${locale}/${t('authentication')}/${t('login')}`,
        logout: () => `/${locale}/${t('authentication')}/${t('logout')}`,
        signUp: () => `/${locale}/${t('authentication')}/${t('sign-up')}`,
        forgotPassword: () => `/${locale}/${t('authentication')}/${t('forgot-password')}`,
        settings: () => `/${locale}/${t('settings')}`,
    }), [locale, t]);
    return routes;
};
