import {getStaticData, useData} from "../hooks/useData";
import {EventService} from "../services/EventService";
import {useTranslation} from "react-i18next";
import {Outlet, useParams} from "react-router-dom";
import React from "react";
import {SpinnerBackdrop} from "../atoms/spinner-backdrop/SpinnerBackdrop";
import {Popup} from "../molecules/popup/Popup";
import {EventDetails} from "../organisms/event-details/EventDetails";
import {StaticData, StaticDataArgs} from "../tools/context";
import {GalleryService} from "../services/GalleryService";
import {useTreeNavigate} from "../hooks/useTreeNavigate";
import {MetaTitle} from "../atoms/meta-title/MetaTitle";
import {MetaEvent} from "../atoms/meta-event/MetaEvent";
import {MetaDescription} from "../atoms/meta-description/MetaDescription";

export const EventDetailsPage = () => {
    const {eventSlug} = useParams();
    const {data: event} = useData(EventService, EventService.prototype.getEvent, eventSlug);
    const {t} = useTranslation();
    const {goLocationUp} = useTreeNavigate();

    return (
        <>
            <MetaTitle subtitle={event?.title}/>
            <MetaDescription description={event?.intro} />
            {event && <MetaEvent event={event}/>}
            {!event && <SpinnerBackdrop visible={true}/>}
            {event ? (<Popup
                open={!!eventSlug}
                title={t('Event Details')}
                onClose={goLocationUp}
            >
                <EventDetails event={event}/>
            </Popup>) : null}

            <Outlet/>
        </>
    )
}

EventDetailsPage.staticData = async ({params, context}: StaticDataArgs): Promise<StaticData> => {
    const fallbacks = await Promise.all([
        getStaticData(context, EventService, EventService.prototype.getEvent, params.eventSlug),
        getStaticData(context, GalleryService, GalleryService.prototype.getGalleryByEvent, params.eventSlug)
    ])
    return {
        fallback: {
            ...fallbacks[0],
            ...fallbacks[1]
        }
    }
}
