import React from 'react';
import styles from "./LogoJateKlubSmall.module.css";

interface LogoJateKlubSmallProps {
  className?: string;
}

export const LogoJateKlubSmall = ({ className }: LogoJateKlubSmallProps) => {
    return (
        <svg className={className} id="layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="-10 10 326.63 326.63">
            <g id="layer_1" data-name="Layer 1">
                <g>
                    <g>
                        <path className={styles.logo} d="m19.55,128.27c4-7.25,7.94-13.75,11.99-19.77,7.55-11.22,16.24-21.72,25.84-31.2,8.12-8.02,17.24-15.19,26.06-22.13,2.28-1.8,4.57-3.59,6.84-5.41,10.56-8.43,23.75-13.48,36.51-18.36,1.83-.7,3.67-1.4,5.5-2.12,9.05-3.53,18.12-6.06,27.31-7.62,9.12-1.55,18.37-2.15,27.88-1.82,15.03.53,29.11,4.14,43.06,11.03,17.49,8.65,32.51,22.01,45.91,40.86.23.32.45.65.68.98,13.72,19.54,23.3,40.45,29.81,65.07V57.43c0-21.88-17.74-39.62-39.62-39.62H39.62C17.74,17.81,0,35.55,0,57.43v113.6c2.66-7.93,5.84-15.73,9.57-23.42,3.43-7.07,6.73-13.45,9.99-19.34Z"/>
                        <path className={styles.logo} d="m267.4,214.63c-.38-28.58-5.39-55.04-15.33-80.88-6.17-16.04-14.39-29.62-25.15-41.54-.48-.53-.98-1.04-1.48-1.55-7.31-7.69-15.66-13.56-24.92-17.41-17.45-7.28-33.92-9.23-50.36-5.97-18.21,3.61-34.41,9.89-48.17,18.66-12.51,7.98-22.53,16.41-30.64,25.79-10.49,12.13-22.33,26.95-30.6,44.94-6.8,14.8-14.18,32.3-15.68,52.66-.28,3.86-.46,7.58-.52,11.2-.28,15.53,1.5,29.04,5.53,42.67,3.06,10.36,7.16,18.19,13.29,25.4,9.68,11.4,21.37,17.87,36.76,20.37,13.74,2.23,24.92-.67,34.95-4.15,17.54-6.06,29.99-18,42.23-30.73,7.5-7.8,13.2-15.98,17.45-25.02,8.64-18.4,13-37.48,13.33-58.34.16-10.17-2.67-20.38-8.63-31.22-3.48-6.32-8.1-11.75-13.73-16.14-5.28-4.11-10.43-4.95-14.78-5.23-12.52-.8-23.5,2.09-33.58,8.85-9.82,6.58-18.03,15.48-24.4,26.45-4.11,7.08-6.19,14.67-6.29,23.07,3.79-9.96,9.29-18.3,16.44-24.89,6.63-6.11,14.73-9.23,24.07-9.29,10.04-.07,18.55,4.8,23.98,13.71,2.39,3.92,4.22,8.26,5.14,12.21,3.51,15.11,2.87,29.18-1.98,43.03-4.34,12.4-11.12,22.62-20.16,30.36-12.53,10.74-26.78,15.65-43.56,15.01-16.96-.65-31.31-12.57-38.38-31.88-6.53-17.86-6.05-33.14-5.12-43.08,1.05-11.19,3.76-21.2,7.32-30.29,4.4-11.25,10.09-21.08,15.52-29.93,7.74-12.61,18.78-23.68,34.75-34.82,9.26-6.46,20.21-10.94,32.54-13.31,1.85-.36,3.7-.62,5.53-.84,9.02-1.09,17.89-.59,26.94,1.56,20.36,4.84,36.48,17.69,47.91,38.18,10.67,19.8,13.01,37.19,13.87,50.13,1.46,22.18-2.7,41.52-7.42,59.12-3.91,14.59-9.82,28.36-17.56,40.93l-.49.8c-8.53,13.86-17.34,28.2-29.99,38.95-.99.84-2.02,1.71-3.05,2.58h80.52c1.8-7.74,3.53-15.81,5.21-24.36,6.28-32.15,9.05-59.39,8.7-85.75Z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}
