import {Event} from "@jklubcafe/backend-api-dto";
import {useTranslation} from "react-i18next";
import {useInjection} from "../../hooks/useInjection";
import {EventCouponService} from "../../services/EventCouponService";
import React, {useCallback, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Input} from "../../atoms/input/Input";
import {LuFileDigit} from "react-icons/lu";
import {MessageBox} from "../../atoms/message-box/MessageBox";
import {Button} from "../../atoms/button/Button";
import {QrCodeReader} from "../../atoms/qr-code-reader/QrCodeReader";
import {useGlobalNotification} from "../../hooks/useGlobalNotification";
import {RedeemCouponResponse} from "../../repositories/EventCouponRepository";
import {BsQrCodeScan} from "react-icons/bs";
import {TbZoomQuestion} from "react-icons/tb";

export const CouponValidationForm = ({event}: { event: Event }) => {
    const {t} = useTranslation();
    const eventCouponService = useInjection(EventCouponService);
    const {showNotification} = useGlobalNotification();
    const [qrCodeScannerEnabled, setQrCodeScannerEnabled] = useState(false);

    const [couponValidationSuccess, setCouponValidationSuccess] = useState<RedeemCouponResponse | null>(null);

    const redeemCoupon = useCallback((couponCode: string, event: string) => {
        return eventCouponService
            .redeemCoupon(couponCode, event)
            .then((response) => {
                setCouponValidationSuccess(response);
                // TODO: Show success message / page / something
                showNotification({
                    title: t('Coupon redeemed successfully'),
                    message: t('Coupon {{coupon}} is valid and it has been redeemed successfully', {coupon: response.coupon.code}),
                    type: 'success',
                });
            })
            .catch(({response}) => {
                showNotification(
                    response?.status === 403 ? {
                        title: t('Failed to redeem coupon'),
                        type: 'error',
                        message: t('You must be logged in to redeem this coupon')
                    } : response?.status === 401 ? {
                        title: t('Failed to redeem coupon'),
                        type: 'error',
                        message: t('You are not authorized to redeem this coupon')
                    } : response?.status === 400 ? {
                        title: t('Invalid Coupon Code'),
                        message: t('Coupon has already been used by {{username}}', {username: response?.data?.user?.email || t('someone else')}),
                        type: 'error',
                    } : response?.status === 404 ? {
                        title: t('Invalid Coupon Code'),
                        message: t('No such coupon exists'),
                        type: 'error',
                    } : {
                        title: t('Failed to redeem coupon'),
                        message: t('Unknown error occurred. Please try again later.'),
                        type: 'error',
                    }
                )
            });
    }, [eventCouponService]);

    const formik = useFormik({
        initialValues: {
            couponCode: '',
            event: event.id,
        },
        validationSchema: Yup.object({
            couponCode: Yup
                .string()
                .required(t('Please enter the coupon code') || ''),
        }),
        onSubmit: ({couponCode, event}, {setSubmitting}) => {
            redeemCoupon(couponCode, event)
                .finally(() => setSubmitting(false));
        },
    });

    const onQrCodeRead = useCallback((couponCode: string) => {
        setQrCodeScannerEnabled(false);
        formik.setFieldValue('couponCode', couponCode);
    }, [formik, setQrCodeScannerEnabled]);

    const onQrCodeError = useCallback((error: Error) => {
        setQrCodeScannerEnabled(false);
        showNotification({
            title: t('Failed to scan QR code'),
            message: t(error.message),
            type: 'error',
        })
    }, [setQrCodeScannerEnabled, showNotification]);

    const onQrCodeClose = useCallback(() => {
        setQrCodeScannerEnabled(false);
        return true;
    }, [setQrCodeScannerEnabled]);

    return (
        <>
            {qrCodeScannerEnabled ?
                <QrCodeReader onQrCodeRead={onQrCodeRead} onError={onQrCodeError} onClose={onQrCodeClose}/> : null}
            <form onSubmit={formik.handleSubmit} className='grid grid-cols-1 gap-3'>
                <p className='text-sm md:text-base'>
                    {t('You can validate a coupon either by entering the code manually or by scanning the QR code. If the coupon code is valid, it will be redeemed automatically.')}
                </p>

                <div className='flex flex-row items-center gap-2 justify-between'>
                    <Input name='couponCode' id='couponCode' placeholder={t('Coupon Code') || ''} icon={<LuFileDigit/>}
                           className='flex-grow'
                           onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.couponCode}
                           required/>
                    <div>
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                setQrCodeScannerEnabled(true);
                            }}
                            outline
                            type='button'
                            className={'text-sm'}
                        >
                            <BsQrCodeScan /> {t('QR Scan')}
                        </Button>
                    </div>
                </div>

                <MessageBox visible={!!(formik.touched.couponCode && formik.errors.couponCode)} type='error'
                            message={formik.errors.couponCode}/>

                <div className="col-12 jk-a-underline flex justify-between items-center">
                    <Button type='submit' disabled={formik.isSubmitting}>
                        <TbZoomQuestion className='mr-2' /> {t('Validate & Redeem')}
                    </Button>
                </div>
            </form>
        </>
    )
}
