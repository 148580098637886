import {Article} from '@jklubcafe/backend-api-dto';
import {Lifecycle, scoped} from 'tsyringe';
import {ArticleRepository} from '../repositories/ArticleRepository';
import {dataProvider} from "../hooks/useData";

@scoped(Lifecycle.ContainerScoped)
export class ArticleService {
  constructor(private readonly articleRepository: ArticleRepository) {
  }

  @dataProvider('articles')
  public async getArticle(slug: string): Promise<Article> {
    return this.articleRepository.findBySlug(slug);
  }
}

