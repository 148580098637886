import React, {HTMLAttributes} from "react";
import {useTheme} from "../../hooks/useTheme";
import {IoChevronBackCircle} from "react-icons/io5";
import {useTranslation} from "react-i18next";

export const BtnBack = ({className, style, ...props}: HTMLAttributes<HTMLDivElement>) => {
    const {colors} = useTheme();
    const {t} = useTranslation();

    return (
        <div
            className={`whitespace-nowrap text-base sm:text-lg md:text-xl clickable aspect-square sm:aspect-auto rounded-full text-jk-gray-dark sm:px-2 sm:py-1 font-semibold ${className}`}
            style={{
                ...style,
                backgroundColor: colors.primary,
            }}
            {...props}
        >
            <IoChevronBackCircle className='text-3xl sm:mr-2'/>
            <span className='hidden sm:inline'>{t('Back')}</span>
        </div>
    )
}
