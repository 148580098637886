import {useCallback, useMemo} from "react";
import config from "../config";
import {isServer} from "../tools/isServer";
import {useGlobalNotification} from "./useGlobalNotification";
import {useTranslation} from "react-i18next";

export const useReCaptcha = (action = 'submit') => {
    const {showNotification} = useGlobalNotification();
    const {t} = useTranslation();

    const loadScript = useMemo(() => new Promise<void>((resolve) => {
        if (isServer) {
            resolve();
        } else {
            if (document.getElementsByClassName('grecaptcha-badge').length === 0) {
                window.grecaptchaOnLoad = () => resolve();

                const script = document.createElement('script');

                script.src = `https://www.google.com/recaptcha/api.js?onload=grecaptchaOnLoad&render=${config.reCaptchaSiteKey}`;
                script.async = true;

                document.body.appendChild(script);
            } else {
                resolve();
            }
        }
    }), []);

    const executeReCaptcha = useCallback(() => {
        return loadScript
            .then(() => isServer
                ? Promise.resolve('')
                : new Promise<string>((resolve, reject) => {
                    window.grecaptcha.ready(() => {
                        window.grecaptcha
                            .execute(config.reCaptchaSiteKey, {action})
                            .then(resolve)
                            .catch((e) => {
                                showNotification({
                                    type: 'error',
                                    title: t('Captcha Failure'),
                                    message: t('Captcha verification failed')
                                });
                                reject(e);
                            });
                    });
                }));
    }, [loadScript]);

    return {
        executeReCaptcha,
    }
}
