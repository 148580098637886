import {Link} from "react-router-dom";
import {Logo} from "../../atoms/logo/Logo";
import React from "react";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {useCafeSlug} from "../../hooks/useCafeSlug";
import {isNative} from "../../tools/isNative";

export const ClickableLogo = () => {
    const routes = useLocalizedRoutes();
    const cafeSlug = useCafeSlug();

    return (
        <Link to={isNative ? routes.cafe(cafeSlug).main() : routes.home()}>
            <Logo className='h-10 sm:h-14 md-web:h-20 flex-shrink-0 clickable'/>
        </Link>
    )
}
