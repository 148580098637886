import React, {useCallback, useMemo, useRef, useState} from "react";
import {useData} from "../../hooks/useData";
import {useIntersectionObserver} from "../../hooks/useIntersectionObserver";
import {Spinner} from "../../atoms/spinner/Spinner";
import {InjectionToken} from "tsyringe";
import {FindDocumentsResponse} from "../../repositories";

const FIRST_PAGE_NR = 1;

const NextPageLoader = ({visible, onNextPage}: { visible: boolean, onNextPage: () => void }) => {
    const loaderSpinner = useRef(null);

    const onIntersect = useCallback(() => {
        if (visible) {
            onNextPage();
        }
    }, [visible, onNextPage]);

    useIntersectionObserver({
        target: loaderSpinner,
        onIntersect,
    })

    return (visible ? <div className="text-center p-8" ref={loaderSpinner}>
        <Spinner/>
    </div> : null);
}

interface InfinitePagesProps<T extends { id: string }> {
    size: number,
    fetcherProps: {
        token: InjectionToken,
        method: (...props: any[]) => Promise<FindDocumentsResponse<T>>,
        parameters: Parameters<any>,
    },
    Item: React.FC<{
        data: T
    }>,
    noItems?: React.ReactNode,
}

function InfinitePage<T extends { id: string }>({
                                                    fetcherProps,
                                                    page,
                                                    size,
                                                    Item
                                                }: Omit<InfinitePagesProps<T>, 'noItems'> & {
    page: number,
}) {
    const {data} = useData(fetcherProps.token, fetcherProps.method, ...fetcherProps.parameters, page, size);
    return (<>
        {data?.docs.map((doc) => (<Item data={doc} key={`inf-page-item-${doc.id}`}/>))}
    </>)
}

export function InfinitePagination<T extends { id: string }>({noItems, ...props}: InfinitePagesProps<T>) {
    const [page, setPage] = useState(FIRST_PAGE_NR);
    const {data: pageDetails} = useData(props.fetcherProps.token, props.fetcherProps.method, ...props.fetcherProps.parameters, FIRST_PAGE_NR, props.size);

    const pages = useMemo(() => Array.from({length: page}, (_, p) => <InfinitePage key={`inf-page-${p}`} page={p + 1} {...props}/>), [page, props]);
    const hasMorePages = useMemo(() => (pageDetails?.totalPages || 0) > page, [pageDetails, page]);
    const onNextPage = useCallback(() => {
        setPage((page) => page + 1);
    }, [setPage]);

    return (<>
        {pageDetails?.totalDocs ? pages : noItems}
        <NextPageLoader visible={hasMorePages} onNextPage={onNextPage}/>
    </>)
}
