import {useFormik} from "formik";
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import {Input, MailIcon, PasswordIcon} from "../../atoms/input/Input";
import {Button} from "../../atoms/button/Button";
import React, {useCallback} from "react";
import {MessageBox} from "../../atoms/message-box/MessageBox";
import {useInjection} from "../../hooks/useInjection";
import {UserService} from "../../services/UserService";
import {Link, useNavigate} from "react-router-dom";
import {useLocalizedRoutes} from "../../hooks/useLocalizedRoutes";
import {SpinnerBackdrop} from "../../atoms/spinner-backdrop/SpinnerBackdrop";
import {useGlobalNotification} from "../../hooks/useGlobalNotification";
import {useAuthenticatedUser} from "../../hooks/useAuthenticatedUser";
import {useReCaptcha} from "../../hooks/useReCaptcha";
import {GoogleReCaptchaBranding} from "../../atoms/google-recaptcha-branding/GoogleReCaptchaBranding";

export const LoginForm = () => {
    const {t} = useTranslation();
    const userService = useInjection(UserService);
    const navigate = useNavigate();
    const routes = useLocalizedRoutes();
    const {showNotification} = useGlobalNotification();
    const {mutate} = useAuthenticatedUser();
    const {executeReCaptcha} = useReCaptcha('login');

    const login = useCallback((username: string, password: string) => {
        return userService
            .login(username, password)
            .then((response) => mutate(response.user))
            .then((user) => {
                showNotification({
                    type: 'success',
                    title: t('Login Successful'),
                    message: t('You have logged in as {{username}}', {username: user?.email})
                });
                navigate(routes.home());
            })
            .catch(({response}) => {
                showNotification({
                    type: 'error',
                    title: t('Login Failed'),
                    message: (response?.data?.errors || [])[0]?.message || t('Login failed due to unknown error')
                });
            });
    }, [userService]);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup
                .string()
                .email(t('Please enter a valid email address') || '')
                .required(t('Please enter your email address') || ''),
            password: Yup
                .string()
                .required(t('Please enter your password') || ''),
        }),
        onSubmit: ({username, password}, {setSubmitting}) => {
            executeReCaptcha()
                .then(() => login(username, password))
                .finally(() => setSubmitting(false));
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} className='grid grid-cols-1 gap-3'>
                <p>{t('Enter your username and password to login.')}</p>

                <Input
                    id='username' name='username' type='email' autoComplete='username'
                    placeholder={t('E-mail address') || ''} icon={<MailIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username}
                    required/>

                <MessageBox visible={!!(formik.touched.username && formik.errors.username)} type='error'
                            message={formik.errors.username}/>

                <Input
                    id='password' name='password' type='password' autoComplete="current-password"
                    placeholder={t('Password') || ''} icon={<PasswordIcon/>}
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}
                    required/>

                <MessageBox visible={!!(formik.touched.password && formik.errors.password)} type='error'
                            message={formik.errors.password}/>

                <GoogleReCaptchaBranding />

                <div className="col-12 jk-a-underline flex justify-between items-center">
                    <Button type='submit' disabled={formik.isSubmitting}>{t('Login')}</Button>
                    <div>
                        <Link to={routes.forgotPassword()} className='text-sm'>
                            {t('Reset Password')}
                        </Link>
                    </div>
                </div>
            </form>

            {formik.isSubmitting && <SpinnerBackdrop/>}
        </>
    )
};
