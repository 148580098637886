import {Lifecycle, scoped} from "tsyringe";
import {AbstractRepository} from "./AbstractRepository";
import {Event, EventCoupon, User} from "@jklubcafe/backend-api-dto";
import {PayloadClient} from "./PayloadClient";

@scoped(Lifecycle.ContainerScoped)
export class EventCouponRepository extends AbstractRepository<EventCoupon> {
    constructor(client: PayloadClient) {
        super(client, 'event-coupons');
    }

    public redeem(code: string, event: string): Promise<RedeemCouponResponse> {
        return this.client.post<RedeemCouponResponse>(`/${this.collection}/redeem`, {
            code,
            event,
        }).then(response => response.data);
    }
}

export interface RedeemCouponResponse {
    coupon: {
        code: string;
        exists: boolean;
        valid: boolean;
        alreadyAttendedAt: string;
    },
    event: Event,
    user: User,
}
