import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

export interface HelmetTitleProps {
    subtitle?: string | null
}

export const MetaTitle = ({subtitle}: HelmetTitleProps) => {
    const {t} = useTranslation();
    const title = useMemo(() => subtitle ? subtitle + ' | JATE Klub' : t('JATE Klub | Cultural and University Center | Szeged'), [subtitle, t]);

    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="twitter:title" content={title} />
        </Helmet>
    )
}
