import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {useInjection} from "../../hooks/useInjection";
import {useGlobalNotification} from "../../hooks/useGlobalNotification";
import {Toggle} from "../../atoms/toggle/Toggle";
import {PushNotificationRecipient} from '@jklubcafe/backend-api-dto';
import {MessageBox} from "../../atoms/message-box/MessageBox";
import {NotificationService} from "../../services/NotificationService";
import {useData} from "../../hooks/useData";
import {KeyedMutator} from "swr";

type NotificationSettings = PushNotificationRecipient['notificationSettings']

const NotificationSettingsForm_ = ({recipient, mutateRecipient}: {
    recipient: PushNotificationRecipient,
    mutateRecipient: KeyedMutator<PushNotificationRecipient>
}) => {
    const {t} = useTranslation();
    const {showNotification} = useGlobalNotification();
    const notificationService = useInjection(NotificationService);
    const [notificationEnabled, setNotificationEnabled] = useState<boolean | undefined>();

    const updateNotificationSettings = useCallback((fields: NotificationSettings) => {
        return notificationService
            .updateRecipient({
                notificationSettings: fields
            })
            .then((updatedRecipient) => mutateRecipient(updatedRecipient))
            .catch(({response}) => {
                showNotification({
                    type: 'error',
                    title: t('Failed to update settings'),
                    message: (response?.data?.errors || [])[0]?.message || t('Could not update your notification settings due to unknown error. Please try again later.')
                });
            });
    }, [notificationService, mutateRecipient, showNotification]);

    useEffect(() => {
        notificationService.isEnabled().then(setNotificationEnabled);
    }, [notificationService, setNotificationEnabled]);

    const formik = useFormik<NotificationSettings>({
        initialValues: recipient.notificationSettings,
        onSubmit: (fields, {setSubmitting}) => {
            updateNotificationSettings(fields)
                .finally(() => setSubmitting(false));
        },
    });

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(e.target.name, e.target.checked);
        return formik.submitForm();
    }, [formik])

    return (
        <>
            <form onSubmit={formik.handleSubmit} className='grid grid-cols-1 gap-3'>
                <p>{t(
                    'Below you can configure which notifications you want to receive. You can always change these settings later on.'
                )}</p>

                <MessageBox visible={notificationEnabled === false} type='warning' message={t(
                    'Push notifications seem to be disabled for this application on your device. Please enable them so that the application can send you notifications.'
                ) || ''}/>

                <Toggle name='eventPublished'
                        label={t('New event published')}
                        disabled={formik.isSubmitting}
                        checked={formik.values.eventPublished}
                        onChange={handleChange}/>
                <Toggle name='eventCancelled'
                        label={t('Event cancelled')}
                        disabled={formik.isSubmitting}
                        checked={formik.values.eventCancelled}
                        onChange={handleChange}/>
                <Toggle name='eventUpdated'
                        label={t('Event updated')}
                        disabled={formik.isSubmitting}
                        checked={formik.values.eventUpdated}
                        onChange={handleChange}/>
                <Toggle name='eventUpcoming'
                        label={t('Event reminder')}
                        disabled={formik.isSubmitting}
                        checked={formik.values.eventUpcoming}
                        onChange={handleChange}/>

                {/*TODO maybe later on :)*/}
                {/*<Toggle name='subscribedEventCancelled'*/}
                {/*        label={t('One of the events, you subscribed to, got cancelled')}/>*/}
                {/*<Toggle name='subscribedEventUpdated' label={t('One of the events, you subscribed to, got updated')}/>*/}
                {/*<Toggle name='subscribedEventUpcoming'*/}
                {/*        label={t('One of the events, you subscribed to, is coming soon')}/>*/}
            </form>
        </>
    )
};

export const NotificationSettingsForm = () => {
    const {
        data: recipient,
        mutate: mutateRecipient
    } = useData(NotificationService, NotificationService.prototype.getRecipient);
    return recipient ? <NotificationSettingsForm_ recipient={recipient} mutateRecipient={mutateRecipient}/> : null;
}
