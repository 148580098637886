import React, {useCallback, useEffect} from "react";
import {BarcodeScanner, CameraDirection, SupportedFormat} from "@capacitor-community/barcode-scanner";
import {BtnBack} from "../btn-back/BtnBack";
import {isWeb} from "../../tools/isWeb";
import {usePageClass} from "../../hooks/usePageClass";
import styles from './QrCodeReader.module.css';
import {logger} from "../../tools/logger";

export interface QrCodeReaderProps {
    onQrCodeRead: (value: string) => void,
    onError?: (error: Error) => void
    onClose?: () => boolean,
}

const checkPermission = async () => {
    let status;

    try {
         status = await BarcodeScanner.checkPermission({force: true});
    } catch (e) {
        // safari does not support Permissions API
        return;
    }

    if (status.denied || status.restricted || status.unknown || status.granted === false) {
        throw new Error('Camera permission not granted. Please enable it in settings.')
    }
}

const stopScan = () => {
    return Promise.all([
        BarcodeScanner.showBackground(),
        BarcodeScanner.stopScan()
            .finally(() => {
                // Safari fix
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (isWeb && BarcodeScanner._video) {
                    logger.debug('Making sure video tracks are stopped and video element is removed from DOM');
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const video = BarcodeScanner._video;
                    (video.srcObject as MediaStream)?.getTracks()?.forEach(track => track.stop());
                    video.parentElement?.remove();
                }
            })
    ]);
}

const startScan = async () => {
    await BarcodeScanner.prepare({
        targetedFormats: [SupportedFormat.QR_CODE],
        cameraDirection: CameraDirection.BACK,
    });
    await BarcodeScanner.hideBackground();
    // Workaround fix until fix gets released: https://github.com/capacitor-community/barcode-scanner/pull/254
    if (isWeb) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const video = await BarcodeScanner._getVideoElement();
        if (!video.__fixed) {
            logger.debug('fixing video element facing mode');
            video.__fixed = true;
            const stream: MediaStream = video.srcObject;
            stream.getTracks().forEach(track => track.stop());
            video.srcObject = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment',
                }
            })
            video.setAttribute(
                'style',
                '-webkit-transform: scaleX(1); transform: scaleX(1); width:100%; height: 100%;'
            );
            video.play();
        }
    }
    // end workaround fix

    const result = await BarcodeScanner.startScan();

    if (!result.hasContent) {
        throw new Error('QR code scan failed, no content found in the QR code');
    }

    return result.content;
};

export const QrCodeReader = ({onQrCodeRead, onError, onClose}: QrCodeReaderProps) => {
    usePageClass('qr-code-reader-active');
    const [started, setStarted] = React.useState(false);

    useEffect(() => {
        checkPermission()
            .then(() => setStarted(true))
            .then(() => startScan())
            .then((code) => onQrCodeRead(code))
            .catch((error) => onError && onError(error))
            .finally(() => stopScan());
    }, []);

    const onBtnBackClick = useCallback(() => {
        (onClose ? onClose() : true) && stopScan();
    }, [])

    return (started ? <BtnBack id='qr-code-reader-back-btn' className={`z-[100] fixed right-0 top-0 m-4 ${styles.backButton}`}
                               onClick={onBtnBackClick}/> : null);
}
