import React from 'react';
import styles from './LogoJateKlub.module.css';

interface LogoJateKlubProps {
  className?: string;
}

export const LogoJateKlub = ({ className }: LogoJateKlubProps) => {
  const cls = `${styles.logo} ${className || ''}`;
  return (
      <svg id="layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 853.36 326.63">
          <g id="layer_1" data-name="Layer 1">
              <g>
                  <g>
                      <path className={cls} d="m406.13,150.85c18.19,0,31.91-5.15,40.79-15.31,8.81-10.09,13.27-24.2,13.27-41.92V17.04h-28.47v78.07c0,9.88-2.14,16.97-6.35,21.07-4.23,4.11-10.7,6.2-19.24,6.2s-15.56-2.19-20.1-6.5c-4.51-4.28-6.8-11.27-6.8-20.77v-7.16h-28.47v5.67c0,17.62,4.66,31.7,13.86,41.85,9.24,10.21,23.2,15.38,41.51,15.38Z"/>
                      <path className={cls} d="m497.17,149.58c1.73-5.95,3.37-11.46,4.92-16.54,1.39-4.58,2.76-9,4.11-13.27h44.54l9.31,31.08h31.83l-42.44-133.81h-41.85l-42.43,133.81h31.65l.37-1.27Zm29.97-99.6c.37-1.35.8-2.86,1.3-4.53,0-.01,0-.02,0-.03.03.07.05.14.07.21.55,1.58,1,3.03,1.37,4.39l13.65,45.94h-30.08c1.9-6.48,3.88-13.14,5.94-19.97,2.29-7.63,4.87-16.29,7.73-26.01Z"/>
                      <polygon className={cls} points="583.04 45.51 623.8 45.51 623.8 150.85 652.27 150.85 652.27 45.51 691.17 45.51 691.17 17.04 583.04 17.04 583.04 45.51"/>
                      <polygon className={cls} points="800.24 122.39 732.11 122.39 732.11 98.18 794.28 98.18 794.28 69.72 732.11 69.72 732.11 45.51 800.24 45.51 800.24 17.04 703.65 17.04 703.65 150.85 800.24 150.85 800.24 122.39"/>
                      <path className={cls} d="m426.8,192.82l-47.57,48.27v-48.27h-28.47v133.81h28.47v-52.39l25.52,31.82c5.22,6.49,11.36,11.6,18.25,15.17,6.92,3.59,14.9,5.41,23.71,5.41h14.98v-29.21h-13.86c-3.85,0-7.62-.77-11.23-2.28-3.57-1.5-7.29-4.53-11.06-9l-23.73-28.19,62.82-65.13h-37.83Z"/>
                      <polygon className={cls} points="503.18 192.82 474.72 192.82 474.72 326.63 571.12 326.63 571.12 298.17 503.18 298.17 503.18 192.82"/>
                      <path className={cls} d="m657.67,270.71c0,9.89-2.11,17.01-6.27,21.18-4.17,4.16-10.67,6.28-19.32,6.28s-15.7-2.18-20.18-6.49c-4.46-4.28-6.72-11.34-6.72-20.97v-77.89h-28.47v76.59c0,17.62,4.66,31.7,13.86,41.85,9.24,10.21,23.2,15.38,41.51,15.38s31.91-5.15,40.79-15.31c8.81-10.09,13.27-24.2,13.27-41.92v-76.59h-28.47v77.89Z"/>
                      <path className={cls} d="m797.79,253.21c-2.79-2.51-5.89-4.54-9.23-6.07,2.9-1.75,5.13-4.29,6.67-7.61,2.08-4.5,3.14-9.1,3.14-13.68,0-3.63-.59-7.41-1.75-11.21-1.2-3.94-3.59-7.59-7.1-10.83-3.46-3.2-8.38-5.86-14.61-7.92-6.18-2.03-14.41-3.06-24.47-3.06h-51.83v133.81h62.07c6.54,0,12.86-.61,18.77-1.8,6-1.21,11.39-3.29,16.04-6.19,4.7-2.94,8.51-7.01,11.31-12.09,2.8-5.09,4.22-11.44,4.22-18.9,0-8.79-1.38-16.04-4.09-21.53-2.69-5.44-5.76-9.78-9.15-12.91Zm-31.56-15.66c-1.94,1.94-5.37,2.92-10.2,2.92h-28.95v-22.9h25.78c3.49,0,6.36.4,8.51,1.2,2.1.77,3.72,1.75,4.82,2.91,1.12,1.17,1.87,2.43,2.29,3.84.45,1.52.67,2.95.67,4.25,0,3.26-.96,5.81-2.93,7.78Zm3.35,62.86c-2.4.86-4.95,1.29-7.6,1.29h-34.9v-35.74h38.07c2.95,0,5.53.48,7.66,1.43,2.09.93,3.82,2.21,5.13,3.79,1.33,1.62,2.35,3.52,3.03,5.65.68,2.18,1.03,4.5,1.03,6.91,0,2.81-.54,5.34-1.59,7.51-1.06,2.18-2.54,4.05-4.37,5.55-1.89,1.54-4.06,2.76-6.46,3.61Z"/>
                      <path className={cls} d="m841.23,21.82c-.43-.31-.86-.77-1.26-1.4l-.79-1.12h.18c.62-.09,1.24-.25,1.84-.47.61-.22,1.15-.55,1.63-1s.87-1.04,1.17-1.77.44-1.66.44-2.77c0-1.21-.22-2.22-.67-3.01-.45-.79-1.02-1.42-1.7-1.87-.68-.45-1.44-.77-2.28-.95-.84-.19-1.64-.28-2.38-.28h-8.49v18.66h3.69v-6.39h2.05l3.26,4.06c.87,1.06,1.68,1.71,2.43,1.96.75.25,1.55.38,2.43.38h1.91v-3.55h-1.96c-.56,0-1.06-.16-1.49-.47Zm-3.12-6.06h-5.5v-4.9h5.5c.77,0,1.41.2,1.91.61.5.4.75,1.01.75,1.82,0,.71-.22,1.3-.65,1.77s-1.1.7-2,.7Z"/>
                      <path className={cls} d="m852.15,10.02c-.81-2.08-1.96-3.87-3.45-5.36s-3.29-2.64-5.39-3.45c-2.1-.81-4.43-1.21-6.97-1.21s-4.88.4-6.97,1.21c-2.1.81-3.89,1.96-5.39,3.45-1.49,1.49-2.64,3.28-3.45,5.36-.81,2.09-1.21,4.4-1.21,6.95s.4,4.87,1.21,6.97c.81,2.1,1.96,3.89,3.45,5.39,1.49,1.49,3.29,2.65,5.39,3.45,2.1.81,4.43,1.21,6.97,1.21s4.87-.4,6.97-1.21c2.1-.81,3.89-1.96,5.39-3.45,1.49-1.49,2.64-3.29,3.45-5.39.81-2.1,1.21-4.42,1.21-6.97s-.41-4.86-1.21-6.95Zm-3.43,12.41c-.64,1.65-1.54,3.06-2.71,4.22-1.17,1.17-2.57,2.07-4.22,2.73-1.65.65-3.47.98-5.45.98s-3.81-.32-5.46-.98c-1.65-.65-3.05-1.56-4.22-2.73-1.17-1.17-2.07-2.57-2.7-4.22-.64-1.64-.96-3.46-.96-5.45s.32-3.8.96-5.43c.63-1.63,1.54-3.03,2.7-4.2,1.17-1.17,2.57-2.07,4.22-2.73,1.65-.65,3.47-.98,5.46-.98s3.81.33,5.45.98c1.65.65,3.06,1.56,4.22,2.73,1.17,1.17,2.07,2.57,2.71,4.2.63,1.63.95,3.44.95,5.43s-.32,3.81-.95,5.45Z"/>
                  </g>
                  <g>
                      <path className={cls} d="m19.55,128.27c4-7.25,7.94-13.75,11.99-19.77,7.55-11.22,16.24-21.72,25.84-31.2,8.12-8.02,17.24-15.19,26.06-22.13,2.28-1.8,4.57-3.59,6.84-5.41,10.56-8.43,23.75-13.48,36.51-18.36,1.83-.7,3.67-1.4,5.5-2.12,9.05-3.53,18.12-6.06,27.31-7.62,9.12-1.55,18.37-2.15,27.88-1.82,15.03.53,29.11,4.14,43.06,11.03,17.49,8.65,32.51,22.01,45.91,40.86.23.32.45.65.68.98,13.72,19.54,23.3,40.45,29.81,65.07V57.43c0-21.88-17.74-39.62-39.62-39.62H39.62C17.74,17.81,0,35.55,0,57.43v113.6c2.66-7.93,5.84-15.73,9.57-23.42,3.43-7.07,6.73-13.45,9.99-19.34Z"/>
                      <path className={cls} d="m267.4,214.63c-.38-28.58-5.39-55.04-15.33-80.88-6.17-16.04-14.39-29.62-25.15-41.54-.48-.53-.98-1.04-1.48-1.55-7.31-7.69-15.66-13.56-24.92-17.41-17.45-7.28-33.92-9.23-50.36-5.97-18.21,3.61-34.41,9.89-48.17,18.66-12.51,7.98-22.53,16.41-30.64,25.79-10.49,12.13-22.33,26.95-30.6,44.94-6.8,14.8-14.18,32.3-15.68,52.66-.28,3.86-.46,7.58-.52,11.2-.28,15.53,1.5,29.04,5.53,42.67,3.06,10.36,7.16,18.19,13.29,25.4,9.68,11.4,21.37,17.87,36.76,20.37,13.74,2.23,24.92-.67,34.95-4.15,17.54-6.06,29.99-18,42.23-30.73,7.5-7.8,13.2-15.98,17.45-25.02,8.64-18.4,13-37.48,13.33-58.34.16-10.17-2.67-20.38-8.63-31.22-3.48-6.32-8.1-11.75-13.73-16.14-5.28-4.11-10.43-4.95-14.78-5.23-12.52-.8-23.5,2.09-33.58,8.85-9.82,6.58-18.03,15.48-24.4,26.45-4.11,7.08-6.19,14.67-6.29,23.07,3.79-9.96,9.29-18.3,16.44-24.89,6.63-6.11,14.73-9.23,24.07-9.29,10.04-.07,18.55,4.8,23.98,13.71,2.39,3.92,4.22,8.26,5.14,12.21,3.51,15.11,2.87,29.18-1.98,43.03-4.34,12.4-11.12,22.62-20.16,30.36-12.53,10.74-26.78,15.65-43.56,15.01-16.96-.65-31.31-12.57-38.38-31.88-6.53-17.86-6.05-33.14-5.12-43.08,1.05-11.19,3.76-21.2,7.32-30.29,4.4-11.25,10.09-21.08,15.52-29.93,7.74-12.61,18.78-23.68,34.75-34.82,9.26-6.46,20.21-10.94,32.54-13.31,1.85-.36,3.7-.62,5.53-.84,9.02-1.09,17.89-.59,26.94,1.56,20.36,4.84,36.48,17.69,47.91,38.18,10.67,19.8,13.01,37.19,13.87,50.13,1.46,22.18-2.7,41.52-7.42,59.12-3.91,14.59-9.82,28.36-17.56,40.93l-.49.8c-8.53,13.86-17.34,28.2-29.99,38.95-.99.84-2.02,1.71-3.05,2.58h80.52c1.8-7.74,3.53-15.81,5.21-24.36,6.28-32.15,9.05-59.39,8.7-85.75Z"/>
                  </g>
              </g>
          </g>
      </svg>
  )
}
