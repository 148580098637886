import React, {InputHTMLAttributes} from "react";
import {RiKey2Fill} from "react-icons/ri";
import {HiMail} from "react-icons/hi";
import {useTheme} from "../../hooks/useTheme";

export const MailIcon = () => (<HiMail className='text-xl text-gray-400'></HiMail>)

export const PasswordIcon = () => (<RiKey2Fill className='text-xl text-gray-400'/>)

export const Input = ({icon, label, className, ...props}: InputHTMLAttributes<HTMLInputElement> & {
    icon?: React.ReactNode,
    label?: React.ReactNode,
}) => {
    const { theme } = useTheme();
    return (
        // TODO type checkbox
        <div className={`relative ${className || ''}`}>
            {icon &&
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    {icon}
                </div>
            }
            <input
                className={props.type === 'checkbox' ? `w-4 h-4 text-blue-600 bg-gray-100 outline-none rounded-md ring-jk-${theme} focus:ring-4 ring-opacity-40` : `outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ring-jk-${theme} focus:ring-opacity-40 focus:ring-4 focus:border-jk-${theme}-light block w-full p-2.5 transition-all duration-200 ease-in-out ${icon && 'pl-10'}`}
                {...props}
            />
            {label &&
                <label htmlFor={props.id} className="ml-2 text-sm">
                    {label}
                </label>
            }
        </div>
    )
}

