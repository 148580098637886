import {Toast, ToastProps} from "../molecules/toast/Toast";
import React, {useCallback, useEffect, useState} from "react";

const initialState = {
    notifications: [],
}

type GlobalNotificationSettings = Omit<ToastProps, 'visible'> & {
    delay?: number,
}

interface GlobalNotification {
    settings: GlobalNotificationSettings,
    createdAt: Date,
}

const globalNotificationReducer = (state: {
    notifications: GlobalNotification[]
}, action: {
    notification: GlobalNotification,
    operation: 'add' | 'delete'
}) => {
    switch (action.operation) {
        case 'add':
            return {
                ...state,
                notifications: [...state.notifications, action.notification]
            };
        case 'delete':
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.createdAt !== action.notification.createdAt)
            };
        default:
            throw new Error('Invalid operation');
    }
}


const GlobalNotificationContext = React.createContext<{
    showNotification: (settings: GlobalNotificationSettings) => void,
}>({
    showNotification: () => null
});

export const GlobalNotificationProvider = ({children}: { children: React.ReactNode }) => {
    const [
        state,
        dispatch
    ] = React.useReducer(globalNotificationReducer, initialState);

    return (
        <>
            <GlobalNotificationContext.Provider value={{
                showNotification: (settings) => {
                    dispatch({
                        notification: {
                            settings,
                            createdAt: new Date()
                        },
                        operation: 'add'
                    })
                }
            }}>
                {state.notifications.length > 0 && <div
                    className='p-2 mt-safe md:p-5 fixed w-full md:w-1/2 lg:w-1/3 right-0 flex flex-col gap-2 md:gap-5 z-[200] select-none'>
                    {state.notifications.map((notification) => {
                        return (
                            <ToastAutoHide key={notification.createdAt.getTime()} notification={notification}
                                           dispatch={dispatch}/>
                        )
                    })}
                </div>}
                {children}
            </GlobalNotificationContext.Provider>
        </>
    )
}

export const useGlobalNotification = () => {
    const {showNotification, ...context} = React.useContext(GlobalNotificationContext);
    return {
        ...context,
        showNotification,
        showGenericError: () => showNotification({
            title: 'Error',
            type: 'error',
            message: 'An error occurred. Please try again later.',
        }),
        showGenericSuccess: () => showNotification({
            title: 'Success',
            type: 'success',
            message: 'Operation completed successfully.',
        }),
    };
}

const ToastAutoHide = ({dispatch, notification}: {
    notification: GlobalNotification,
    dispatch: React.Dispatch<{
        notification: GlobalNotification,
        operation: 'add' | 'delete'
    }>,
}) => {
    const [visible, setVisible] = useState(true);

    const close = useCallback(() => {
        setVisible(false);
    }, [setVisible]);

    const remove = useCallback(() => {
        dispatch({
            notification: notification,
            operation: 'delete'
        })
    }, [dispatch, notification])

    useEffect(() => {
        const timeout = setTimeout(close, notification.settings.delay || 5000);
        return () => clearTimeout(timeout);
    }, [])

    return (<Toast {...notification.settings} visible={visible} onClose={close} onClosed={remove}/>)
}
