import {useTheme} from "../../hooks/useTheme";
import {Link} from "react-router-dom";
import styles from "./Card.module.css";
import {oneLineTrim} from "common-tags";
import React, {HTMLAttributes, useMemo} from "react";
import {LazyOptimizedImage, OptimizedImageProps} from "../../atoms/optimized-image/OptimizedImage";
import {useTranslation} from "react-i18next";
import {ClientComponent} from "../../atoms/client-component/ClientComponent";
import {IsoTime} from "../../atoms/iso-time/IsoTime";
import {BsCalendar3} from "react-icons/bs";

const CardImage = ({image, alt, PlaceholderIco}: {
    image?: OptimizedImageProps['image'],
    PlaceholderIco?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
    alt?: string,
}) => {
    const Placeholder = useMemo(() => PlaceholderIco || BsCalendar3, [PlaceholderIco]);

    return (
        <div className='flex-shrink-0 flex-grow-0'>
            {image
                ? <LazyOptimizedImage
                    className="w-full h-60 object-cover"
                    image={image}
                    alt={alt}
                    sizes='(min-width: 768px) 50vw, (min-width: 1024px) 33.3vw, 100vw'
                />
                : (<div className="w-full h-60 bg-gray-300 flex items-center justify-center">
                    <Placeholder className="w-20 h-20 fill-slate-400"/>
                </div>)}
        </div>
    )
}
const CardDate = ({datetime, className, ...props}: { datetime: string | Date } & HTMLAttributes<HTMLDivElement>) => {
    const {i18n} = useTranslation();
    const date = useMemo(() => new Date(datetime), [datetime]);
    const month = useMemo(() => date.toLocaleDateString(i18n.language, {month: 'short'}), [date, i18n.language]);
    const weekday = useMemo(() => date.toLocaleDateString(i18n.language, {weekday: 'long'}), [date, i18n.language]);
    const day = useMemo(() => date.toLocaleDateString(i18n.language, {day: 'numeric'}), [date, i18n.language]);

    return (
        <ClientComponent serverComponent={<IsoTime datetime={date}/>}>
            <div className={`${className || ''} py-2 text-white uppercase flex-col flex items-center z-0`} {...props} >
                <div className="whitespace-nowrap font-bold text-lg w-fit mx-2">{month}</div>
                <div className="whitespace-nowrap font-extrabold text-4xl w-fit mx-2">{day}</div>
                <div className="whitespace-nowrap text-base scale-x-75">{weekday}</div>
            </div>
        </ClientComponent>
    )
};

interface CardProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    url: string;
    datetime: string | Date;
    image?: OptimizedImageProps['image'];
    PlaceholderIco?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
    description?: string;
}

export const Card = ({datetime, image, title, description, className, url, PlaceholderIco, ...props}: CardProps) => {
    const {colors} = useTheme();

    return (
        <Link to={url} className='clickable-restrained flex'>
            <div
                style={{
                    backgroundColor: colors.light,
                }}
                className={`${className || ''} relative flex flex-grow flex-col drop-shadow-md active:drop-shadow-none ${styles.eventCard}`}
                {...props}
            >
                <CardImage image={image} alt={title} PlaceholderIco={PlaceholderIco}/>
                <div className="flex flex-row flex-nowrap flex-grow">
                    <CardDate datetime={datetime} style={{
                        backgroundColor: colors.dark,
                    }}/>
                    <section className="text-base line-clamp-4 m-2">
                        <h4 className="inline text-base font-bold">{title} </h4>
                        <p className="inline text-base">{oneLineTrim(description || '')}</p>
                    </section>
                </div>
            </div>
        </Link>
    )
};
