import {Lifecycle, scoped} from "tsyringe";
import {NativeConfigRepository} from "../repositories/NativeConfigRepository";
import {dataProvider} from "../hooks/useData";

@scoped(Lifecycle.ContainerScoped)
export class NativeConfigService {
    constructor(private readonly nativeConfigRepository: NativeConfigRepository) {
    }

    @dataProvider('nativeConfig')
    public getNativeConfig() {
        return this.nativeConfigRepository.find()
    }
}
