import React from "react";
import {HeaderSimple} from "../organisms/header-simple/HeaderSimple";
import {Outlet} from "react-router-dom";
import {FooterSimple} from "../organisms/footer-simple/FooterSimple";
import {StaticData, StaticDataArgs} from "../tools/context";
import {getStaticData} from "../hooks/useData";
import {FooterNavigationService} from "../services/FooterNavigationService";

export const SimpleTemplate = () => (
    <div className='flex flex-col justify-between h-screen'>
        <HeaderSimple/>

        <main className='simple-template p-4 md:p-8 lg-web:px-28 w-full flex-grow'>
            <Outlet/>
        </main>

        <FooterSimple className='p-4 md:p-8 lg-web:px-28 w-full'/>
    </div>
);

SimpleTemplate.staticData = async ({context}: StaticDataArgs): Promise<StaticData> => {
    return {
        fallback: {
            ...(await getStaticData(context, FooterNavigationService, FooterNavigationService.prototype.getFooterNavigation)),
        }
    }
}
