import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {getLanguageFromUrl, Locale} from "../i18n";
import {useTranslation} from "react-i18next";

/**
 * Hook to detect new language code in the URL and set i18n current language accordingly, upon browser history changes.
 *
 * This was necessary to handle situations when navigating to URL with a new language code w/o
 * reloading the application itself. For instance in case of using deep-links w/ native mode or using browser history
 * buttons.
 */
export const useUrlLanguage = () => {
    const location = useLocation();
    const {i18n} = useTranslation();

    useEffect(() => {
        const newLanguage = getLanguageFromUrl();
        const currentLanguage = i18n.language as Locale;
        if (newLanguage && newLanguage !== currentLanguage) {
            i18n.changeLanguage(newLanguage);
        }
    }, [location])
}
