import React, {HTMLAttributes, useCallback, useId} from 'react';
import styles from './BtnMenu.module.css';
import {useTheme} from '../../hooks/useTheme';

const Line = () => {
  const { colors } = useTheme();

  return (
    <span style={{
      background: colors.primary,
    }} className={styles.span}></span>
  )
}

export interface BtnMenuProps extends HTMLAttributes<HTMLLabelElement> {
  onOpen?: () => void;
  onClose?: () => void;
  closed?: boolean;
}

export const BtnMenu = ({ className, onOpen, onClose, closed, ...props }: BtnMenuProps) => {
  const id = useId();

  const onChange = useCallback((e: any) => {
    if (e.target.checked) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [onOpen, onClose]);

  return (
    <label className={`${styles.label} ${className}`} {...props} htmlFor={id}>
      <input aria-label='main menu' onChange={onChange} className={styles.input} type="checkbox" id={id} checked={!closed}/>
      <Line/>
      <Line/>
      <Line/>
    </label>
  )
}
