import i18next, {InitOptions, LanguageDetectorAsyncModule} from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en/translations.json'
import enRoutes from './en/routes.json'
import hu from './hu/translations.json'
import huRoutes from './hu/routes.json'
import {Device} from '@capacitor/device';
import middleware from 'i18next-http-middleware';
import {isServer} from "../tools/isServer";
import {cookieName, getCookie, setCookie} from "../tools/cookies";
import {logger} from "../tools/logger";
import {LanguageDetectorInterface} from "i18next-http-middleware/cjs";

const preferredLanguageCookieKey = 'i18nextLng';

const locales = ['en', 'hu'];

export type Locale = 'en' | 'hu';

export const getLanguageFromUrl = () => {
    const url = window.location.href;
    const urlParts = url.split('/');
    const language = urlParts[3];
    return validateLanguage(language);
}

const validateLanguage = (language?: string) => {
    if (language && locales.includes(language)) {
        return language as Locale;
    }
}
const clientLanguageDetector = (fallback: string): LanguageDetectorAsyncModule => {
    return {
        type: 'languageDetector',
        async: true,
        detect: async () => {
            try {
                const locale = getLanguageFromUrl() ||
                    await (Promise.resolve(getCookie(preferredLanguageCookieKey)).then(validateLanguage)) ||
                    await (Device.getLanguageCode().then((lc) => validateLanguage(lc.value))) ||
                    fallback;
                logger.debug('Current locale', locale);
                return locale;
            } catch (error) {
                return fallback
            }
        },
        cacheUserLanguage: async (language) => {
            try {
                if (validateLanguage(language)) {
                    await setCookie(preferredLanguageCookieKey, language)
                }
            } catch (error: any) {
                logger.error(error);
                if (error.stack) {
                    logger.error(error.stack);
                }
            }
        }
    }
};

const defaultLanguage = (fallback: string): LanguageDetectorInterface => {
    return {
        name: 'defaultLanguage',
        lookup: () => fallback,
    }
}

const i18nextConfig: InitOptions = {
    debug: false,
    load: 'languageOnly',
    preload: locales,
    supportedLngs: locales,
    fallbackLng: false,
    resources: {
        en: {
            translation: en,
            routes: enRoutes,
        },
        hu: {
            translation: hu,
            routes: huRoutes,
        }
    },
}

const createInstance = () => {
    if (isServer) {
        const detector = new middleware.LanguageDetector();
        detector.addDetector(defaultLanguage('hu'))

        return i18next
            .use(detector)
            .use(initReactI18next)
            .init({
                ...i18nextConfig,
                detection: {
                    order: ['path', 'cookie', 'defaultLanguage'],
                    lookupCookie: cookieName(preferredLanguageCookieKey),
                }
            })
    } else {
        return i18next
            .use(clientLanguageDetector('hu'))
            .use(initReactI18next)
            .init({
                ...i18nextConfig,
                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                }
            });
    }
}

createInstance();

export default i18next;

